import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Route, Switch} from "react-router-dom";

import AppWrapper from "@artibulles-cis/react/AppWrapper";
import AppMainContainer from "@artibulles-cis/react/AppMainContainer";
import NavHorizontalBar from "@artibulles-cis/react/NavHorizontalBar";

import TopNavBarMobile from "./TopNavBar/TopNavBarMobile";
// import BottomNavBar from "./BottomNavBar/BottomNavBar";
import HomePage from "../../Pages/Mobile/HomePage";
import ISOPage from "../../Pages/Mobile/ISO_Page";
import DCPage from "../../Pages/Mobile/DC_Page";
import CISPage from "../../Pages/Mobile/CIS_Page";
import PMPage from "../../Pages/Mobile/PM_Page";
import ESPage from "../../Pages/Mobile/ES_Page";
import Cookies from "../../Pages/Desktop/Legal/Cookies";
import TermsAndConditions from "../../Pages/Desktop/Legal/TermsAndConditions";
import PrivacyPolicy from "../../Pages/Desktop/Legal/PrivacyPolicy";
import Legal from "../../Pages/Desktop/Legal/Legal";
import Contact from "../../Pages/Mobile/Contact";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    classExtendMainContainer: {
        // display : "flex",
        // flexDirection : "column"
	},
	

}));

const AppMobile = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    const AppConfig = {
        maxWidth: "2500px",
    };

    return (
        <React.Fragment>
            <AppWrapper AppMaxWidth={AppConfig.maxWidth} style={{height: "100%"}}>
                <NavHorizontalBar Show={true} Height="60px" id="TopFixedNavBar" style={{background : "green"}}MaxWidth={AppConfig.maxWidth} >
                    <TopNavBarMobile />
                </NavHorizontalBar>

                <AppMainContainer
                    WithPerfectScrollbar={false}
                    WithScrollBar={false}
                    classExtendMainContainer={classes.classExtendMainContainer}
                    DisableBodyScroll={true}
                    TopRefComponentID="TopFixedNavBar"
                    // BottomRefComponentID="BottomFixedNavBar"
                >
                    <Switch>
                        <Route exact path="/">
                            <HomePage />
                        </Route>
                        <Route path="/es">
                            <ESPage />
                        </Route>
                        <Route path="/iso">
                            <ISOPage />
                        </Route>
                        <Route path="/dc">
                            <DCPage />
                        </Route>
                        <Route path="/cis">
                            <CISPage />
                        </Route>
                        <Route path="/pm">
                            <PMPage />
                        </Route>
                        <Route path="/legal">
                            <Legal />
                        </Route>
                        <Route path="/termsandconditions">
                            <TermsAndConditions />
                        </Route>
                        <Route path="/cookies">
                            <Cookies />
                        </Route>
                        <Route path="/privacy">
                            <PrivacyPolicy />
                        </Route>
                        <Route path="/contact">
                            <Contact />
                        </Route>
                    </Switch>
                </AppMainContainer>
            </AppWrapper>
        </React.Fragment>
    );
};

export default AppMobile;
