exports.TopSection = {
    English: {
        Description: [
            "Complete Engineering solutions",
            "Consulting support on partial/complete solutions",
            "Consulting advices for problem solving",
            "Evaluate, assess, recommend optimal technologies",
            "Study design (DOEs), specifications, verification studies, risk analysis",
        ],
        CombinedBoxContent: [
            {
                combineWith: "ISO",
                title: "Innovation is a key core element to create new technical solutions in a fast-evolving sector:",
                list: [
                    "Brainstorm on design solution and technologies",
                    "Generate new product ideas, expand product target market, improve features  ",
                    "Use new technologies, expand technology span, ",
                ],
            },
            {
                combineWith: "DC",
                title: "Digital Communication is key to improve efficient communication of very complex technical and scientific aspects:",
                list: [
                    "Deliver clear and simple messages to key stakeholders",
                    "Simply complex data presentation to deliver esaily understandable conclusions",
                    "Convert design ideas into easy to visualize sketches and concepts",
                    "Organize complex flow of information into powerful graphical views",
                ],
            },
            {
                combineWith: "CIS",
                title: "Custom IT solutions are key to facilitate data management and improve team efficiency:",
                list: [
                    "Automate complex data management input and output to focus on analysis and reporting ",
                    "Automate design analysis and report using templates",
                    "Share complex and unique scientific data across teams",
                    "Secure data across sites",
                    "Provide custom communication tools and complex dashboards",
                ],
            },
            {
                combineWith: "PM",
                title: "Project Management helps to organize time and track tasks efficiently:",
                list: ["Custom scheduling tools", "Focus on essential tasks to optimize delivery time", "Communicate with key stakeholders on complex topics"],
            },
        ],
    },
    French: {},
};
