import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import clsx from "clsx";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import Page from "@artibulles-cis/react/Page";
import {Facebook, Linkedin, ZoomIn, ZoomOut, Expand} from "@artibulles-cis/react-icons";
import ContactForm from "./ContactForm";
import BottomNavBar from "../../app/layout/BottomNavBar/BottomNavBarMobile";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ContactTop: {
        width: "100%",

        // height: "100%",
        // display: "flex",
        // flexDirection: "column",
    },
    ContactBackground: {
        flex: "1 0 auto",
        display: "flex",
        flexDirection: "column",
        backgroundImage: "url(/images/backgrounds/BrusselsV5.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        alignItems: "center",
        padding: "0px 10px",
    },
    ContactInfo_Main: {
        display: "flex",
        width: "100%",
        flex: "0 0 320px",
        maxWidth: "1500px",
        background: "rgba(35,35,35,0.9)",
        position: "relative",
        marginTop: "20px",
        flexDirection: "column",
        color: "white",
        padding: "10px",
        boxSizing: "border-box",
    },
    ContactInfo_Form: {
        display: "flex",
        width: "100%",
        maxWidth: "100%",
        margin: "20px 0px 20px 0px",
        justifyContent: "center",
        background: "rgba(35,35,35,0.9)",
    },
    ContactInfoFormPicture_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // margin: "0px 20px 0px 20px",
        background: "white",
    },
    ContactInfoForm_Picture: {
        height: "420px",
        width: "auto",
    },
    ContactInfo_Picture_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "center",
        // margin: "0px 20px 0px 20px",
        background: "white",
    },
    ContactInfo_Picture: {
        height: "320px",
        width: "auto",
    },
    ContactInfo_Wrapper: {
        flex: "1 1 700px",
        minWidth: "500px",
        maxWidth: "700px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        height: "100%",
        padding: "0px 10px 0px 10px",
        "& h1": {
            margin: "0px",
        },

        color: "white",
    },
    ContactTitle: {
        display: "flex",
        flex: "0 1 auto",
        justifyContent: "center",
        "& h1": {
            marginTop: "10px",
            textAlign: "center",
        },
    },
    ContactBox_Wrapper: {
        display: "flex",
        flex: "1 1 auto",
        width: "100%",
        position: "relative",
        justifyContent: "center",
    },
    ContactBox_ContactInfo: {
        flex: "1 1 auto",
        maxWidth: "400px",
        display: "flex",
        flexDirection: "column",

        justifyContent: "center",
    },
    ContactBox_Social: {
        // position: "absolute",
        top: "0px",
        right: "0px",
        flex: "0 1 auto",

        // maxWidth: "400px",
        // minWidth : "200px",
        padding: "0px 20px 0px 20px",
        display: "flex",

        justifyContent: "center",
        alignItems: "center",
    },
    ContactFlex: {
        display: "flex",
        padding: "15px 0px 15px 0px",
    },
    ContactFlex_Left: {
        display: "flex",
        flex: "1 1 60px",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 20px 0px 20px",
    },
    ContactIcons: {
        height: "40px",
        width: "auto",
    },
    Footer_SocialIconsWrapper: {
        display: "flex",
    },
    IconBoxSocial: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "5px 5px",
    },
    ContactFlex_Right: {
        display: "flex",
        flex: "1 1 250px",
        flexDirection: "column",
        justifyContent: "center",
        minWidth: "250px",
        padding: "0px 10px 0px 10px",
        boxSizing: "border-box",
        "& p": {
            padding: "0px",
            margin: "0px",
        },
    },
    MapWrapper: {
        flex: "0 0 300px",
        minWidth: "300px",
        minHeight: "300px",
        position: "relative",
        display: "flex",
    },
    MapContainer: {
        position: "absolute",
        // width: "800px",
        // height: "400px",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    Marker: {
        backgroundSize: "cover",
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        background: "black",
        cursor: "pointer",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    Link: {
        textDecoration: "none",
        // text-decoration-color: white;
        textDecorationLine: "none",
        color: "inherit",
        // text-decoration-style: solid;
        "&:visited": {
            textDecoration: "none",
            color: "inherit",
        },
    },
    FormBoxWrapper: {
        flex: "0 1 900px",
        color: "black",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        // padding: "30px",
        width: "100%",
        boxSizing: "border-box",
    },
    MapControlToolbar: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        zIndex: 1000,
        bottom: 25,
        right: 15,
        borderRadius: "8px",
        background: "rgba(255,255,255,0.95)",
        width: "30px",
        justifyContent: "center",
        alignItems: "center",
        // boxShadow: "rgba(0, 0, 0, .419608) 0 14px 26px -12px, rgba(0, 0, 0, .117647) 0 4px 23px 0, rgba(0, 0, 0, .2) 0 8px 10px -5px",
        boxShadow: "rgba(0, 0, 0, 0.419608) 0 14px 26px -12px, rgba(0, 0, 0, 0.117647) 0 4px 23px 0, rgba(0, 0, 0, 0.2) 0 8px 10px -5px",
    },
    MapControlButton: {
        flex: "1 1 auto",
        width: "100%",
        display: "flex",

        alignItems: "center",
        padding: "5px 0px 5px 0px",
        justifyContent: "center",
        borderBottom: "1px solid rgb(180,180,180)",
    },
    MapControlButtonLast: {
        borderBottom: "none !important",
    },
    "@media only screen and (max-width: 1500px)": {
        ContactInfo_Wrapper: {
            flex: "1 1 auto",
            // minWidth: "800px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px",
        },

        MapWrapper: {
            flex: "1 1 auto",
            minWidth: "100px",
            minHeight: "300px",
            position: "relative",
            display: "flex",
        },
    },
}));

const Contact = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    const {trackPageView,trackEvent} = useMatomo();

    /***************** CONST ******************/

    mapboxgl.accessToken = "pk.eyJ1IjoiYXJuYXVkamFzcGFyZCIsImEiOiJja2RiYjcyaGIxZGltMnlubmFyZm9mM3BqIn0.QE_rL41ltdY3pE1Axwhp4A";
    const Ref_MapContainer = useRef(null);
    const Ref_MapBoxInstance = useRef(null);
    const Marker = () => {
        return <div id={`marker`} className="MapBoxmarker"></div>;
    };
    /***************** CONST ******************/

    /***************** STATE ******************/
    //eslint-disable-next-line
    const [Coordinates, setCoordinates] = useState({lng: 4.4106594, lat: 50.837});
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    //Matomo Tracking
    React.useEffect(() => {
        trackPageView({
            documentTitle: "ContactPage_Mobile",
        });
    }, [trackPageView]);
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: Ref_MapContainer.current,
            // See style options here: https://docs.mapbox.com/api/maps/#styles
            style: "mapbox://styles/mapbox/streets-v11",
            center: [Coordinates.lng, Coordinates.lat],
            zoom: 10,
        });
        Ref_MapBoxInstance.current = map;
        map.on("load", function () {
            map.addSource("Markers", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [
                        {
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: [Coordinates.lng, Coordinates.lat],
                            },
                        },
                    ],
                },
            });
            const markerNode = document.createElement("div");
            ReactDOM.render(<Marker />, markerNode);
            new mapboxgl.Marker(markerNode, {offset: [0, -23]}).setLngLat(Coordinates).addTo(map);
        });
        map.scrollZoom.disable();
        // map.addControl(new mapboxgl.NavigationControl(), "bottom-right");
        return () => map.remove();
    }, [Coordinates]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleMapControls = (action) => {
		//Matomo Tracking
        trackEvent({category: "ContactPage_Mobile", action: `MapControlButton : ${action}`});
        if (action === "ZoomIn") {
            Ref_MapBoxInstance.current.zoomIn({duration: 1000});
        } else if (action === "ZoomOut") {
            Ref_MapBoxInstance.current.zoomOut({duration: 1000});
        } else if (action === "Reset") {
            Ref_MapBoxInstance.current.setCenter([Coordinates.lng, Coordinates.lat]);
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Page WithScrollbar={{X: "hidden", Y: "auto"}}>
            <div className={classes.ContactTop}>
                <div className={classes.MapWrapper}>
                    <div className={classes.MapContainer} ref={Ref_MapContainer}>
                        <div className={classes.MapContainer} ref={Ref_MapContainer}>
                            <div className={classes.MapControlToolbar}>
                                <div className={classes.MapControlButton}>
                                    <ZoomIn IconSize="25px" SVGFillHoverColor="#FF6103" onClick={() => HandleMapControls("ZoomIn")} />
                                </div>
                                <div className={classes.MapControlButton}>
                                    <ZoomOut IconSize="25px" SVGFillHoverColor="#FF6103" onClick={() => HandleMapControls("ZoomOut")} />
                                </div>
                                <div className={clsx(classes.MapControlButton, classes.MapControlButtonLast)}>
                                    <Expand IconSize="22px" SVGFillHoverColor="#FF6103" onClick={() => HandleMapControls("Reset")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.ContactBackground}>
                    <div className={classes.ContactInfo_Main}>
                        <div className={classes.ContactTitle}>
                            <h1>Contact information</h1>
                        </div>
                        <div className={classes.ContactBox_Wrapper}>
                            <div className={classes.ContactBox_ContactInfo}>
                                <div className={classes.ContactFlex}>
                                    <div className={classes.ContactFlex_Left}>
                                        <img className={classes.ContactIcons} alt="Address" src="/images/icons/IconAddress.svg" />
                                    </div>
                                    <div className={classes.ContactFlex_Right}>
                                        <p>180 Avenue de Tervueren</p>
                                        <p>1150 Woluwe-Saint-Pierre</p>
                                        <p> (Belgium)</p>
                                    </div>
                                </div>

                                <div className={classes.ContactFlex}>
                                    <div className={classes.ContactFlex_Left}>
                                        <img style={{height: "30px"}} className={classes.ContactIcons} alt="Email" src="/images/icons/IconEmail.svg" />
                                    </div>
                                    <div className={classes.ContactFlex_Right}>
                                        <a className={classes.Link} href="mailto:contact@artibulles.com">
                                            contact@artibulles.com
                                        </a>
                                    </div>
                                </div>
                                <div className={classes.ContactFlex}>
                                    <div className={classes.ContactFlex_Left}>
                                        <img className={classes.ContactIcons} alt="Address" src="/images/icons/IconPhone.svg" />
                                    </div>
                                    <div className={classes.ContactFlex_Right}>
                                        <a className={classes.Link} href="tel:003223155900">
                                            +32 2 315 59 00
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.ContactBox_Social}>
                            <div className={classes.IconBoxSocial}>
                                <a href="https://www.linkedin.com/company/artibulles" target="_blank" rel="noopener noreferrer">
                                    <Linkedin IconSize="60px" />
                                </a>
                            </div>

                            <div className={classes.IconBoxSocial}>
                                <a href="https://www.facebook.com/ArTiBulles-1832078817006718" target="_blank" rel="noopener noreferrer">
                                    <Facebook IconSize="60px" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className={classes.ContactInfo_Form}>
                        <div className={classes.FormBoxWrapper}>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <BottomNavBar />
        </Page>
    );

    /***************** RENDER ******************/
};

export default Contact;
