exports.TopSection = {
    English: {
        Description: [
            "Develop Innovative solutions based on your needs through consulting",
            "Implement/Improve your innovation process using our Smart Innovation Tool",
            "Develop, assess and improve your development processes to improve operational efficiency",
            "Evaluate and propose strategies for product development and product expansion",
        ],
        CombinedBoxContent: [
            {
                combineWith: "ES",
                title: "A technical and scientific background, helps to keep innovation process and the operational strategy rational:",
                list: [
                    "Assess the brainstorming output with a factual methodology using weighed criteria",
                    "Create thorough processes analysis to understand the critical parameters and optimize the most impactful ones",
                    "Optimize the operational processes through mathematical modeling",
                ],
            },
            {
                combineWith: "DC",
                title: "Digital Communication supports the Innovation and Operational Support in:",
                list: [
                    "Creating visualization tools to represent ideas and processes",
                    "Express complex concepts into simple sketches",
                    "In the early phase of brainstorming, capture the essence of embryonic ideas",
                ],
            },
            {
                combineWith: "CIS",
                title: "Using custom IT is a key asset for Innovation and Operational Support:",
                list: [
                    "We have created a unique Smart Innovation Tool (Web Application) that helps each steps of the innovation process to enable teams to generate ideas, evaluated them, while focusing on business outcomes",
                    "To analyze complex processes and optimize them, it’s often required to deal with huge amount of data that are difficult to handle without custom IT programming",
                ],
            },
            {
                combineWith: "PM",
                title: "Project Management helps to keep track of business aspects:",
                list: [
                    "Provide an appropriate framework to prevent inevitable chaos, define clear cost and time evaluation criteria in the innovation process",
                    "Manage clear expectations upfront when improving operational processes",
                ],
            },
        ],
    },
    French: {},
};
