exports.TopSection = {
    English: {
        Description: [
            "Develop strategies, plan and schedule",
            "Manage daily communication on schedule, cost, time",
            "Ensure efficient and smooth communication between multi-sites and mulit-departments collaborators",
            "Resolve communication issues, ensure the focus is on delivering the rigth product at the right time",
            "Optimize plan and schedule throughout the project to optimize time to market",
            "Communicate to key stackeholders",
        ],
        CombinedBoxContent: [
            {
                combineWith: "ES",
                title: "Having a scientific / technical network supporting the PM is efficient when dealing with technical projects:",
                list: ["Understand the key aspects of the project", "Benchmark cost, time, resources for technical tasks"],
            },
            {
                combineWith: "ISO",
                title: "Innovation in the Project Management can help to propose alternative strategies or resolve issues:",
                list: [
                    "When the team is stuck, brainstorming sessions can be organized to resolve issues whether it due to communication or technical problems",
                    "Re-assessing the plan and the strategy to propose alternative options, shortcuts, multiple paths",
                ],
            },
            {
                combineWith: "DC",
                title: "Digital communication is an asset in the PM toolkit:",
                list: [
                    "Improve delivery of impactful messages",
                    "Support complex communication by adding visual support",
                    "Deliver high professional quality presentation to board members or key investors",
                ],
            },
            {
                combineWith: "PM",
                title: "Custom IT solutions helps the PM:",
                list: [
                    "Custom web scheduling tools and addons to existing scheduling software",
                    "Critical chain analysis with multiple customizable options and algorithms to optimize schedule and buffer",
                    "Custom secured platform to manage data and communication",
                ],
            },
        ],
    },
    French: {},
};
