import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import clsx from "clsx";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {v4 as uuidv4} from "uuid";
import {HomePageText, HomePageTextVision} from "../TextContent/HomePageText";
import useWebsiteAppInitContext from "@artibulles-cis/react/utils/context/websiteAppInitContext/useWebsiteAppInitContext";
import Page from "@artibulles-cis/react/Page";
import BottomNavBar from "../../app/layout/BottomNavBar/BottomNavBarMobile";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendsPageContent: {
        // display: "flex",
        // flexDirection: "column",
    },
    Background: {
        position: "relative",
        width: "100%",
        backgroundImage: "url(/images/backgrounds/homePage_Background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",

    },
    SectionTop: {},

    Top_Wrapper: {
        position: "relative",
        height: "160px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "white",
        background: "black",
    },

    AnimatedLogoWrapper: {
        position: "absolute",
        padding: 0,
        margin: "0 auto",
        width: "100%",
        display: "block",
        zIndex: 10,
    },

    AnimatedLogo: {
        display: "block",
        height: "160px",
        margin: "0 auto",
        opacity: 0.4,
    },
    Titles_Wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 10,
    },

    Animated_ButtonHomeVision_Wrapper: {
        backgroundColor: "transparent",
        perspective: "2000px",
        width: "150px",
        height: "40px",
    },
    Animated_ButtonHomeVision: {
        position: "relative",
        // transition: FlipVisionMainTransition,
        transformStyle: "preserve-3d",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },

    Animated_ButtonHomeVision_ShowVision: {
        transform: "rotateY(180deg)",
    },
    Animated_ButtonHomeVision_Vision: {
        display: "block",
        backfaceVisibility: "hidden",
        position: "absolute",
        width: "100%",
        opacity: 1,
        transition: "opacity 0.6s",
    },

    Animated_ButtonHomeVision_Vision_Hidden: {
        opacity: 0,
        transition: "opacity 0.6s",
    },
    Animated_ButtonHomeVision_Home: {
        display: "block",
        transform: "rotateY(180deg)",
        backfaceVisibility: "hidden",
        position: "absolute",
        width: "100%",
    },

    SubTitle: {
        margin: 0,
        textAlign: "center",
        fontSize: "1.2rem",
    },

    HomePageCompanyH1: {
        margin: 0,
        textAlign: "center",
        fontSize: "1.5rem",
    },

    Section_Main: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
    },
    MainWrapper: {
        display: "flex",
        // height: "1000px",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 20px 0px 20px",
        boxSizing: "border-box",
        transition: "all 0.4s",
        fontSize: "1rem",
        width: "100%",
        maxWidth: "1600px",
        margin: "0 auto",
    },
    HomeImage: {
        padding: "10px",
        boxSizing: "border-box",
    },

    BusinessDescriptionWrapper: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    SectionTitle_Wrapper: {
        display: "flex",
        flex: "0 0 50px",
        justifyContent: "center",
        alignItems: "center",
        background: "black",
    },
    SectionTitle: {
        margin: "0px",
        fontSize: "20px",
        color: "white",
    },
    SectionWrapper: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
    },
    ES_Background: {
        position: "relative",
        display: "flex",
        flex: "1 0 auto",
        // height: "100%",
        width: "100%",
        backgroundImage: "url(/images/backgrounds/Artibulles_ES_wave.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    ISO_Background: {
        position: "relative",
        display: "flex",
        flex: "1 0 auto",
        // height: "100%",
        width: "100%",
        backgroundImage: "url(/images/backgrounds/Artibulles_ISO_wave.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        // boxSizing: "content-box",
    },
    DC_Background: {
        position: "relative",
        display: "flex",
        flex: "1 0 auto",
        // height: "100%",
        width: "100%",
        backgroundImage: "url(/images/backgrounds/Artibulles_DC_wave.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    CIS_Background: {
        position: "relative",
        display: "flex",
        flex: "1 0 auto",
        // height: "100%",
        width: "100%",
        backgroundImage: "url(/images/backgrounds/Artibulles_CIS_wave.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    PM_Background: {
        position: "relative",
        display: "flex",
        flex: "1 0 auto",
        // height: "100%",
        width: "100%",
        backgroundImage: "url(/images/backgrounds/Artibulles_PM_wave.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },

    Summary_SectionTop: {
        flex: "1 1 auto",
        // padding: "10px",
        // margin : "10px",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        boxSizing: "border-box",
    },
    HomeSummary_DivWrapper: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        position: "relative",
        height: "100%",
        // margin : "10px"

        // padding: "0px 10px 0px 10px",
    },
    HomePage_Summary_Wrapper: {
        display: "flex",
        width: "100%",
        height: "100",
        boxSizing: "border-box",
        margin: "10px",
    },
    HomePage_Summary: {
        flex: "1 1 auto",
        width: "100%",

        flexDirection: "column",
        justifyContent: "center",
        color: "white",
        borderRadius: "15px",
        // backgroundColor: "rgba(255, 255, 255, 0.6)",
        backgroundColor: "rgba(44, 44, 44,0.9)",
        // margin : "10px"
    },
    MainPage_Summary_Title_Mobile: {
        textAlign: "center",
        display: "flex",
        backgroundColor: "black",
        color: "white",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        height: "60px",

        padding: "5px 10px 5px 10px",
        alignItems: "center",
    },
    MainPage_Summary_Title_Mobile_H1: {
        flex: "1 1 auto",
        fontSize: "1.2rem",
        margin: "0",
        padding: "0px 10px 0px 10px",
        textAlign: "center",
        wordWrap: "normal",
        wordBreak: "normal",
    },

    MainPage_Summary_Title_SimpleIcon_Mobile: {
        height: "50px",
        width: "auto",
    },
    Home_Summary_Middle_Mobile: {
        // display: "flex",
        // flex: "1 1 auto",
        height: "auto",
        position: "relative",
        padding: "10px",
        // flexDirection: "column",

        // justifyContent: "flex-start",
        // alignItems: "center",
        boxSizing: "border-box",
        // padding : "15px 15px 15px 15px"
        // padding: "10px",
        margin: "0",
    },

    Home_Summary_Middle_UL_Mobile: {
        listStyle: "none",
        margin: "0px",
        // margin :"10px 0px 10px 0px",
        padding: "0px",
        color: "white",
        width: "100%",
        // height : "100%"
    },
    Home_Summary_Middle_Mobile_Li: {
        display: "flex",
        alignContent: "flex-start",
        alignItems: "flex-start",
        padding: "3px 15px 3px 15px",
        margin: "0px",
    },
    Home_Summary_Middle_Mobile_P: {
        fontSize: "12px",
        margin: "0",
        lineHeight: "20px",
        flex: "1 1 auto",
    },
    BulletLogo: {
        height: "20px",
        width: "auto",
        margin: "3px 10px 0px 0px",
        fill: "white",
        stroke: "white",
    },
    VisionSection: {
        flex: "1 0 auto",
        display: "flex",
        width: "100%",
        margin: "0px",
        color: "white",
        flexDirection: "column",

        background: "black",
    },
    VisionSectionBoxWrapper: {
        width: "100%",
        display: "flex",
        boxSizing: "border-box",
        // margin: "10px",
    },
    VisionSectionBox: {
        flex: "1 1 auto",
        width: "100%",
        // borderRadius: "15px",
        backgroundColor: "rgba(255, 255, 255, 0.192)",
    },
    Title_Vision: {
        margin: "10px",
        textAlign: "center",
        fontSize: "2rem",
    },

    Vision_Section_Wrapper: {
        display: "flex",
        flexDirection: "column",
        fontSize: "12px",
    },
    Vision_Section_Wrapper_Title: {
        width: "100%",
        minHeight: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.192)",
        // borderTopLeftRadius: "8px",
        // borderTopRightRadius: "8px",
        marginBottom: "10px",
        padding: "10px 20px 10px 20px",
        boxSizing: "border-box",
    },
    Vision_Section_Wrapper_Title_H: {
        flex: "1 1 auto",
        textAlign: "center",
        margin: "0px",
        padding: "0px",
        fontSize: "16px",
    },

    HomePage_Vision_Intro_Block: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "1450px",
        padding: "0px 20px 0px 20px",
    },
    HomePage_Vision_Intro_Block_Text_Title: {
        margin: "0px 0px 10px 0px",
        fontSize: "12px",
    },
    HomePage_Vision_Intro_Block_Icon: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",

        // "& img": {
        //     maxWidth: "600px",
        //     height: "auto",
        // },
    },

    HomePage_Vision_Intro_Block_Text: {
        flex: "1 1 auto",
        // textAlign: "justify",
        "& li": {
            padding: "5px 0px",
        },
    },
    HomePage_CoreBusinessText: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    Vision_Section_TitleIntro: {
        textAlign: "center",
        maxWidth: "1200px",
        padding: "0px 10px 0px 10px",
        margin: "5px 0px 15px 0px",
    },

    HomePage_Vision_Categories_block: {
        maxWidth: "1400px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        fontSize: "1.1rem",
        // padding: "0px 20px 0px 20px",
    },

    HomePage_Vision_Categories_Text: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexWrap: "nowrap",
        paddingRight: "30px",
        textAlign: "justify",
        padding: "0px 20px 0px 20px",
        "& p": {
            lineHeight: "30px",
        },
    },

    SolutionsStructureBox: {
        flex: "1 1 auto",
        width: "100%",
        // borderRadius: "15px",
        backgroundColor: "rgba(255, 255, 255, 0.192)",
    },
    Vision_Section_Blocks_FlexWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
        maxWidth: "1400px",
    },
    Vision_Block: {
        flex: "0 0 350px",
        display: "flex",
        flexDirection: "column",

        margin: "8px 8px 8px 8px",
        color: "black",
        boxSizing: "border-box",

        padding: "0",
        "& h3": {
            textAlign: "center",
            color: "white",
        },
    },
    Vision_Block_Business: {
        height: "210px",
    },
    Vision_Block_Values: {
        height: "175px",
    },
    Vision_Block_Title_Wrapper: {
        display: "flex",
        flex: "1 0 auto",
        alignItems: "center",
        padding: "4px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },

    Vision_Block_Title_Wrapper_Orange: {
        backgroundColor: "#FF6103",
    },
    Vision_Block_Title_Wrapper_Blue: {
        backgroundColor: "#4997D2",
    },
    Vision_Block_Title_Wrapper_Green: {
        backgroundColor: "#6DBE45",
    },
    Vision_Block_Title_Wrapper_Pink: {
        backgroundColor: "#ED2D92",
    },
    Vision_Block_Title_Wrapper_Yellow: {
        backgroundColor: "#FCB415",
    },
    HomePage_Vision_Categories_Svg: {
        maxWidth: "250px",
        height: "auto",
    },
    Vision_Block_Title_Icon: {
        flex: "0 0 50px",
        height: "50px",
    },
    Vision_Block_Title: {
        margin: "0",
        padding: "0px 15px 0px 15px",
    },
    Vision_Block_Body: {
        flex: "1 1 auto",
        backgroundColor: "white",
        height: "100%",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        border: "4px solid white",
        padding: "0px 8px 0px 8px",
    },
    HomePage_Vision_Intro_Block_Picture: {
        maxWidth: "300px",
        height: "auto",
    },
    HomePageVisionLogoInsideText_P: {
        padding: "0px",
    },
    HomePageVisionLogoInsideText: {
        height: "25px",
        width: "auto",
    },
}));

const HomePage = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
	const classes = styles({...props, theme});
	const {trackPageView} = useMatomo();
    const Language = "English";
    var FinalLanguageTag;
    if (Language === "English") {
        FinalLanguageTag = "English";
    }
    /***************** CONST ******************/
    const {websiteAppInitContextState} = useWebsiteAppInitContext();

    const windowHeight = websiteAppInitContextState.clientWindowDimensions.windowHeight;
    const windowWidth = websiteAppInitContextState.clientWindowDimensions.windowWidth;
    const SVGRatioWH = 576 / 520;
    /***************** CONST ******************/

    /***************** STATE ******************/

    const [SVGDimensions, setSVGDimensions] = useState({Width: null, Height: null});
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

	/***************** EFFECTS ******************/
	//Matomo Tracking
    React.useEffect(() => {
        trackPageView({
            documentTitle: "HomePage_Mobile",
        });
    }, [trackPageView]);
    //Calculating the height of the main section element
    useEffect(() => {
        var SVGHeight = windowHeight - 160 - 60;
        var SVGWidth = SVGHeight * SVGRatioWH;
        if (SVGWidth > windowWidth) {
            SVGWidth = windowWidth;
            SVGHeight = SVGWidth / SVGRatioWH;
        }
        setSVGDimensions({Width: SVGWidth, Height: SVGHeight});
    }, [windowHeight, SVGRatioWH, windowWidth]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Page WithScrollbar={{X: "hidden", Y: "auto"}} ClassExtendsPageContent={classes.ClassExtendsPageContent}>
            <section className={classes.Background}>
                <section className={classes.SectionTop}>
                    <div className={classes.Top_Wrapper}>
                        <div className={classes.AnimatedLogoWrapper}>
                            <img className={classes.AnimatedLogo} src="/images/general/ArtibullesLogoAnimated.gif" alt="AnimatedLogo" />
                        </div>
                        <div className={classes.Titles_Wrapper}>
                            <h1 className={classes.HomePageCompanyH1}>{HomePageText[FinalLanguageTag].TopTitle}</h1>
                            <h2 className={classes.SubTitle}>{HomePageText[FinalLanguageTag].SubTitle}</h2>
                        </div>
                    </div>
                </section>

                <section className={classes.Section_Main} style={{color: "white"}}>
                    <div>
                        <img
                            className={classes.HomeImage}
                            alt="HomePageMobile"
                            style={{height: `${SVGDimensions.Height}px`, width: `${SVGDimensions.Width}px`}}
                            src="/images/homepage/HomePageMobileB.svg"
                        ></img>
                    </div>
                </section>
            </section>

            <div className={classes.BusinessDescriptionWrapper}>
                <div className={classes.SectionTitle_Wrapper}>
                    <h1 className={classes.SectionTitle}>Our Businesses</h1>
                </div>
                <div className={classes.ES_Background}>
                    <div className={classes.HomePage_Summary_Wrapper}>
                        <div className={classes.HomePage_Summary}>
                            <div className={classes.MainPage_Summary_Title_Mobile}>
                                <img className={classes.MainPage_Summary_Title_SimpleIcon_Mobile} src="images/icons/Artibulles_logo_ES_black_fontexp.svg" alt="SipmleEngineering" />
                                <h1 className={classes.MainPage_Summary_Title_Mobile_H1}>{HomePageText[FinalLanguageTag].ES_Box_Title}</h1>
                            </div>
                            <div className={classes.Home_Summary_Middle_Mobile}>
                                <ul className={classes.Home_Summary_Middle_UL_Mobile}>
                                    {HomePageText[FinalLanguageTag].ES_Box_Description.map((elem) => {
                                        return (
                                            <li key={uuidv4()} className={classes.Home_Summary_Middle_Mobile_Li}>
                                                <img src="images/general/ArtibullesBulletWhite.svg" alt="companyLogo" className={classes.BulletLogo} />
                                                <p className={classes.Home_Summary_Middle_Mobile_P}>{elem}</p>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.ISO_Background}>
                    <div className={classes.HomePage_Summary_Wrapper}>
                        <div className={classes.HomePage_Summary}>
                            <div className={classes.MainPage_Summary_Title_Mobile}>
                                <img className={classes.MainPage_Summary_Title_SimpleIcon_Mobile} src="images/icons/Artibulles_logo_ISO_black_fontexp.svg" alt="SipmleEngineering" />
                                <h1 className={classes.MainPage_Summary_Title_Mobile_H1}>{HomePageText[FinalLanguageTag].ISO_Box_Title}</h1>
                            </div>
                            <div className={classes.Home_Summary_Middle_Mobile}>
                                <ul className={classes.Home_Summary_Middle_UL_Mobile}>
                                    {HomePageText[FinalLanguageTag].ISO_Box_Description.map((elem) => {
                                        return (
                                            <li key={uuidv4()} className={classes.Home_Summary_Middle_Mobile_Li}>
                                                <img src="images/general/ArtibullesBulletWhite.svg" alt="companyLogo" className={classes.BulletLogo} />
                                                <p className={classes.Home_Summary_Middle_Mobile_P}>{elem}</p>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.DC_Background}>
                    <div className={classes.HomePage_Summary_Wrapper}>
                        <div className={classes.Summary_SectionTop}>
                            <div className={classes.HomeSummary_DivWrapper}>
                                <div className={classes.HomePage_Summary}>
                                    <div className={classes.MainPage_Summary_Title_Mobile}>
                                        <img className={classes.MainPage_Summary_Title_SimpleIcon_Mobile} src="images/icons/Artibulles_logo_DC_black_fontexp.svg" alt="SipmleEngineering" />
                                        <h1 className={classes.MainPage_Summary_Title_Mobile_H1}>{HomePageText[FinalLanguageTag].DC_Box_Title}</h1>
                                    </div>
                                    <div className={classes.Home_Summary_Middle_Mobile}>
                                        <ul className={classes.Home_Summary_Middle_UL_Mobile}>
                                            {HomePageText[FinalLanguageTag].DC_Box_Description.map((elem) => {
                                                return (
                                                    <li key={uuidv4()} className={classes.Home_Summary_Middle_Mobile_Li}>
                                                        <img src="images/general/ArtibullesBulletWhite.svg" alt="companyLogo" className={classes.BulletLogo} />
                                                        <p className={classes.Home_Summary_Middle_Mobile_P}>{elem}</p>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.CIS_Background}>
                    <div className={classes.HomePage_Summary_Wrapper}>
                        <div className={classes.Summary_SectionTop}>
                            <div className={classes.HomeSummary_DivWrapper}>
                                <div className={classes.HomePage_Summary}>
                                    <div className={classes.MainPage_Summary_Title_Mobile}>
                                        <img className={classes.MainPage_Summary_Title_SimpleIcon_Mobile} src="images/icons/Artibulles_logo_CIS_black_fontexp.svg" alt="SipmleEngineering" />
                                        <h1 className={classes.MainPage_Summary_Title_Mobile_H1}>{HomePageText[FinalLanguageTag].CIS_Box_Title}</h1>
                                    </div>
                                    <div className={classes.Home_Summary_Middle_Mobile}>
                                        <ul className={classes.Home_Summary_Middle_UL_Mobile}>
                                            {HomePageText[FinalLanguageTag].CIS_Box_Description.map((elem) => {
                                                return (
                                                    <li key={uuidv4()} className={classes.Home_Summary_Middle_Mobile_Li}>
                                                        <img src="images/general/ArtibullesBulletWhite.svg" alt="companyLogo" className={classes.BulletLogo} />
                                                        <p className={classes.Home_Summary_Middle_Mobile_P}>{elem}</p>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.PM_Background}>
                    <div className={classes.HomePage_Summary_Wrapper}>
                        <div className={classes.Summary_SectionTop}>
                            <div className={classes.HomeSummary_DivWrapper}>
                                <div className={classes.HomePage_Summary}>
                                    <div className={classes.MainPage_Summary_Title_Mobile}>
                                        <img className={classes.MainPage_Summary_Title_SimpleIcon_Mobile} src="images/icons/Artibulles_logo_PM_black_fontexp.svg" alt="SipmleEngineering" />
                                        <h1 className={classes.MainPage_Summary_Title_Mobile_H1}>{HomePageText[FinalLanguageTag].PM_Box_Title}</h1>
                                    </div>
                                    <div className={classes.Home_Summary_Middle_Mobile}>
                                        <ul className={classes.Home_Summary_Middle_UL_Mobile}>
                                            {HomePageText[FinalLanguageTag].PM_Box_Description.map((elem) => {
                                                return (
                                                    <li key={uuidv4()} className={classes.Home_Summary_Middle_Mobile_Li}>
                                                        <img src="images/general/ArtibullesBulletWhite.svg" alt="companyLogo" className={classes.BulletLogo} />
                                                        <p className={classes.Home_Summary_Middle_Mobile_P}>{elem}</p>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.Vision_Section_Wrapper}>
                <div className={classes.SectionTitle_Wrapper}>
                    <h1 className={classes.SectionTitle}>{HomePageTextVision[FinalLanguageTag].Title}</h1>
                </div>
                <div className={classes.VisionSection}>
                    <div className={classes.VisionSectionBoxWrapper}>
                        <div className={classes.VisionSectionBox}>
                            <div className={classes.Vision_Section_Wrapper_Title}>
                                <h2 className={classes.Vision_Section_Wrapper_Title_H}>{HomePageTextVision[FinalLanguageTag].Goal}</h2>
                            </div>
                            <div className={classes.HomePage_Vision_Intro_Block}>
                                <div className={classes.HomePage_Vision_Intro_Block_Icon}>
                                    <img className={classes.HomePage_Vision_Intro_Block_Picture} src="/images/homepage/Vision.png" alt="EvolutionSpeed" />
                                </div>
                                <div className={classes.HomePage_Vision_Intro_Block_Text}>
                                    <h2 className={classes.HomePage_Vision_Intro_Block_Text_Title}>{HomePageTextVision[FinalLanguageTag].IntroSection.Title1}</h2>
                                    <h2 className={classes.HomePage_Vision_Intro_Block_Text_Title}>{HomePageTextVision[FinalLanguageTag].IntroSection.Title2}</h2>
                                    <p>{HomePageTextVision[FinalLanguageTag].IntroSection.P1}</p>
                                    {HomePageTextVision[FinalLanguageTag].IntroSection.P2}
                                    <ul>
                                        <li>{HomePageTextVision[FinalLanguageTag].IntroSection.Bullets[0]}</li>
                                        <li>{HomePageTextVision[FinalLanguageTag].IntroSection.Bullets[1]}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.VisionSectionBoxWrapper}>
                        <div className={classes.SolutionsStructureBox}>
                            <div className={classes.Vision_Section_Wrapper_Title}>
                                <h2 className={classes.Vision_Section_Wrapper_Title_H}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.Title}</h2>
                            </div>
                            <div className={classes.HomePage_Vision_Intro_Block}>
                                <div className={classes.HomePage_Vision_Intro_Block_Icon}>
                                    <img className={classes.HomePage_Vision_Categories_Svg} src="/images/homepage/Artibulles_vision_world.png" alt="VisionCategory" />
                                </div>
                                <div className={classes.HomePage_CoreBusinessText}>
                                    <p>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.P1}</p>
                                    <p className={classes.HomePageVisionLogoInsideText_P}>
                                        <img className={classes.HomePageVisionLogoInsideText} src="/images/icons/Artibulles_Icon_WithText_ES.svg" alt="SipmleEngineering" />
                                    </p>
                                    <p className={classes.HomePageVisionLogoInsideText_P}>
                                        <img className={classes.HomePageVisionLogoInsideText} src="/images/icons/Artibulles_Icon_WithText_ISO.svg" alt="SipmleEngineering" />
                                    </p>

                                    <p style={{textAlign: "center"}}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.P2}</p>
                                    <p className={classes.HomePageVisionLogoInsideText_P}>
                                        <img className={classes.HomePageVisionLogoInsideText} src="/images/icons/Artibulles_Icon_WithText_DC.svg" alt="SipmleEngineering" />
                                    </p>
                                    <p className={classes.HomePageVisionLogoInsideText_P}>
                                        <img className={classes.HomePageVisionLogoInsideText} src="/images/icons/Artibulles_Icon_WithText_CIS.svg" alt="SipmleEngineering" />
                                    </p>
                                    <p className={classes.HomePageVisionLogoInsideText_P}>
                                        <img className={classes.HomePageVisionLogoInsideText} src="/images/icons/Artibulles_Icon_WithText_PM.svg" alt="SipmleEngineering" />
                                    </p>
                                    <p className="HomePage_Vision_Categories_Columns_Message">{HomePageTextVision[FinalLanguageTag].SolutionsStructure.P3}</p>
                                    <h3 className={classes.Vision_Section_TitleIntro}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.P4}</h3>

                                    <h3 className={classes.Vision_Section_TitleIntro}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.P5}</h3>
                                </div>
                                <div className={classes.Vision_Section_Blocks_FlexWrapper} style={{marginTop: "10px"}}>
                                    <div className={clsx(classes.Vision_Block, classes.Vision_Block_Business)}>
                                        <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                            <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Artibulles_logo_ES_black_fontexp.svg" alt="SipmleEngineering" />
                                            <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.ES_Box_Title}</h3>
                                        </div>
                                        <div className={classes.Vision_Block_Body}>
                                            <ul>
                                                {HomePageTextVision[FinalLanguageTag].SolutionsStructure.ES_Box_Description.map((elem) => {
                                                    return <li key={uuidv4()}>{elem}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={clsx(classes.Vision_Block, classes.Vision_Block_Business)}>
                                        <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Green)}>
                                            <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Artibulles_logo_ISO_black_fontexp.svg" alt="SipmleEngineering" />
                                            <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.ISO_Box_Title}</h3>
                                        </div>

                                        <div className={classes.Vision_Block_Body}>
                                            <ul>
                                                {HomePageTextVision[FinalLanguageTag].SolutionsStructure.ISO_Box_Description.map((elem) => {
                                                    return <li key={uuidv4()}>{elem}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={clsx(classes.Vision_Block, classes.Vision_Block_Business)}>
                                        <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Pink)}>
                                            <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Artibulles_logo_DC_black_fontexp.svg" alt="SipmleEngineering" />
                                            <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.DC_Box_Title}</h3>
                                        </div>

                                        <div className={classes.Vision_Block_Body}>
                                            <ul>
                                                {HomePageTextVision[FinalLanguageTag].SolutionsStructure.DC_Box_Description.map((elem) => {
                                                    return <li key={uuidv4()}>{elem}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={clsx(classes.Vision_Block, classes.Vision_Block_Business)}>
                                        <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Blue)}>
                                            <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Artibulles_logo_CIS_black_fontexp.svg" alt="SipmleEngineering" />
                                            <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.CIS_Box_Title}</h3>
                                        </div>

                                        <div className={classes.Vision_Block_Body}>
                                            <ul>
                                                {HomePageTextVision[FinalLanguageTag].SolutionsStructure.CIS_Box_Description.map((elem) => {
                                                    return <li key={uuidv4()}>{elem}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className={clsx(classes.Vision_Block, classes.Vision_Block_Business)}>
                                        <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Yellow)}>
                                            <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Artibulles_logo_PM_black_fontexp.svg" alt="SipmleEngineering" />
                                            <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.PM_Box_Title}</h3>
                                        </div>

                                        <div className={classes.Vision_Block_Body}>
                                            <ul>
                                                {HomePageTextVision[FinalLanguageTag].SolutionsStructure.PM_Box_Description.map((elem) => {
                                                    return <li key={uuidv4()}>{elem}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.VisionSectionBoxWrapper}>
                        <div className={classes.SolutionsStructureBox}>
                            <div className={classes.Vision_Section_Wrapper_Title}>
                                <h2 className={classes.Vision_Section_Wrapper_Title_H}>{HomePageTextVision[FinalLanguageTag].CoreValues.Title}</h2>
                            </div>
                            <div className={classes.HomePage_Vision_Intro_Block}>
                                <h2 style={{textAlgin: "center"}}>{HomePageTextVision[FinalLanguageTag].CoreValues.P1}</h2>
                                <div className={classes.Vision_Section_Blocks_FlexWrapper} style={{marginTop: "10px"}}>
                                    <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                                        <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                            <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Values_expertise_box.svg" alt="Expertise" />
                                            <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box1_Title}</h3>
                                        </div>

                                        <div className={classes.Vision_Block_Body}>
                                            <ul>
                                                {HomePageTextVision[FinalLanguageTag].CoreValues.Box1_Description.map((elem) => {
                                                    return <li key={uuidv4()}>{elem}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                                        <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                            <img className={classes.Vision_Block_Title_Icon} style={{width: "70px", height: "70px"}} src="/images/icons/Values_innovative_box.svg" alt="Innovative" />
                                            <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box2_Title}</h3>
                                        </div>

                                        <div className={classes.Vision_Block_Body}>
                                            <ul>
                                                {HomePageTextVision[FinalLanguageTag].CoreValues.Box2_Description.map((elem) => {
                                                    return <li key={uuidv4()}>{elem}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                                        <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                            <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Values_results_box.svg" alt="Business" />
                                            <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box3_Title}</h3>
                                        </div>

                                        <div className={classes.Vision_Block_Body}>
                                            <ul>
                                                {HomePageTextVision[FinalLanguageTag].CoreValues.Box3_Description.map((elem) => {
                                                    return <li key={uuidv4()}>{elem}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                                        <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                            <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Values_passion_box.svg" alt="Passion" />
                                            <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box4_Title}</h3>
                                        </div>

                                        <div className={classes.Vision_Block_Body}>
                                            <ul>
                                                {HomePageTextVision[FinalLanguageTag].CoreValues.Box4_Description.map((elem) => {
                                                    return <li key={uuidv4()}>{elem}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                                        <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                            <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Values_knowledge_box.svg" alt="Knowledge" />
                                            <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box5_Title}</h3>
                                        </div>

                                        <div className={classes.Vision_Block_Body}>
                                            <ul>
                                                {HomePageTextVision[FinalLanguageTag].CoreValues.Box5_Description.map((elem) => {
                                                    return <li key={uuidv4()}>{elem}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                                        <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                            <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Values_ethical_box.svg" alt="Ethical" />
                                            <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box6_Title}</h3>
                                        </div>

                                        <div className={classes.Vision_Block_Body}>
                                            <ul>
                                                {HomePageTextVision[FinalLanguageTag].CoreValues.Box6_Description.map((elem) => {
                                                    return <li key={uuidv4()}>{elem}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
			<BottomNavBar />
        </Page>
    );

    /***************** RENDER ******************/
};

export default HomePage;
