exports.ContactFormText = {
    English: {
		Name: "Your name",
		Email : "Your email",
        Subject: "Subject",
        Business: "Business",
        Message: "Your message",
    },
    French: {
		Name: "Votre nom",
		Email : "Votre email",
        Subject: "Sujet",
        Business: "Business",
        Message: "Votre message",
	},
};
