import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useMatomo} from "@datapunt/matomo-tracker-react";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Privacy: {
        margin: "0 auto",
        maxWidth: "800px",
	},
	PageTitle : {
		margin : "0 auto",
		textAlign : "center",
		

	}
}));

const PrivacyPolicy = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});const {trackPageView} = useMatomo();

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
 //Matomo Tracking
 React.useEffect(() => {
	trackPageView({
		documentTitle: "PrivacyPolicy_Desktop",
	});
}, [trackPageView]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
		<div className={classes.Privacy}>
		<h1 className={classes.PageTitle}>Privacy policy</h1>
            <p>This site is not storing any information neither uses cookies or traffic tracking</p>
        </div>
    );

    /***************** RENDER ******************/
};

export default PrivacyPolicy;
