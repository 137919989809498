import React from "react";
import {createUseStyles, useTheme} from "react-jss";


import NavTopBarLink from "@artibulles-cis/react/NavTopBarLink";
import NavTopBarSubMenu from "@artibulles-cis/react/NavTopBarSubMenu";
import NavTopBarSubLink from "@artibulles-cis/react/NavTopBarSubLink";


//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CompanyLink: {
        flex: "0 0 250px",
        boxSizing: "border-box",
    },
    CompanyLinkContent: {
        justifyContent: "flex-start",
        fontSize: "30px",
    },
    CompanyLinkActive: {
        // background: "#FF6103",
        // opacity : 0.4
    },
    TopNavIconCompany: {
        width: "55px",
        height: "55px",
    },
    ClassExtendTopNavLink: {
        fontSize: "1rem",
        padding: "0px 5px 0px 5px",
    },
    ClassExtendNavTopBarSubMenu: {
        flex: "0 0 200px",
    },
    ClassExtendNavTopBarSubMenuAdmin: {
        flex: "0 0 200px",
    },
    ClassExtendNavTopBarSubMenu_ControllerAdmin: {
        background: "rgb(255, 68, 0)",
        color: "white",
    },
    TopNavIcon: {
        width: "45px",
        height: "45px",
    },
    "@media only screen and (max-width: 1500px)": {
        BoxBusinessDesc_Content_Media: {
            minHeight: "200px",
        },
        BoxBusiness_Title: {
            color: "white",
            fontSize: "1.2rem",
            padding: "0px",
            margin: "0px",
        },
        BoxBusiness_UL: {
            color: "white",
            "& li": {
                marginTop: "4px",
            },
        },
    },
}));

const TopNavBar = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
	
	
	
    

    return (
        <React.Fragment>
            <NavTopBarLink
                exact
                Slug="/"
                ClassExtendTopNavLink={classes.CompanyLink}
                ClassExtendNavTopBarLinkContent={classes.CompanyLinkContent}
				ClassExtendTopNavLinkActive={classes.CompanyLinkActive}
				
            >
                <img className={classes.TopNavIconCompany} src="/images/icons/ArtibullesLogo.svg" alt="Engineering Solutions" />
                <p style={{padding: "10px"}}>ArTiBulles</p>
            </NavTopBarLink>
            <NavTopBarLink WithActiveIndicator={true} ClassExtendTopNavLink={classes.ClassExtendTopNavLink} Slug="/es">
                <img className={classes.TopNavIcon} src="/images/icons/Artibulles_logo_ES_black_fontexp.svg" alt="Engineering Solutions" />

                <p style={{padding: "10px"}}>Engineering Solutions</p>
            </NavTopBarLink>
            <NavTopBarLink WithActiveIndicator={true} ClassExtendTopNavLink={classes.ClassExtendTopNavLink} Slug="/iso">
                <img className={classes.TopNavIcon} src="/images/icons/Artibulles_logo_ISO_black_fontexp.svg" alt="Engineering Solutions" />
                <p style={{padding: "10px"}}>Innovation, Strategy, Operational Support</p>
            </NavTopBarLink>
            <NavTopBarLink WithActiveIndicator={true} ClassExtendTopNavLink={classes.ClassExtendTopNavLink} Slug="/dc">
                <img className={classes.TopNavIcon} src="/images/icons/Artibulles_logo_DC_black_fontexp.svg" alt="Engineering Solutions" />
                <p style={{padding: "10px"}}>Digital Communication</p>
            </NavTopBarLink>
            <NavTopBarLink WithActiveIndicator={true} ClassExtendTopNavLink={classes.ClassExtendTopNavLink} Slug="/cis">
                <img className={classes.TopNavIcon} src="/images/icons/Artibulles_logo_CIS_black_fontexp.svg" alt="Engineering Solutions" />
                <p style={{padding: "10px"}}>Custom IT Solutions</p>
            </NavTopBarLink>
            <NavTopBarLink WithActiveIndicator={true} ClassExtendTopNavLink={classes.ClassExtendTopNavLink} Slug="/pm">
                <img className={classes.TopNavIcon} src="/images/icons/Artibulles_logo_PM_black_fontexp.svg" alt="Engineering Solutions" />
                <p style={{padding: "10px"}}>Project Management</p>
            </NavTopBarLink>
            <NavTopBarSubMenu
                WithActiveIndicator={true}
                ClassExtendTopNavLink={classes.ClassExtendTopNavLink}
                // Slug="/company"
                SlugList={["/aboutus", "/contact", "/legal"]}
                ControllerContent={<p style={{padding: "10px"}}>Artibulles Company</p>}
            >
                <NavTopBarSubLink Slug="/contact">Contact</NavTopBarSubLink>
            </NavTopBarSubMenu>
        </React.Fragment>
    );
};

export default TopNavBar;
// <NavTopBarSubLink Slug="/contact">Contact</NavTopBarSubLink>
//                 <NavTopBarSubLink Slug="/aboutus">About us</NavTopBarSubLink>
//                 <NavTopBarSubLink Slug="/legal">legal</NavTopBarSubLink>
