exports.TopSection = {
    English: {
		VisitSiteButton : "Visit Artibulles-CIS dedicated Website",
		VisitSiteButtonMobile : "Visit Artibulles-CIS Website",
		Description: ["Websites", "WebApps", "Technical and Scientific Applications", "Complex data analysis and automated processes"],
        CombinedBoxContent: [
            {
                combineWith: "ES",
                title: "A scientific and Engineering knowledge helps a lot for creating IT solutions, whether it is to:",

                list: [
                    "Calculate complex graphic illustrations, creating complex 3d models, creating javascript functions to generate fully responsive and dynamic applications on multiple devices",
                    "Optimize database, processes, calculation time",
                    "Evaluate appropriately new technologies and determine whether it is wise to implement them or not",
                    "Create technical and scientific applications using mathematical models, statistics, or any type of simulation models that can be think of",
                ],
            },
            {
                combineWith: "ISO",
                title: "Innovation in the IT sector is truly a daily thing for new technologies, but it is not always true for the end users. We innovate to:",
                list: [
                    "Provide a unique website, application that does not exist. No copy pastes",
                    "We invent and develop our own tools to improve efficiency and create a better experience for our customers",
                ],
            },
            {
                combineWith: "DC",
                title: "Combining Digital Communication with IT development is obviously a fantastic asset for front-end development:",
                list: [
                    "Generating stunning and harmonized themes, fonts, colors meeting the customer identity",
                    "Creating unique graphical identity with Icons, Pictures that represents the brand identity",
                    "Develop user friendly and custom UI/UX experience for any applications",
                    "Create efficient marketing campaigns, tools, applications that can be distributed in a reliable and impactful manner",
                ],
            },
            {
                combineWith: "PM",
                title: " Project Management expertise brings confidence when developing IT solutions:",
                list: [
                    "Delivering the solutions on Time: Clear schedule, clear expectations",
                    "Ability to discuss cost upfront and adapt the solution in a realistic way for both parties",
                    "Frequent communication and exchange to ensure the final solution will meet all expectations",
                ],
            },
        ],
    },
    French: {},
};
