/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {toast} from "react-toastify";
import Button from "@artibulles-cis/react/Button";
import _ from "lodash";
import {Form, Field} from "react-final-form";
import validator from "validator";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";

import FormTextInput from "@artibulles-cis/react/FormTextInput";
// import FormSelect from "@artibulles-cis/react/FormSelect";
import FormSelect from "../../artiweb-react/FormSelect/FormSelect";

import {ContactFormText} from "../TextContent/ContactPageText";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
    },
    FormTitle: {
        color: "white",
        marginTop: "10px",
        textAlign: "center",
    },

    FixedLoginContent: {
        flex: "0 0 80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
    },
    ImgLogin: {
        width: "60px",
        padding: "10px",
    },
    AppLogin: {
        margin: "0px",
    },
    AnimatedContent: {
        flex: "1 0 auto",
        display: "flex",
        flexDirection: "column",
        padding: "20px 10px 10px 10px",
        alignItems: "center",
    },
    LoginTitle: {
        textAlign: "left",
        margin: 0,
        // alignSelf: "flex-start",
    },
    Form: {
        flex: "1 0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        width: "100%",
    },
    FormField: {
        display: "flex",
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    LoginController: {
        flex: "0 1 auto",
        width: "100%",
        display: "flex",
        // marginLeft: "15px",
        justifyContent: "center",
        // padding: "0px 20px 10px 20px",
    },
    ClassExtendButton: {
        margin: 0,
    },
    QR_Wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "150px",
        height: "150px",
    },
}));

const ContactForm = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
	var FinalLanguageTag;
	const Language = "English";
	if (Language === "English") {
        FinalLanguageTag = "English";
    }else if (Language === "French") {
        FinalLanguageTag = "French";
    }

	
	
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://www.artibulles.com";

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(null);

    const [Loading, setLoading] = useState(false);
    //eslint-disable-next-line
    const [InitalFormValues, setInitalFormValues] = React.useState({
        name: null,
        email: null,
        subject: null,
        business: "01",
        message: null,
    });
    //eslint-disable-next-line
    const [FormValues, setFormValues] = useState({
        otp: null,
    });
    //eslint-disable-next-line
    const [FieldOptions, setFieldOptions] = React.useState({
        business: [
            {value: "01", label: "General"},
            {value: "02", label: "Engineering Solution"},
            {value: "03", label: "Innovation, Strategy, Operational Support"},
            {value: "04", label: "Digital Communication"},
            {value: "05", label: "Custom IT Solutions"},
            {value: "06", label: "Project Management"},
        ],
    });

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                
                //Something went wrong
                // toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
            } else {
                
                toast.success("Thank you for contacting us. We will reply shortly. Check your email for a receipt", {autoClose: 4000, draggable: false});
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const onSubmit = async (values) => {
        var FinalBusiness = _.find(FieldOptions.business, {value: values.business}).label;

        let FinalForm = {
            name: values.name,
            email: values.email,
            subject: values.subject,
            message: values.message,
            business: FinalBusiness,
        };
        const ApiCall = {url: `${ProdURL}/api/emailform`, type: "post"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall.url, "post", FinalForm, null);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    const validate = (values) => {
        const errors = {};
        if (values.name) {
        } else {
            errors.name = "Your name is required";
        }
        if (values.subject) {
        } else {
            errors.subject = "The subject is required";
        }
        if (values.message) {
        } else {
            errors.message = "The message cannot be empty";
        }
        if (values.email) {
            if (validator.isEmail(values.email) === false) {
                errors.email = "This is not a valid email";
            }
        } else {
            errors.email = "Email cannot be empty";
        }

        return errors;
    };

    // const ShareInput = (field, value) => {
    //     setFormValues({...FormValues, ...{[field]: value}});
    // };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <div className={classes.FormWrapper}>
            <LocalCircularLoader WithModalCard={true} Loading={Loading} FullSize={true} />
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={InitalFormValues ? InitalFormValues : {}}
                render={({handleSubmit, form, invalid, submitting, pristine, values}) => (
                    <form className={classes.Artiweb_form_Wrapper_Flex} onSubmit={handleSubmit} noValidate>
                        <div className={classes.Form}>
                            <h1 className={classes.FormTitle}>Contact Form</h1>
                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "1 1 450px"}}>
                                    <Field
                                        name="name"
                                        initialvalue={InitalFormValues.name ? InitalFormValues.name : null}
                                        curvalue={values.name}
                                        label={ContactFormText[FinalLanguageTag].Name}
                                        variant="outlined" //standard,outlined,filled
                                        InputType="text" //text,number,email,password,date
                                        required={true}
                                        // disabled={false}
                                        // readOnly={false}
                                        // defaultValue="ReadOnly" //Usefull for Read Only
                                        // placeHolder="PlaceHolder..."
                                        // HelperMessage="null"
                                        // multiline={true}
                                        // RowsToDisplay="2"
                                        // MaxRows="3"
                                        // LabelScale="'0.75'"
                                        // StyleBorderErrorFocusTrue={true}
                                        Colors={{Empty: "white", Filled: "white", Error: null, Focused: null, Disabled: null}}
                                        // InputFontProp={{fontSize: null, fontFamily: null, fontWeight: null}}
                                        // LabelStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                                        // InputStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                                        // BorderStyle={{Empty: null, Focused: null, Error: null, Disabled: null, BoderRadius: null}}
                                        // HelperStyle={{fontFamily: null, fontSize: null, marginTop: null, color: null}}
                                        // ErrorStyle={{color: null}}
                                        // inputVerticalPadding="null"
                                        component={FormTextInput}
                                    />
                                </div>
                            </div>
                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "1 1 450px"}}>
                                    <Field
                                        name="email"
                                        initialvalue={InitalFormValues.email ? InitalFormValues.email : null}
                                        curvalue={values.email}
                                        label={ContactFormText[FinalLanguageTag].Email}
                                        variant="outlined" //standard,outlined,filled
                                        InputType="email" //text,number,email,password,date
                                        required={true}
                                        // disabled={false}
                                        // readOnly={false}
                                        // defaultValue="ReadOnly" //Usefull for Read Only
                                        // placeHolder="PlaceHolder..."
                                        // HelperMessage="null"
                                        // multiline={true}
                                        // RowsToDisplay="2"
                                        // MaxRows="3"
                                        // LabelScale="'0.75'"
                                        // StyleBorderErrorFocusTrue={true}
                                        Colors={{Empty: "white", Filled: "white", Error: null, Focused: null, Disabled: null}}
                                        // InputFontProp={{fontSize: null, fontFamily: null, fontWeight: null}}
                                        // LabelStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                                        // InputStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                                        // BorderStyle={{Empty: null, Focused: null, Error: null, Disabled: null, BoderRadius: null}}
                                        // HelperStyle={{fontFamily: null, fontSize: null, marginTop: null, color: null}}
                                        // ErrorStyle={{color: null}}
                                        // inputVerticalPadding="null"
                                        component={FormTextInput}
                                    />
                                </div>
                            </div>
                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "1 1 450px"}}>
                                    <Field
                                        name="subject"
                                        initialvalue={InitalFormValues.subject ? InitalFormValues.subject : null}
                                        curvalue={values.subject}
                                        label={ContactFormText[FinalLanguageTag].Subject}
                                        variant="outlined" //standard,outlined,filled
                                        InputType="text" //text,number,email,password,date
                                        required={true}
                                        // disabled={false}
                                        // readOnly={false}
                                        // defaultValue="ReadOnly" //Usefull for Read Only
                                        // placeHolder="PlaceHolder..."
                                        // HelperMessage="null"
                                        // multiline={true}
                                        // RowsToDisplay="2"
                                        // MaxRows="3"
                                        // LabelScale="'0.75'"
                                        // StyleBorderErrorFocusTrue={true}
                                        Colors={{Empty: "white", Filled: "white", Error: null, Focused: null, Disabled: null}}
                                        // InputFontProp={{fontSize: null, fontFamily: null, fontWeight: null}}
                                        // LabelStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                                        // InputStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                                        // BorderStyle={{Empty: null, Focused: null, Error: null, Disabled: null, BoderRadius: null}}
                                        // HelperStyle={{fontFamily: null, fontSize: null, marginTop: null, color: null}}
                                        // ErrorStyle={{color: null}}
                                        // inputVerticalPadding="null"
                                        component={FormTextInput}
                                    />
                                </div>
                            </div>
                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "1 1 450px"}}>
                                    <Field
                                        name="business"
                                        initialvalue={InitalFormValues.business ? InitalFormValues.business : null}
                                        options={FieldOptions.business}
                                        curvalue={values.business}
                                        // UpdateOptionsFromOutside={(Options, selected) => UpdateCreatableOptions(Options, selected, "servingUnit")} //To use if you want to add all created values as options even if the user deselect it",
                                        label={ContactFormText[FinalLanguageTag].Business}
                                        MultiSelect={false}
                                        AllowCreateOption={false}
                                        // RemoveOptionIfUnselected={true}
                                        // variant="outlined" //standard,outlined,filled
                                        // required={true}
                                        // readOnly={true}
                                        // disabled={true}
                                        Clearable={false}
                                        // isSearchable={true}
                                        // placeHolder="PlaceHolder"
                                        // HelperMessage="HelperMessage"
                                        // sortSelectOptions="asc" //asc,desc
                                        // minMenuHeight="100px"
                                        // maxMenuHeight="150px"
                                        // menuPlacement="auto" //top,bottom,auto
                                        // AlternateRowStyle={false}
                                        // MenuMaxHeight="200px"
                                        // LabelScale="'0.75'"
                                        // StyleBorderErrorFocusTrue={true}
                                        Colors={{Empty: "white", Filled: "white", Error: null, Focused: "white", Disabled: null}}
                                        // InputFontProp={{fontSize: null, fontFamily: null, fontWeight: null}}
                                        // LabelStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                                        // InputStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                                        // BorderStyle={{Empty: null, Focused: null, Error: null, Disabled: null, BoderRadius: null}}
                                        // HelperStyle={{fontFamily: null, fontSize: null, marginTop: null, color: null}}
                                        // ErrorStyle={{color: null}}
                                        // inputVerticalPadding="null"
                                        isSearchable={false}
                                        component={FormSelect}
                                    />
                                </div>
                            </div>
                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "1 1 450px"}}>
                                    <Field
                                        name="message"
                                        initialvalue={InitalFormValues.message ? InitalFormValues.message : null}
                                        curvalue={values.message}
                                        label={ContactFormText[FinalLanguageTag].Message}
                                        variant="outlined" //standard,outlined,filled
                                        InputType="text" //text,number,email,password,date
                                        required={true}
                                        // disabled={false}
                                        // readOnly={false}
                                        // defaultValue="ReadOnly" //Usefull for Read Only
                                        // placeHolder="PlaceHolder..."
                                        // HelperMessage="null"
                                        multiline={true}
                                        RowsToDisplay="5"
                                        MaxRows="10"
                                        // LabelScale="'0.75'"
                                        // StyleBorderErrorFocusTrue={true}
                                        Colors={{Empty: "white", Filled: "white", Error: null, Focused: null, Disabled: null}}
                                        // InputFontProp={{fontSize: null, fontFamily: null, fontWeight: null}}
                                        // LabelStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                                        // InputStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                                        // BorderStyle={{Empty: null, Focused: null, Error: null, Disabled: null, BoderRadius: null}}
                                        // HelperStyle={{fontFamily: null, fontSize: null, marginTop: null, color: null}}
                                        // ErrorStyle={{color: null}}
                                        // inputVerticalPadding="null"
                                        component={FormTextInput}
                                    />
                                </div>
                            </div>

                            <div className={classes.LoginController}>
                                <Button
                                    Width="200px"
                                    ClassExtendButton={classes.ClassExtendButton}
                                    type="submit"
                                    disabled={invalid || submitting || pristine}
                                    onClick={() => form.change("submitype", "Login")}
                                >
                                    Send
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            />
        </div>
    );

    /***************** RENDER ******************/
};

export default ContactForm;
