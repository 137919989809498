import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import Page from "@artibulles-cis/react/Page";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Legal: {
        margin: "0 auto",
        maxWidth: "800px",
    },
    PageTitle: {
        margin: "0 auto",
        textAlign: "center",
    },
}));

const Legal = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    const {trackPageView} = useMatomo();

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
 //Matomo Tracking
 React.useEffect(() => {
	trackPageView({
		documentTitle: "Legal_Desktop",
	});
}, [trackPageView]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Page PagePadding="10px">
            <div className={classes.Legal}>
                <h1 className={classes.PageTitle}>Legal</h1>
                <h2>Contact</h2>
                <p>Email: contact@artibulles.com</p>
                <p>Address: Avenue de Tervueren, 180 – 1150 Woluwe-Saint-Pierre Belgium</p>
                <p>Phone: +32 23 15 59 00</p>
                <h2>WebSite and Hosting</h2>
                <p>Website desgin: ArTiBulles-CIS</p>
                <p>Hosting: OVH</p>
                <h2>Company</h2>
                <p>Artibulles</p>
                <p>Enterprise number: 0670.860.215 </p>
                <p>VAT: BE0670860215</p>​<h2>Intelecutal property</h2>
                <p>
                    Copyright 2020 ArTiBulles. All rights reserved. No part of ArTiBulles's Website may be reproduced, modified, or distributed in any form or manner without the prior written
                    permission of ArTiBulles.
                </p>
            </div>
        </Page>
    );

    /***************** RENDER ******************/
};

export default Legal;
// Reproduction interdite

// L’ensemble de ce site relève de la législation belge sur le droit d’auteur et la propriété intellectuelle.

// La reproduction de tout ou partie de ce site est strictement interdite sans autorisation. Tous droits réservés.

// Protection de la vie privée

// Les informations personnelles recueillies sont destinées à un usage strictement interne.

// Aucune information personnelle ne sera cédée ni vendue à un tiers. Vous disposez d’un droit d’accès, de rectification, de modification et de suppression des données vous concernant.

// Vous pouvez exercer ce droit en nous envoyant un email à l’adresse contact@artibulles.com
