exports.HomePageText = {
    English: {
        TopTitle: "ArTiBulles",
        SubTitle: "Applicable Innovative Solutions",
        VisionButton_Vision: "OUR VISION",
        VisionButton_Home: "HOME",
        ES_Box_Title: "Engineering Solutions",
        ES_Box_Description: [
            "Developing complete engineering solutions",
            "Provide consulting support on partial/complete solutions",
            "Provide consulting advices for problem solving",
            "Evaluate, assess, recommend optimal technologies",
            "Create study design (DOEs), specifications, verification studies, perform risk analysis",
        ],
        ISO_Box_Title: "Innovation, Strategy, Operational support",
        ISO_Box_Description: [
            "Develop innovative solutions based on your needs through consulting",
            "Implement/improve your innovation process using our Smart Innovation Tool",
            "Develop, assess and improve your development processes to improve operational efficiency",
            "Evaluate and propose strategies for product development and product expansion",
        ],
        DC_Box_Title: "Digital Communication",
        DC_Box_Description: ["Communication strategy", "Branding & visual identity", "Graphic design", "Presentations"],
        CIS_Box_Title: "Custom IT Solutions",
        CIS_Box_Description: ["Websites", "Webapps", "Technical and scientific applications", "Complex data analysis and automated processes"],
        PM_Box_Title: "Project Management",
        PM_Box_Description: [
            "Develop strategies, plan and schedule",
            "Manage daily communication on schedule, cost, time",
            "Ensure efficient and smooth communication between multi-sites and multi-departments collaborators",
            "Resolve communication issues, ensure the focus is on delivering the right product at the right time",
            "Optimize plan and schedule throughout the project to optimize time to market",
            "Communicate to key stakeholders",
        ],
    },
    French: {},
};
exports.HomePageTextVision = {
    English: {
        Title: "Our Vision",
        Goal: "The goal of ArTiBulles is to develop useful and impactful innovative concepts and solutions to help companies to grow",
        IntroSection: {
            Title1: "Technologies and businesses are evolving faster every day, requiring increased communication skills, multi-cultural understanding, and multi-discipline knowledge.",
            Title2: "Having a strong expertise in one domain at a given time is not enough anymore.",
            P1:
                "In this context, the key to successful business is the ability to adapt and evolve quickly by learning and developing new technologies while delivering the appropriate solutions on time with a high level of efficiency and profit.",
            P2: "Our answer to that difficult problem is the following:",
            Bullets: [
                "We have organized our businesses in 5 well defined categories to enable excellence and focus on delivering the best possible solutions in each of them.",
                "We rely on 6 core principles to ensure consistency and quality in each business while leveraging key competencies and exchanging knowledge and tools across those businesses.",
            ],
        },
        SolutionsStructure: {
            Title: "Structure of our solutions",
            P1: "Our core businesses are",
            P2:
                "With the nature of companies, using multiple partners located all over the world, the need to communicate more efficiently using digital technologies, managing the increasing flow of information and data, we have developed solutions and services in:",
            P3:
                "The idea is not to isolate those domains of activities but a the contrary to develop strong capabilities in each of them and then combine and share the knowledge and capabilities across all of them to make each of them better and propose efficient solutions faster.",
            P4: "We leverage each business to transversally share the knowledge, tools and skills across all domains.",
            P5:
                "To ensure we deliver the best solutions to our customer in a consistent manner while continuously evolving and improving our solutions, we rely on our 6 core principles of operations described below.",
            ES_Box_Title: "Engineering Solutions",
            ES_Box_Description: ["Collect data and facts", "Analyze trends, build mathematical models, optimize outcomes", "Focus the outcome on quality cost, timing, resources"],
            ISO_Box_Title: "Innovation, Strategy, Operational support",
            ISO_Box_Description: [
                "Frame a problem, define it correctly, understand expectations",
                "Brainstorm to define solutions, push the boundaries, ensure the critical processes are fully identified and understood",
                "Incorporate time, resources and cost in the selection process",
            ],
            DC_Box_Title: "Digital Communication",
            DC_Box_Description: [
                "Illustrate a vision, simplify complex situations to extract the key messages",
                "Create impactful communication focused on the content of the vision and an identity of the customer",
                "Leverage modern digital tools to deliver impactful communication",
            ],
            CIS_Box_Title: "Custom IT Solutions",
            CIS_Box_Description: [
                "Use the most efficient and modern programming languages and platforms",
                "Automate recurrent tasks (create scientific reports, financial dashboards, invoices)",
                "Create custom operational tools to minimize redo",
            ],
            PM_Box_Title: "Project Management",
            PM_Box_Description: [
                "Use scientific tools and processes to identify critical chains and optimize the schedule",
                "Keep the focus on time and cost",
                "Create operational tools to minimize recurrent tasks and deliver efficient and harmonized communication across the organization",
                "Translate strategy into plan and schedule",
            ],
        },
        CoreValues: {
            Title: "Our 6 core values",
            P1:
                "We have carefully developed and are implementing every day the following principles that enable us to deliver consistent quality across all businesses and help us to share lessons and knowledge across all those businesses.",
            Box1_Title: "Expertise - Science and Facts",
            Box1_Description: ["Why we do something?", "How we do something?", "How and why we will do it in the future?"],
            Box2_Title: "Innovative thinking - Expanding boundaries",
            Box2_Description: ["Problem- Method - Solution", "Critical thinking", "Innovate to improve not to be different", "State of mind / process"],
            Box3_Title: "Business minded - Results oriented",
            Box3_Description: ["Concrete results driven", "Simple and efficient", "Make things easier for people"],
            Box4_Title: "Passion - Fun",
            Box4_Description: ["Passion to change things to continuously improve, and evolve", "Eagerness to discover and learn", "Go beyond the limits"],
            Box5_Title: "Knowledge management - Cross-pollination",
            Box5_Description: ["Export and share knowledge across domains", "No silos, but open minded", "Reuse what works"],
            Box6_Title: "Ethical - Durable - Responsible",
            Box6_Description: ["Humility - know when you don't know", "Partnership", "Long term strategy - not just one-time quick fix", "Environmental awareness"],
        },
    },
};
