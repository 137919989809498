import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Link, useHistory} from "react-router-dom";
import clsx from "clsx";
import {v4 as uuidv4} from "uuid";
import useWebsiteAppInitContext from "@artibulles-cis/react/utils/context/websiteAppInitContext/useWebsiteAppInitContext";
import {HomePageText} from "../TextContent/HomePageText";
import HomePageVision from "./HomePageVision";
import {useMatomo} from "@datapunt/matomo-tracker-react";
//Styling the components with variables
const BoxAnimationOn = "0.4s";
const BoxAnimationOff = "0.4s";
const SvgScaling = 1.3;
const BusinessBoxBorderWidth = "4px";
const BusinessBoxBorderRadius = "10px";
// const BusinessBoxBackgroundColor = "rgb(222, 222, 222)";
const BusinessBoxBackgroundColor = "rgb(10, 10, 10)";
const CircleLinesStrokeWidth = 5;

// const FlipVisionMainTransition = "transform 1s cubic-bezier(0, 1.13, 0.8, 1.21)"
const FlipVisionMainTransition = "transform 1s cubic-bezier(0, .8, 0.8, 1.18)";

const styles = createUseStyles((theme, props) => ({
    Background: {
        position: "relative",
        height: "100%",
        width: "100%",
        backgroundImage: "url(/images/backgrounds/homePage_Background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    SectionTop: {},

    Top_Wrapper: {
        position: "relative",
        height: (props) => props.SectionTopHeight + "px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "white",
    },

    Header_Separtor: {
        display: "block",
        position: "absolute",
        width: "100%",
        height: (props) => props.SectionTopHeight + "px",
        zIndex: 10,
        margin: 0,
        padding: 0,
        top: "0px",
    },

    SVGseparator: {
        position: "relative",
        height: (props) => props.SectionTopHeight + "px",
        width: "100%",
        display: "block",
        padding: "auto",
        margin: "auto",
    },

    Separator: {
        fill: "black",
    },

    AnimatedLogoWrapper: {
        position: "absolute",
        padding: 0,
        margin: "0 auto",
        width: "100%",
        display: "block",
        zIndex: 10,
    },

    AnimatedLogo: {
        display: "block",
        height: (props) => props.SectionTopHeight - props.AnimatedLogoPaddingTop + "px",
        margin: "0 auto",
        opacity: 0.4,
    },
    Titles_Wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 10,
    },

    Animated_ButtonHomeVision_Wrapper: {
        backgroundColor: "transparent",
        perspective: "2000px",
        width: "150px",
        height: "40px",
    },
    Animated_ButtonHomeVision: {
        position: "relative",
        transition: FlipVisionMainTransition,
        transformStyle: "preserve-3d",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },

    Animated_ButtonHomeVision_ShowVision: {
        transform: "rotateY(180deg)",
    },
    Animated_ButtonHomeVision_Vision: {
        display: "block",
        backfaceVisibility: "hidden",
        position: "absolute",
        width: "100%",
        opacity: 1,
        transition: "opacity 0.6s",
    },

    Animated_ButtonHomeVision_Vision_Hidden: {
        opacity: 0,
        transition: "opacity 0.6s",
    },
    Animated_ButtonHomeVision_Home: {
        display: "block",
        transform: "rotateY(180deg)",
        backfaceVisibility: "hidden",
        position: "absolute",
        width: "100%",
    },

    SubTitle: {
        margin: 0,
        textAlign: "center",
        fontSize: "1.7rem",
    },

    HomePageCompanyH1: {
        margin: 0,
        textAlign: "center",
        fontSize: "2rem",
    },

    Section_Main: {
        position: "relative",
    },
    MainWrapper: {
        display: "flex",
        // height: "1000px",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 20px 0px 20px",
        boxSizing: "border-box",
        transition: "all 0.4s",
        fontSize: "1rem",
        width: "100%",
        maxWidth: "1600px",
        margin: "0 auto",
    },
    MainWrapper_FlexLeft: {
        flex: (props) => `0 1 ${props.MainFlexSidesMaxWidth}px`,
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    MainWrapper_FlexCenter: {
        display: "flex",
        flex: "0 1 auto",
        justifyContent: "center",
    },
    MainWrapper_FlexRight: {
        flex: (props) => `0 1 ${props.MainFlexSidesMaxWidth}px`,
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },

    BoxBusinessDesc_Content_PM: {
        stroke: (props) => props.ArtibullesColors.Yellow,
        strokeWidth: BusinessBoxBorderWidth,
        fill: BusinessBoxBackgroundColor,
    },

    BoxBusinessDesc_Content_PM_Text: {
        fontFamily: "Roboto-Bold, Roboto",
        fontSize: "1.2rem",
        fontWeight: "bold",
        letterSpacing: 0.744000137,
        fill: (props) => props.ArtibullesColors.Yellow,
    },

    BoxBusinessDesc_Wrapper: {
        display: "flex",
        flex: "1 0 auto",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    BoxBusinessDesc_Content: {
        width: "100%",
        borderRadius: BusinessBoxBorderRadius,
        padding: "10px",
        backgroundColor: BusinessBoxBackgroundColor,
        transition: `all ${BoxAnimationOff}`,
        color: "black",
        zIndex: 200,
        opacity: 1,
    },
    BoxBusinessDesc_Content_Hover: {
        transform: "scale(1.05)",
        transition: `all ${BoxAnimationOn}`,
    },
    BoxBusinessDesc_Content_NotHover: {
        opacity: 0.3,
        transition: `all ${BoxAnimationOff}`,
    },
    BoxBusinessDesc_PM: {
        opacity: 1,
        transition: `all ${BoxAnimationOff}`,
    },

    BoxBusinessDesc_PM_NotHover: {
        opacity: 0.3,
        transition: `all ${BoxAnimationOff}`,
    },

    BoxBusinessDesc_Content_ES: {
        border: (props) => `solid ${BusinessBoxBorderWidth} ${props.ArtibullesColors.Orange}`,
    },
    BoxBusinessDesc_Content_ISO: {
        border: (props) => `solid ${BusinessBoxBorderWidth} ${props.ArtibullesColors.Green}`,
    },
    BoxBusinessDesc_Content_DC: {
        border: (props) => `solid ${BusinessBoxBorderWidth} ${props.ArtibullesColors.Pink}`,
    },
    BoxBusinessDesc_Content_CIS: {
        border: (props) => `solid ${BusinessBoxBorderWidth} ${props.ArtibullesColors.Blue}`,
    },
    BoxBusinessDesc_Content_Media: {
        minHeight: "250px",
    },

    BoxBusiness_Title: {
        fontSize: "1.2rem",
        padding: "0px",
        margin: "0px",
        color: "white",
    },
    BoxBusinessTitle_ES: {
        color: (props) => props.ArtibullesColors.Orange,
    },
    BoxBusinessTitle_ISO: {
        color: (props) => props.ArtibullesColors.Green,
    },
    BoxBusinessTitle_DC: {
        color: (props) => props.ArtibullesColors.Pink,
    },
    BoxBusinessTitle_CIS: {
        color: (props) => props.ArtibullesColors.Blue,
    },

    BoxBusiness_UL: {
        margin: "10px 0px 0px 0px",
        color: "white",
        "& li": {
            marginTop: "4px",
        },
    },
    CircleGroup: {
        transform: "scale(1)",
        transition: `all ${BoxAnimationOff}`,
        opacity: 1,
    },
    CircleGroup_Hover: {
        transform: `scale(${SvgScaling})`,
        transition: `all ${BoxAnimationOn}`,
        opacity: 1,
    },
    CircleGroup_NotHover: {
        transition: `all ${BoxAnimationOn}`,
        opacity: 0.4,
    },
    CircleGroup_ES: {
        transformOrigin: "622px 344px 0px",
    },
    CircleGroup_ISO: {
        transformOrigin: "818px 344px 0px",
    },
    CircleGroup_DC: {
        transformOrigin: "600px 636px 0px",
    },
    CircleGroup_CIS: {
        transformOrigin: "842px 636px 0px",
    },

    CircleGroup_Circle_ES: {
        // fill: (props) => props.ArtibullesColors.Orange,
        fill: "none",
    },
    CircleGroup_Line_ES: {
        stroke: (props) => props.ArtibullesColors.Orange,
        strokeWidth: CircleLinesStrokeWidth,
    },
    CircleGroup_Circle_DC: {
        // fill: (props) => props.ArtibullesColors.Pink,
        fill: "none",
    },
    CircleGroup_Line_DC: {
        stroke: (props) => props.ArtibullesColors.Pink,
        strokeWidth: CircleLinesStrokeWidth,
    },
    CircleGroup_Circle_ISO: {
        // fill: (props) => props.ArtibullesColors.Green,
        fill: "none",
    },
    CircleGroup_Line_ISO: {
        stroke: (props) => props.ArtibullesColors.Green,
        strokeWidth: CircleLinesStrokeWidth,
    },
    CircleGroup_Circle_CIS: {
        // fill: (props) => props.ArtibullesColors.Blue,
        fill: "none",
    },
    CircleGroup_Line_CIS: {
        stroke: (props) => props.ArtibullesColors.Blue,
        strokeWidth: CircleLinesStrokeWidth,
    },
    CircleGroup_Circle_PM: {
        // fill: (props) => props.ArtibullesColors.Yellow,
        fill: "none",
    },
    MainSectionAnimated_Wrapper: {
        backgroundColor: "transparent",
        perspective: "2000px",
    },
    MainSectionAnimated: {
        position: "relative",
        transition: FlipVisionMainTransition,
        transformStyle: "preserve-3d",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    MainSectionAnimated_ShowVision: {
        transform: "rotateY(180deg)",
    },
    HomePage_Animated_Main: {
        display: "block",
        backfaceVisibility: "hidden",
        position: "absolute",
        width: "100%",
        opacity: 1,
        transition: "opacity 0.6s",
    },

    HomePage_Animated_Main_Hidden: {
        opacity: 0,
        transition: "opacity 0.6s",
    },
    HomePage_Animated_Vision: {
        display: "block",
        transform: "rotateY(180deg)",
        backfaceVisibility: "hidden",
        position: "absolute",
        width: "100%",
    },

    "@media only screen and (max-width: 1500px)": {
        BoxBusinessDesc_Content_Media: {
            minHeight: "200px",
        },

        BoxBusiness_Title: {
            color: "white",
            fontSize: "1.2rem",
            padding: "0px",
            margin: "0px",
        },
        BoxBusiness_UL: {
            color: "white",
            "& li": {
                marginTop: "4px",
            },
        },
    },
}));

const MaxWrapperSVGHOME = 1600 - 40;
const MaxRectangleWidth = 500;
const HomeSVGRatio = 731 / 464;

const HomePage = (props) => {
    const {SectionTopHeight} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    const History = useHistory();
    const {trackPageView, trackEvent} = useMatomo();
    // const SectionTopHeight = 200;
    // const AnimatedLogoHeight = SectionTopHeight-20;
    const [MainSectionHeight, setMainSectionHeight] = React.useState(null);
    const [VisionStatusOpen, setVisionStatusOpen] = React.useState(false);

    const [IconsHover, setIconsHover] = React.useState({
        ES: false,
        ISO: false,
        DC: false,
        CIS: false,
        PM: false,
    });

    const {websiteAppInitContextState} = useWebsiteAppInitContext();

    const windowHeight = websiteAppInitContextState.clientWindowDimensions.windowHeight;

    const Language = "English";
    var FinalLanguageTag;
    if (Language === "English") {
        FinalLanguageTag = "English";
    }
    //Calculating the height of the main section element
    React.useEffect(() => {
        let MainHeight = windowHeight - SectionTopHeight - 60;
        setMainSectionHeight(MainHeight);
    }, [windowHeight, SectionTopHeight]);

    //Matomo Tracking
    React.useEffect(() => {
        trackPageView({
            documentTitle: "HomePage_Desktop",
        });
    }, [trackPageView]);

    var SVGWidth, SVGHeight;
    SVGWidth = MaxWrapperSVGHOME - 2 * MaxRectangleWidth;
    SVGHeight = SVGWidth * HomeSVGRatio;
    if (SVGHeight > MainSectionHeight) {
        SVGWidth = MainSectionHeight / HomeSVGRatio;
        SVGHeight = SVGWidth * HomeSVGRatio;
    }

    const handleVisionClick = () => {
        setVisionStatusOpen(!VisionStatusOpen);
        //Matomo Tracking
        trackEvent({category: "HomePage_Desktop", action: `Vision Button Click`});
    };
    const HandlePMClick = () => {
        History.push("/pm");
    };

    const HandleBoxDescMouseEnter = (name) => {
        //Ensure that we desactivate all other Mouse Leave in case it is too fast
        let UpdatesState = {
            ES: false,
            ISO: false,
            DC: false,
            CIS: false,
            PM: false,
        };

        setIconsHover({...UpdatesState, ...{[name]: true}});
        //Matomo Tracking
        trackEvent({category: "HomePage_Desktop", action: `Hover HomePage SVG Element : ${name}`});
    };

    const HandleBoxDescMouseLeave = (name) => {
        setIconsHover({...IconsHover, ...{[name]: false}});
    };

    //Changing classes when hovering the boxes
    var ClassBoxCommon = clsx(classes.BoxBusinessDesc_Content, classes.BoxBusinessDesc_Content_Media);
    var ClassCircleGroupCommon = clsx(classes.CircleGroup);

    var ClassES = clsx(ClassBoxCommon, classes.BoxBusinessDesc_Content_ES);
    var ClassCIS = clsx(ClassBoxCommon, classes.BoxBusinessDesc_Content_CIS);
    var ClassISO = clsx(ClassBoxCommon, classes.BoxBusinessDesc_Content_ISO);
    var ClassDC = clsx(ClassBoxCommon, classes.BoxBusinessDesc_Content_DC);
    var ClassPM = clsx(classes.BoxBusinessDesc_PM);

    var ClassCircleGroupES = clsx(ClassCircleGroupCommon, classes.CircleGroup_ES);
    var ClassCircleGroupCIS = clsx(ClassCircleGroupCommon, classes.CircleGroup_CIS);
    var ClassCircleGroupISO = clsx(ClassCircleGroupCommon, classes.CircleGroup_ISO);
    var ClassCircleGroupDC = clsx(ClassCircleGroupCommon, classes.CircleGroup_DC);

    if (IconsHover.ES === true) {
        ClassES = clsx(ClassES, classes.BoxBusinessDesc_Content_Hover);
        ClassCIS = clsx(ClassCIS, classes.BoxBusinessDesc_Content_NotHover);
        ClassISO = clsx(ClassISO, classes.BoxBusinessDesc_Content_NotHover);
        ClassDC = clsx(ClassDC, classes.BoxBusinessDesc_Content_NotHover);
        ClassPM = clsx(classes.BoxBusinessDesc_PM_NotHover);

        ClassCircleGroupES = clsx(ClassCircleGroupES, classes.CircleGroup_Hover);
        ClassCircleGroupDC = clsx(ClassCircleGroupDC, classes.CircleGroup_NotHover);
        ClassCircleGroupISO = clsx(ClassCircleGroupISO, classes.CircleGroup_NotHover);
        ClassCircleGroupCIS = clsx(ClassCircleGroupCIS, classes.CircleGroup_NotHover);
    }
    if (IconsHover.CIS === true) {
        ClassCIS = clsx(ClassCIS, classes.BoxBusinessDesc_Content_Hover);
        ClassES = clsx(ClassES, classes.BoxBusinessDesc_Content_NotHover);
        ClassISO = clsx(ClassISO, classes.BoxBusinessDesc_Content_NotHover);
        ClassDC = clsx(ClassDC, classes.BoxBusinessDesc_Content_NotHover);
        ClassPM = clsx(classes.BoxBusinessDesc_PM_NotHover);

        ClassCircleGroupES = clsx(ClassCircleGroupES, classes.CircleGroup_NotHover);
        ClassCircleGroupDC = clsx(ClassCircleGroupDC, classes.CircleGroup_NotHover);
        ClassCircleGroupISO = clsx(ClassCircleGroupISO, classes.CircleGroup_NotHover);
        ClassCircleGroupCIS = clsx(ClassCircleGroupCIS, classes.CircleGroup_Hover);
    }
    if (IconsHover.ISO === true) {
        ClassISO = clsx(ClassISO, classes.BoxBusinessDesc_Content_Hover);
        ClassCIS = clsx(ClassCIS, classes.BoxBusinessDesc_Content_NotHover);
        ClassES = clsx(ClassES, classes.BoxBusinessDesc_Content_NotHover);
        ClassDC = clsx(ClassDC, classes.BoxBusinessDesc_Content_NotHover);
        ClassPM = clsx(classes.BoxBusinessDesc_PM_NotHover);

        ClassCircleGroupES = clsx(ClassCircleGroupES, classes.CircleGroup_NotHover);
        ClassCircleGroupDC = clsx(ClassCircleGroupDC, classes.CircleGroup_NotHover);
        ClassCircleGroupISO = clsx(ClassCircleGroupISO, classes.CircleGroup_Hover);
        ClassCircleGroupCIS = clsx(ClassCircleGroupCIS, classes.CircleGroup_NotHover);
    }
    if (IconsHover.DC === true) {
        ClassDC = clsx(ClassDC, classes.BoxBusinessDesc_Content_Hover);
        ClassCIS = clsx(ClassCIS, classes.BoxBusinessDesc_Content_NotHover);
        ClassISO = clsx(ClassISO, classes.BoxBusinessDesc_Content_NotHover);
        ClassES = clsx(ClassES, classes.BoxBusinessDesc_Content_NotHover);
        ClassPM = clsx(classes.BoxBusinessDesc_PM_NotHover);

        ClassCircleGroupES = clsx(ClassCircleGroupES, classes.CircleGroup_NotHover);
        ClassCircleGroupDC = clsx(ClassCircleGroupDC, classes.CircleGroup_Hover);
        ClassCircleGroupISO = clsx(ClassCircleGroupISO, classes.CircleGroup_NotHover);
        ClassCircleGroupCIS = clsx(ClassCircleGroupCIS, classes.CircleGroup_NotHover);
    }
    if (IconsHover.PM === true) {
        ClassPM = clsx(ClassPM);
        ClassCIS = clsx(ClassCIS, classes.BoxBusinessDesc_Content_NotHover);
        ClassISO = clsx(ClassISO, classes.BoxBusinessDesc_Content_NotHover);
        ClassDC = clsx(ClassDC, classes.BoxBusinessDesc_Content_NotHover);
        ClassES = clsx(ClassES, classes.BoxBusinessDesc_Content_NotHover);

        ClassCircleGroupES = clsx(ClassCircleGroupES, classes.CircleGroup_NotHover);
        ClassCircleGroupDC = clsx(ClassCircleGroupDC, classes.CircleGroup_NotHover);
        ClassCircleGroupISO = clsx(ClassCircleGroupISO, classes.CircleGroup_NotHover);
        ClassCircleGroupCIS = clsx(ClassCircleGroupCIS, classes.CircleGroup_NotHover);
    }

    //Circles And Images Inside Cirlces Parameters
    const CircleRad = 70;
    const ImgDim = 100;

    const CircleDimES = {
        cx: 637,
        cy: 344,
        r: CircleRad,
    };

    const CircleImgES = {
        x: CircleDimES.cx - ImgDim / 2,
        y: CircleDimES.cy - ImgDim / 2,
        dim: ImgDim,
    };

    const CircleDimDC = {
        cx: 605,
        cy: 646,
        r: CircleRad,
    };

    const CircleImgDC = {
        x: CircleDimDC.cx - ImgDim / 2,
        y: CircleDimDC.cy - ImgDim / 2,
        dim: ImgDim,
    };

    const CircleDimCIS = {
        cx: 835,
        cy: 646,
        r: CircleRad,
    };

    const CircleImgCIS = {
        x: CircleDimCIS.cx - ImgDim / 2,
        y: CircleDimCIS.cy - ImgDim / 2,
        dim: ImgDim,
    };
    const CircleDimISO = {
        cx: 800,
        cy: 344,
        r: CircleRad,
    };

    const CircleImgISO = {
        x: CircleDimISO.cx - ImgDim / 2,
        y: CircleDimISO.cy - ImgDim / 2,
        dim: ImgDim,
    };

    const CircleRadPM = 45;
    const ImgDimPM = 82;

    const CircleDimPM = {
        cx: 142,
        cy: 58,
        r: CircleRadPM,
    };

    const CircleImgPM = {
        x: CircleDimPM.cx - ImgDimPM / 2,
        y: CircleDimPM.cy - ImgDimPM / 2,
        dim: ImgDimPM,
    };

    const ImageSrc_ES = "/images/icons/Artibulles_logo_ES_black_fontexp.svg";
    const ImageSrc_ISO = "/images/icons/Artibulles_logo_ISO_black_fontexp.svg";
    const ImageSrc_DC = "/images/icons/Artibulles_logo_DC_black_fontexp.svg";
    const ImageSrc_CIS = "/images/icons/Artibulles_logo_CIS_black_fontexp.svg";
    const ImageSrc_PM = "/images/icons/Artibulles_logo_PM_black_fontexp.svg";

    var ClassMainSectionAnimated = clsx(classes.MainSectionAnimated);
    if (VisionStatusOpen === true) {
        ClassMainSectionAnimated = clsx(ClassMainSectionAnimated, classes.MainSectionAnimated_ShowVision);
    }
    var ClassHomePage_Animated_Main = classes.HomePage_Animated_Main;
    if (VisionStatusOpen === false) {
        ClassHomePage_Animated_Main = classes.HomePage_Animated_Main;
    } else {
        ClassHomePage_Animated_Main = clsx(ClassHomePage_Animated_Main, classes.HomePage_Animated_Main_Hidden);
    }

    var ClassAnimated_ButtonHomeVision = clsx(classes.Animated_ButtonHomeVision);
    if (VisionStatusOpen === true) {
        ClassAnimated_ButtonHomeVision = clsx(ClassAnimated_ButtonHomeVision, classes.Animated_ButtonHomeVision_ShowVision);
    }
    var ClassAnimated_ButtonHomeVision_Vision = classes.Animated_ButtonHomeVision_Vision;

    if (VisionStatusOpen === false) {
        ClassAnimated_ButtonHomeVision_Vision = classes.Animated_ButtonHomeVision_Vision;
    } else {
        ClassAnimated_ButtonHomeVision_Vision = clsx(ClassAnimated_ButtonHomeVision_Vision, classes.Animated_ButtonHomeVision_Vision_Hidden);
    }

    return (
        <React.Fragment>
            <section className={classes.Background}>
                <section className={classes.SectionTop}>
                    <div className={classes.Top_Wrapper}>
                        <div className={classes.Header_Separtor}>
                            <svg className={classes.SVGseparator} viewBox="0 0 1000 200" preserveAspectRatio="none">
                                <path className={classes.Separator} d="M0,0 L0,100 L500,200 L1000,100 L1000,0z" />
                            </svg>
                        </div>
                        <div className={classes.AnimatedLogoWrapper}>
                            <img className={classes.AnimatedLogo} src="/images/general/ArtibullesLogoAnimated.gif" alt="AnimatedLogo" />
                        </div>
                        <div className={classes.Titles_Wrapper}>
                            <h1 className={classes.HomePageCompanyH1}> {HomePageText[FinalLanguageTag].TopTitle}</h1>
                            <h2 className={classes.SubTitle}>{HomePageText[FinalLanguageTag].SubTitle}</h2>
                            <div className={classes.Animated_ButtonHomeVision_Wrapper}>
                                <div className={ClassAnimated_ButtonHomeVision}>
                                    <div className={ClassAnimated_ButtonHomeVision_Vision}>
                                        <div className="HomePage_VisionButton" onClick={handleVisionClick}>
                                            {HomePageText[FinalLanguageTag].VisionButton_Vision}
                                        </div>
                                    </div>
                                    <div className={classes.Animated_ButtonHomeVision_Home}>
                                        <div className="HomePage_VisionButton" onClick={handleVisionClick}>
                                            {HomePageText[FinalLanguageTag].VisionButton_Home}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={classes.Section_Main} style={{color: "white"}}>
                    <div className={classes.MainSectionAnimated_Wrapper} style={{width: "100%", height: `${MainSectionHeight}px`}}>
                        <div className={ClassMainSectionAnimated}>
                            <div className={ClassHomePage_Animated_Main} style={{height: MainSectionHeight + "px"}}>
                                <div className={classes.MainWrapper} style={{height: MainSectionHeight + "px"}}>
                                    <div className={classes.MainWrapper_FlexLeft}>
                                        <div className={classes.BoxBusinessDesc_Wrapper}>
                                            <Link to="/es" className={ClassES} onMouseEnter={() => HandleBoxDescMouseEnter("ES")} onMouseLeave={() => HandleBoxDescMouseLeave("ES")}>
                                                <h2 className={clsx(classes.BoxBusiness_Title, classes.BoxBusinessTitle_ES)}>{HomePageText[FinalLanguageTag].ES_Box_Title}</h2>
                                                <ul className={classes.BoxBusiness_UL}>
                                                    {HomePageText[FinalLanguageTag].ES_Box_Description.map((elem) => {
                                                        return <li key={uuidv4()}>{elem}</li>;
                                                    })}
                                                </ul>
                                            </Link>
                                        </div>
                                        <div className={classes.BoxBusinessDesc_Wrapper}>
                                            <Link to="/dc" className={ClassDC} onMouseEnter={() => HandleBoxDescMouseEnter("DC")} onMouseLeave={() => HandleBoxDescMouseLeave("DC")}>
                                                <h2 className={clsx(classes.BoxBusiness_Title, classes.BoxBusinessTitle_DC)}>{HomePageText[FinalLanguageTag].DC_Box_Title}</h2>
                                                <ul className={classes.BoxBusiness_UL}>
                                                    {HomePageText[FinalLanguageTag].DC_Box_Description.map((elem) => {
                                                        return <li key={uuidv4()}>{elem}</li>;
                                                    })}
                                                </ul>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className={classes.MainWrapper_FlexCenter}>
                                        <svg width={SVGWidth + "px"} height={SVGHeight + "px"} viewBox="0 0 464 731">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g transform="translate(-488.000000, -130.000000)">
                                                    <g id="ArTiBulles" transform="translate(525.000000, 148.000000)" fill="rgb(171, 171, 171)" fillRule="nonzero">
                                                        <path
                                                            d="M299.362422,194.908793 C236.54566,152.928226 195.185564,81.3037879 195.185564,0 L194.814436,0 C194.814436,81.3037879 153.45434,152.928226 90.6513234,194.922561 C36.1643111,229.52314 0,290.449349 0,359.857036 C0,467.692983 87.3249216,555.110045 195.006873,554.999896 C302.688824,555.096276 390,467.692983 390,359.857036 C390,290.449349 353.835689,229.509372 299.362422,194.908793 Z M194.7732,542.511744 C93.2904522,539.537719 11.9585521,459.004975 11.9585521,360.077335 C11.9585521,295.158222 46.9682445,238.169843 99.7233285,205.799783 C146.938991,175.316026 181.618793,128.089609 194.7732,72.9049207 C207.927607,128.089609 242.607408,175.302257 289.823071,205.799783 C342.56441,238.156074 377.587848,295.158222 377.587848,360.077335 C377.587848,459.004975 296.255948,539.537719 194.7732,542.511744 Z"
                                                            id="Shape"
                                                        />
                                                        <path
                                                            fill="black"
                                                            fillOpacity="0.8"
                                                            d="M194.7732,542.511744 C93.2904522,539.537719 11.9585521,459.004975 11.9585521,360.077335 C11.9585521,295.158222 46.9682445,238.169843 99.7233285,205.799783 C146.938991,175.316026 181.618793,128.089609 194.7732,72.9049207 C207.927607,128.089609 242.607408,175.302257 289.823071,205.799783 C342.56441,238.156074 377.587848,295.158222 377.587848,360.077335 C377.587848,459.004975 296.255948,539.537719 194.7732,542.511744 Z"
                                                            id="Path"
                                                        />
                                                    </g>

                                                    <g
                                                        className={ClassPM}
                                                        onMouseEnter={() => HandleBoxDescMouseEnter("PM")}
                                                        onMouseLeave={() => HandleBoxDescMouseLeave("PM")}
                                                        onClick={HandlePMClick}
                                                        transform="translate(577.000000, 419.000000)"
                                                    >
                                                        <rect className={classes.BoxBusinessDesc_Content_PM} x="3.5" y="3.5" width="277" height="146" rx="10" />

                                                        <circle id="Oval" className={classes.CircleGroup_Circle_PM} cx={CircleDimPM.cx} cy={CircleDimPM.cy} r={CircleDimPM.r} />
                                                        <image x={CircleImgPM.x} y={CircleImgPM.y} width={CircleImgPM.dim} height={CircleImgPM.dim} xlinkHref={ImageSrc_PM} />

                                                        <text className={classes.BoxBusinessDesc_Content_PM_Text} x="140.25" y="131" textAnchor="middle">
                                                            Project Management
                                                        </text>
                                                    </g>
                                                    <g className={ClassCircleGroupES} onMouseEnter={() => HandleBoxDescMouseEnter("ES")} onMouseLeave={() => HandleBoxDescMouseLeave("ES")}>
                                                        <polyline
                                                            className={classes.CircleGroup_Line_ES}
                                                            transform="translate(555.000000, 319.500000) scale(-1, 1) translate(-555.000000, -319.500000) "
                                                            points="488 370 567 295 622 295"
                                                        />
                                                        <circle className={classes.CircleGroup_Circle_ES} cx={CircleDimES.cx} cy={CircleDimES.cy} r={CircleDimES.r} />
                                                        <image x={CircleImgES.x} y={CircleImgES.y} width={CircleImgES.dim} height={CircleImgES.dim} xlinkHref={ImageSrc_ES} />
                                                    </g>
                                                    <g className={ClassCircleGroupDC} onMouseEnter={() => HandleBoxDescMouseEnter("DC")} onMouseLeave={() => HandleBoxDescMouseLeave("DC")}>
                                                        <polyline
                                                            className={classes.CircleGroup_Line_DC}
                                                            transform="translate(543.000000, 666.000000) scale(-1, 1) translate(-543.000000, -666.000000) "
                                                            points="488 600 554 676 598 676"
                                                        />
                                                        <circle className={classes.CircleGroup_Circle_DC} cx={CircleDimDC.cx} cy={CircleDimDC.cy} r={CircleDimDC.r} />
                                                        <image x={CircleImgDC.x} y={CircleImgDC.y} width={CircleImgDC.dim} height={CircleImgDC.dim} xlinkHref={ImageSrc_DC} />
                                                    </g>
                                                    <g className={ClassCircleGroupCIS} onMouseEnter={() => HandleBoxDescMouseEnter("CIS")} onMouseLeave={() => HandleBoxDescMouseLeave("CIS")}>
                                                        <polyline className={classes.CircleGroup_Line_CIS} points="842 600 908 676 952 676" />
                                                        <circle className={classes.CircleGroup_Circle_CIS} cx={CircleDimCIS.cx} cy={CircleDimCIS.cy} r={CircleDimCIS.r} />

                                                        <image x={CircleImgCIS.x} y={CircleImgCIS.y} width={CircleImgCIS.dim} height={CircleImgCIS.dim} xlinkHref={ImageSrc_CIS} />
                                                    </g>
                                                    <g className={ClassCircleGroupISO} onMouseEnter={() => HandleBoxDescMouseEnter("ISO")} onMouseLeave={() => HandleBoxDescMouseLeave("ISO")}>
                                                        <polyline className={classes.CircleGroup_Line_ISO} points="818 370 897 295 952 295" />
                                                        <circle className={classes.CircleGroup_Circle_ISO} cx={CircleDimISO.cx} cy={CircleDimISO.cy} r={CircleDimISO.r} />

                                                        <image x={CircleImgISO.x} y={CircleImgISO.y} width={CircleImgISO.dim} height={CircleImgISO.dim} xlinkHref={ImageSrc_ISO} />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className={classes.MainWrapper_FlexRight}>
                                        <div className={classes.BoxBusinessDesc_Wrapper}>
                                            <Link to="/iso" className={ClassISO} onMouseEnter={() => HandleBoxDescMouseEnter("ISO")} onMouseLeave={() => HandleBoxDescMouseLeave("ISO")}>
                                                <h2 className={clsx(classes.BoxBusiness_Title, classes.BoxBusinessTitle_ISO)}>{HomePageText[FinalLanguageTag].ISO_Box_Title}</h2>
                                                <ul className={classes.BoxBusiness_UL}>
                                                    {HomePageText[FinalLanguageTag].ISO_Box_Description.map((elem) => {
                                                        return <li key={uuidv4()}>{elem}</li>;
                                                    })}
                                                </ul>
                                            </Link>
                                        </div>
                                        <div className={classes.BoxBusinessDesc_Wrapper}>
                                            <Link to="/cis" className={ClassCIS} onMouseEnter={() => HandleBoxDescMouseEnter("CIS")} onMouseLeave={() => HandleBoxDescMouseLeave("CIS")}>
                                                <h2 className={clsx(classes.BoxBusiness_Title, classes.BoxBusinessTitle_CIS)}>{HomePageText[FinalLanguageTag].CIS_Box_Title}</h2>
                                                <ul className={classes.BoxBusiness_UL}>
                                                    {HomePageText[FinalLanguageTag].CIS_Box_Description.map((elem) => {
                                                        return <li key={uuidv4()}>{elem}</li>;
                                                    })}
                                                </ul>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.HomePage_Animated_Vision} style={{height: `${MainSectionHeight}px`}}>
                                <HomePageVision />
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </React.Fragment>
    );
};

HomePage.defaultProps = {
    SectionTopHeight: 200,
    AnimatedLogoPaddingTop: 20,
    MainFlexSidesMaxWidth: 500,
    ArtibullesColors: {
        Orange: "#FF6103",
        Pink: "#ED2D92",
        Green: "#6DBE45",
        Blue: "#4997D2",
        Yellow: "#FCB415",
    },
};

export default HomePage;
