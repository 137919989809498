import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import Page from "@artibulles-cis/react/Page";

import BottomNavBar from "../../app/layout/BottomNavBar/BottomNavBar";
import {TopSection} from "../TextContent/ESPageText";
const PagesBoxDescription = React.lazy(() => import("./PagesBoxDescription/PagesBoxDescription"));

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Summary: {
        position: "relative",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        touchAction: "none",
        overflowX: "hidden",
    },
}));
const EngineeringSolution = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
	const classes = styles({...props, theme});
	const {trackPageView} = useMatomo();
    const Language = "English";
    var FinalLanguageTag;
    if (Language === "English") {
        FinalLanguageTag = "English";
	} //Matomo Tracking
    React.useEffect(() => {
        trackPageView({
            documentTitle: "ESPage_Desktop",
        });
    }, [trackPageView]);
	
	var Description = TopSection[FinalLanguageTag].Description
	var CombinedBoxContent = TopSection[FinalLanguageTag].CombinedBoxContent

    return (
        <Page WithPerfectScrollBar={true}>
            <section className={classes.Summary}>
                <section className="SummaryBackground_ES">
                    <div className="Summary_Top_DivWrapper">
                        <React.Suspense fallback={<div>Loading...</div>}>
                            <PagesBoxDescription
                                TitleImgPath="/images/icons/Artibulles_logo_ES_black_fontexp.svg"
                                Title="Engineering Solutions"
                                // TitleRightContent={<div>Hello There</div>}
                                RefBusiness="ES"
                                SectionLeftImagesPath={{
                                    top: "/images/general/Artibulles_ES_icon1.png",
                                    right: "/images/general/Artibulles_ES_icon2.png",
                                    bottom: "/images/general/Artibulles_ES_icon3.png",
                                    left: "/images/general/Artibulles_ES_icon4.png",
                                }}
                                DescriptionContent={Description}
                                CombinedBoxContent={CombinedBoxContent}
                            />
                        </React.Suspense>
                    </div>
                </section>
            </section>
            <BottomNavBar />
        </Page>
    );
};

export default EngineeringSolution;
