import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import clsx from "clsx";
import {v4 as uuidv4} from "uuid";
import {HomePageTextVision} from "../TextContent/HomePageText";
import PerfectScrollBarWrapper from "@artibulles-cis/react/PerfectScrollBarWrapper";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    HomePageVision: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        margin: "0 auto",
        // height : "100%",
        padding: "10px",
        boxSizing: "border-box",
    },
    Title_Vision: {
        margin: "10px",
        textAlign: "center",
        fontSize: "2rem",
    },
    Vision_Section_Wrapper: {
        maxWidth: "1500px",
        display: "flex",
        borderRadius: "8px",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        padding: "0px 0px 20px 0px",
        backgroundColor: "rgba(0, 0, 0, 0.851)",
        marginTop: "40px",
        boxSizing: "border-box",
    },
    Vision_Section_Wrapper_Title: {
        width: "100%",
        minHeight: "40px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.192)",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        marginBottom: "20px",
        padding: "0px 20px 0px 20px",
        boxSizing: "border-box",
    },

    HomePage_Vision_Intro_Block: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        maxWidth: "1450px",
        padding: "0px 20px 0px 20px",
    },
    HomePage_Vision_Intro_Block_Text_Title: {
        margin: "0px 0px 10px 0px",
    },
    HomePage_Vision_Intro_Block_Icon: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",

        "& img": {
            maxWidth: "600px",
            height: "auto",
        },
    },

    HomePage_Vision_Intro_Block_Text: {
        flex: "1 1 auto",
        paddingLeft: "30px",
        textAlign: "justify",
    },
    Vision_Section_TitleIntro: {
        textAlign: "center",
        maxWidth: "1200px",
        padding: "0px 10px 0px 10px",
        margin: "5px 0px 15px 0px",
    },

    HomePage_Vision_Categories_block: {
        maxWidth: "1400px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        fontSize: "1.1rem",
        padding: "0px 20px 0px 20px",
    },

    HomePage_Vision_Categories_Text: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexWrap: "nowrap",
        paddingRight: "30px",
        textAlign: "justify",
        padding: "0px 20px 0px 20px",
        "& p": {
            lineHeight: "30px",
        },
    },

    Vision_Section_Blocks_FlexWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
        maxWidth: "1400px",
    },
    Vision_Block: {
        flex: "0 0 400px",
        display: "flex",
        flexDirection: "column",
        margin: "8px 8px 8px 8px",
        color: "black",
        boxSizing: "border-box",
        padding: "0",
        "& h3": {
            textAlign: "center",
            color: "white",
        },
    },
    Vision_Block_Business: {
        height: "350px",
    },
    Vision_Block_Values: {
        height: "250px",
    },
    Vision_Block_Title_Wrapper: {
        display: "flex",
        flex: "1 0 auto",
        alignItems: "center",
        padding: "10px 10px 10px 10px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },

    Vision_Block_Title_Wrapper_Orange: {
        backgroundColor: "#FF6103",
    },
    Vision_Block_Title_Wrapper_Blue: {
        backgroundColor: "#4997D2",
    },
    Vision_Block_Title_Wrapper_Green: {
        backgroundColor: "#6DBE45",
    },
    Vision_Block_Title_Wrapper_Pink: {
        backgroundColor: "#ED2D92",
    },
    Vision_Block_Title_Wrapper_Yellow: {
        backgroundColor: "#FCB415",
    },

    Vision_Block_Title_Icon: {
        flex: "0 0 70px",
        height: "70px",
    },
    Vision_Block_Title: {
        margin: "0",
        padding: "0px 15px 0px 15px",
    },
    Vision_Block_Body: {
        flex: "1 1 auto",
        backgroundColor: "white",
        height: "100%",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        border: "4px solid white",
        padding: "0px 8px 0px 8px",
	},
	"@media only screen and (max-width: 1500px)": {
		Vision_Block: {
			flex: "0 0 350px",
			display: "flex",
			flexDirection: "column",
			margin: "8px 8px 8px 8px",
			color: "black",
			boxSizing: "border-box",
			padding: "0",
			"& h3": {
				textAlign: "center",
				color: "white",
			},
		},
		Vision_Block_Business: {
			height: "290px",
		},
		
		Vision_Block_Title_Wrapper: {
			display: "flex",
			flex: "1 0 auto",
			alignItems: "center",
			padding: "5px 10px 5px 10px",
			borderTopLeftRadius: "8px",
			borderTopRightRadius: "8px",
		},
		Vision_Block_Title_Icon: {
			flex: "0 0 50px",
			height: "50px",
		},
		Vision_Block_Values: {
			height: "200px",
		},
	}
}));

const HomePageVision = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();

    const Language = "English";
    var FinalLanguageTag;
    if (Language === "English") {
        FinalLanguageTag = "English";
    }

    //eslint-disable-next-line
    const classes = styles({...props, theme});
	const {trackPageView} = useMatomo();

	 //Matomo Tracking
	 React.useEffect(() => {
        trackPageView({
            documentTitle: "HomePage_Vision_Desktop",
        });
    }, [trackPageView]);
    return (
        <PerfectScrollBarWrapper Active={true} ScrollY={true}>
            <div className={classes.HomePageVision}>
                <h1 className={classes.Title_Vision}>{HomePageTextVision[FinalLanguageTag].Title}</h1>
                <div className={classes.Vision_Section_Wrapper}>
                    <div className={classes.Vision_Section_Wrapper_Title}>
                        <h2>{HomePageTextVision[FinalLanguageTag].Goal}</h2>
                    </div>
                    <div className={classes.HomePage_Vision_Intro_Block}>
                        <div className={classes.HomePage_Vision_Intro_Block_Icon}>
                            <img src="/images/homepage/Vision.png" alt="EvolutionSpeed" />
                        </div>
                        <div className={classes.HomePage_Vision_Intro_Block_Text}>
                            <h3 className={classes.HomePage_Vision_Intro_Block_Text_Title}>{HomePageTextVision[FinalLanguageTag].IntroSection.Title1}</h3>
                            <h3>{HomePageTextVision[FinalLanguageTag].IntroSection.Title2}</h3>
                            <p>{HomePageTextVision[FinalLanguageTag].IntroSection.P1}</p>
                            {HomePageTextVision[FinalLanguageTag].IntroSection.P2}
                            <ul>
                                <li>{HomePageTextVision[FinalLanguageTag].IntroSection.Bullets[0]}</li>
                                <li>{HomePageTextVision[FinalLanguageTag].IntroSection.Bullets[1]}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className={classes.Vision_Section_Wrapper}>
                    <div className={classes.Vision_Section_Wrapper_Title}>
                        <h2>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.Title}</h2>
                    </div>
                    <div className={classes.HomePage_Vision_Categories_block}>
                        <div className={classes.HomePage_Vision_Categories_Text}>
                            <p>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.P1}</p>
                            <p className="HomePageVisionCategories_IncreasedLeftPadding">
                                <img className="HomePageVisionLogoInsideText" src="/images/icons/Artibulles_Icon_WithText_ES.svg" alt="SipmleEngineering" />
                            </p>
                            <p className="HomePageVisionCategories_IncreasedLeftPadding">
                                <img className="HomePageVisionLogoInsideText" src="/images/icons/Artibulles_Icon_WithText_ISO.svg" alt="SipmleEngineering" />
                            </p>

                            <p style={{paddingRight: "60px"}}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.P2}</p>
                            <p className="HomePageVisionCategories_IncreasedLeftPadding">
                                <img className="HomePageVisionLogoInsideText" src="/images/icons/Artibulles_Icon_WithText_DC.svg" alt="SipmleEngineering" />
                            </p>
                            <p className="HomePageVisionCategories_IncreasedLeftPadding">
                                <img className="HomePageVisionLogoInsideText" src="/images/icons/Artibulles_Icon_WithText_CIS.svg" alt="SipmleEngineering" />
                            </p>
                            <p className="HomePageVisionCategories_IncreasedLeftPadding">
                                <img className="HomePageVisionLogoInsideText" src="/images/icons/Artibulles_Icon_WithText_PM.svg" alt="SipmleEngineering" />
                            </p>
                        </div>
                        <div className="HomePage_Vision_Categories_Picture">
                            <img className="HomePage_Vision_Categories_Svg" src="/images/homepage/Artibulles_vision_world.png" alt="VisionCategory" />
                        </div>
                    </div>
                    <p className="HomePage_Vision_Categories_Columns_Message">{HomePageTextVision[FinalLanguageTag].SolutionsStructure.P3}</p>
                    <h3 className={classes.Vision_Section_TitleIntro}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.P4}</h3>

                    <h3 className={classes.Vision_Section_TitleIntro}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.P5}</h3>
                    <div className={classes.Vision_Section_Blocks_FlexWrapper} style={{marginTop: "55px"}}>
                        <div className={clsx(classes.Vision_Block, classes.Vision_Block_Business)}>
                            <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Artibulles_logo_ES_black_fontexp.svg" alt="SipmleEngineering" />
                                <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.ES_Box_Title}</h3>
                            </div>
                            <div className={classes.Vision_Block_Body}>
                                <ul>
                                    {HomePageTextVision[FinalLanguageTag].SolutionsStructure.ES_Box_Description.map((elem) => {
                                        return <li key={uuidv4()}>{elem}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className={clsx(classes.Vision_Block, classes.Vision_Block_Business)}>
                            <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Green)}>
                                <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Artibulles_logo_ISO_black_fontexp.svg" alt="SipmleEngineering" />
                                <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.ISO_Box_Title}</h3>
                            </div>

                            <div className={classes.Vision_Block_Body}>
                                <ul>
                                    {HomePageTextVision[FinalLanguageTag].SolutionsStructure.ISO_Box_Description.map((elem) => {
                                        return <li key={uuidv4()}>{elem}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className={clsx(classes.Vision_Block, classes.Vision_Block_Business)}>
                            <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Pink)}>
                                <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Artibulles_logo_DC_black_fontexp.svg" alt="SipmleEngineering" />
                                <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.DC_Box_Title}</h3>
                            </div>

                            <div className={classes.Vision_Block_Body}>
                                <ul>
                                    {HomePageTextVision[FinalLanguageTag].SolutionsStructure.DC_Box_Description.map((elem) => {
                                        return <li key={uuidv4()}>{elem}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className={clsx(classes.Vision_Block, classes.Vision_Block_Business)}>
                            <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Blue)}>
                                <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Artibulles_logo_CIS_black_fontexp.svg" alt="SipmleEngineering" />
                                <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.CIS_Box_Title}</h3>
                            </div>

                            <div className={classes.Vision_Block_Body}>
                                <ul>
                                    {HomePageTextVision[FinalLanguageTag].SolutionsStructure.CIS_Box_Description.map((elem) => {
                                        return <li key={uuidv4()}>{elem}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className={clsx(classes.Vision_Block, classes.Vision_Block_Business)}>
                            <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Yellow)}>
                                <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Artibulles_logo_PM_black_fontexp.svg" alt="SipmleEngineering" />
                                <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].SolutionsStructure.PM_Box_Title}</h3>
                            </div>

                            <div className={classes.Vision_Block_Body}>
                                <ul>
                                    {HomePageTextVision[FinalLanguageTag].SolutionsStructure.PM_Box_Description.map((elem) => {
                                        return <li key={uuidv4()}>{elem}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.Vision_Section_Wrapper}>
                    <div className={classes.Vision_Section_Wrapper_Title}>
                        <h2>{HomePageTextVision[FinalLanguageTag].CoreValues.Title}</h2>
                    </div>
                    <h3 className={classes.Vision_Section_TitleIntro}>
					{HomePageTextVision[FinalLanguageTag].CoreValues.P1}
                    </h3>
                    <div className={classes.Vision_Section_Blocks_FlexWrapper}>
                        <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                            <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Values_expertise_box.svg" alt="Expertise" />
                                <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box1_Title}</h3>
                            </div>

                            <div className={classes.Vision_Block_Body}>
                                <ul>
                                    {HomePageTextVision[FinalLanguageTag].CoreValues.Box1_Description.map((elem) => {
                                        return <li key={uuidv4()}> {elem}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                            <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Values_innovative_box.svg" alt="Innovative" />
                                <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box2_Title}</h3>
                            </div>

                            <div className={classes.Vision_Block_Body}>
                                <ul>
                                    {HomePageTextVision[FinalLanguageTag].CoreValues.Box2_Description.map((elem) => {
                                        return <li key={uuidv4()}>{elem}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                            <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Values_results_box.svg" alt="Business" />
                                <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box3_Title}</h3>
                            </div>

                            <div className={classes.Vision_Block_Body}>
                                <ul>
                                    {HomePageTextVision[FinalLanguageTag].CoreValues.Box3_Description.map((elem) => {
                                        return <li key={uuidv4()}>{elem}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                            <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Values_passion_box.svg" alt="Passion" />
                                <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box4_Title}</h3>
                            </div>

                            <div className={classes.Vision_Block_Body}>
                                <ul>
                                    {HomePageTextVision[FinalLanguageTag].CoreValues.Box4_Description.map((elem) => {
                                        return <li key={uuidv4()}> {elem}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                            <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Values_knowledge_box.svg" alt="Knowledge" />
                                <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box5_Title}</h3>
                            </div>

                            <div className={classes.Vision_Block_Body}>
                                <ul>
                                    {HomePageTextVision[FinalLanguageTag].CoreValues.Box5_Description.map((elem) => {
                                        return <li key={uuidv4()}>{elem}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className={clsx(classes.Vision_Block, classes.Vision_Block_Values)}>
                            <div className={clsx(classes.Vision_Block_Title_Wrapper, classes.Vision_Block_Title_Wrapper_Orange)}>
                                <img className={classes.Vision_Block_Title_Icon} src="/images/icons/Values_ethical_box.svg" alt="Ethical" />
                                <h3 className={classes.Vision_Block_Title}>{HomePageTextVision[FinalLanguageTag].CoreValues.Box6_Title}</h3>
                            </div>

                            <div className={classes.Vision_Block_Body}>
                                <ul>
                                    {HomePageTextVision[FinalLanguageTag].CoreValues.Box6_Description.map((elem) => {
                                        return <li key={uuidv4()}>{elem}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PerfectScrollBarWrapper>
    );
};

export default HomePageVision;
