import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import Page from "@artibulles-cis/react/Page";
import PagesBoxDescription from "./PagesBoxDescription/PagesBoxDescription";
import BottomNavBar from "../../app/layout/BottomNavBar/BottomNavBar";
import {TopSection} from "../TextContent/CISPageText";
import Button from "@artibulles-cis/react/Button";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Summary: {
        position: "relative",

        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        touchAction: "none",
        overflowX: "hidden",
    },
    RightTitle: {
        display: "flex",
        alignItems: "center",
    },
    RightTitle_Icon: {
        width: "30px",
        height: "30px",
        margin: "0px 10px 0px 0px",
    },
    ClassExtendButton_RightTitle: {
        padding: "0px",
        margin: "0px",
    },
}));

const EngineeringSolution = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
	const classes = styles({...props, theme});
	const {trackPageView,trackEvent} = useMatomo();
	const Language = "English";
    var FinalLanguageTag;
    if (Language === "English") {
        FinalLanguageTag = "English";
    }

    var Description = TopSection[FinalLanguageTag].Description;
    var CombinedBoxContent = TopSection[FinalLanguageTag].CombinedBoxContent;
    const OpenExternalLink = () => {
		//Matomo Tracking
        trackEvent({category: "CIS_Page_Desktop", action: `Visit CIS Website Button`});
		window.open("https://www.artibulles-cis.artibulles.com", "_blank");
		
	};
	
	 //Matomo Tracking
	 React.useEffect(() => {
        trackPageView({
            documentTitle: "CISPage_Desktop",
        });
	}, [trackPageView]);
	
    const TitleRightComp = (
        <div className={classes.RightTitle}>
            <Button Width="350px" style={{margin: "0px"}} ClassExtendButton={classes.ClassExtendButton_RightTitle} onClick={OpenExternalLink}>
                <img className={classes.RightTitle_Icon} src="/images/icons/earth-globe.svg" alt="link" />
                <p style={{margin: "0px"}}>{TopSection[FinalLanguageTag].VisitSiteButton}</p>
            </Button>
        </div>
    );
    return (
        <Page WithPerfectScrollBar={true}>
            <section className={classes.Summary}>
                <section className="SummaryBackground_CIS">
                    <div className="Summary_Top_DivWrapper">
                        <PagesBoxDescription
                            TitleImgPath="/images/icons/Artibulles_logo_CIS_black_fontexp.svg"
                            Title="Custom IT Solutions"
                            TitleRightContent={TitleRightComp}
                            RefBusiness="CIS"
                            SectionLeftImagesPath={{
                                top: "/images/general/Artibulles_CIS_icon1.png",
                                right: "/images/general/Artibulles_CIS_icon2.png",
                                bottom: "/images/general/Artibulles_CIS_icon3.png",
                                left: "/images/general/Artibulles_CIS_icon4.png",
                            }}
                            DescriptionContent={Description}
                            CombinedBoxContent={CombinedBoxContent}
                        />
                    </div>
                </section>
            </section>
            <BottomNavBar />
        </Page>
    );
};

export default EngineeringSolution;
