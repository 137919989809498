/*
This is the Default Theme For the ArtiwebReact Library
*/

/*
 ****************** Colors Defintion ********************
 */
const Colors = {
	DarkGrey: "rgba(115, 115, 115, 1)",
	MediumGrey: "#2c2c2c;",
	LightGrey: "grey",
	TextLightGrey: "#b9b9b9"
  };
  /*
  ****************** Colors Defintion ********************
  */
  
  /*
  ****************** TopBar ********************
  */
  
  const TopBarDefault = {
	Height: "60px",
	Background: Colors.MediumGrey,
	Links: {
		Background: "rgba(35, 35, 35, 1)",
		BackgroundHover: "rgba(150, 150, 150, 1)",
		BackgroundActive: "rgba(130, 130, 130, 1)",
		TextColor: "white",
		TextHover: "white",
		TextColorActive: "white",
		FontSize: "16px",
		HoverTransitionDuration: "600ms",
		HoverTransitionEasing: ""
	},
	Indicator: {
		ActiveBorder: "2px solid rgb(255, 68, 0)", //Don't add transparency due to the Sliding Effect
		InactiveBorder: `2px solid rgba(35, 35, 35, 1)`, //Don't add transparency due to the Sliding Effect
		HoverBorder: `2px solid rgba(130, 130, 130, 1)` //The Hover color of the Indicator - Should be the Hover Background
	},
	SubLinks: {
		Background: "rgba(79, 79, 79, 1)",
		TextColor: "white",
		BackgroundHover: "#FF6103",
		TextColorHover: "white",
		FontWeight: "400",
		FontSize: "14px",
		Height: "35px",
		Border: "0.5px solid rgba(255, 255, 255, 0.2)"
	}
  };
  
  /*
  ****************** TopBar ********************
  */
  /*
  ****************** BottomBar ********************
  */
  
  const BottomBarDefault = {
	Height: "60px",
	Background: Colors.MediumGrey,
	TextColor: "white",
	fontSize: "0.9em"
  };
  
  /*
  ****************** BottomBar ********************
  */
  
  /*
  ****************** SideBars ********************
  */
  
  const SideBarDefault = {
	Background: "#124871", //NavSideBar background
	BorderRight: "1px solid grey", //NavSideBar right border
	BorderTop: "1px solid white", //NavSideBar top border
	TransitionMinimizing: {
		EasingDuration: "300ms", //NavSideBar transition maxwidth/minwidth duration
		EasingFunction: "ease-in-out" //NavSideBar transition easing function
	},
	CommonLinkElement: {
		paddingTopForAllElement: "0px",
		paddingRightForAllElement: "20px", //Define a Consistent padding to the right of the SideBar for all SubElements
		paddingBottomForAllElement: "0px",
		paddingLeftForAllElement: "0px"
	},
  
	paddingRightForAllElement: "20px" //Define a Consistent padding to the right of the SideBar for all SubElements
  };
  //Values for the NavLink Element - This is used as the baseline for All other SubElements (NavAccordion and subNav)
  const SideBarDefaultNavLink = {
	Height: "40px", //Height of the Element
	Background: "none", //Background color
	BackgroundHover: "rgba(160, 160, 160, 1)", //Background When element is hovered
	BackgroundActive: "rgba(91, 91, 91, 1)", //Background when Active (see Router)
	TextColor: "white", //Basic text color
	TextColorHover: "white", //Hover Text Color (if you sepecify anything inside the Container it won't work)
	TextColorActive: "white", //Active Text Color (if you sepecify anything inside the Container it won't work)
	FontSize: "1rem", //Font Size (won't work if you pecify something inside the Nav Container)
	FontSizeActive: "1rem", //Font Size (won't work if you pecify something inside the Nav Container)
	FontWeigth: "400", //Font Size (won't work if you pecify something inside the Nav Container)
	HoverTransitionDuration: "600ms", //Hover transition duration
	HoverTransitionEasing: "", //Hover transition easing
	BorderBottom: "0px solid white", //separation border between elements
	BorderTop: "0px solid white", //separation border between elements
	paddingTop: "0px", //Top padding for the content
	paddingRight: SideBarDefault.CommonLinkElement.paddingRightForAllElement, //Defines the Right padding (For Scrollbar)
	paddingBottom: "0px", //Bottom padding for the content
	paddingLeft: "0px", //Defines the Left padding for the SideBarLink Element
	LinkIndicator: {
		ActiveBorder: "3px solid rgb(255, 68, 0)", //Active border Color and Width
		InactiveBorder: `3px solid #124871`, //Inactive border Color and Width needs to be the same width!!!
		IndicatorPadding: "0px 0px 0px 0px", //If required can be specified here but shouldn't be
		HoverBorder: `3px solid rgba(160, 160, 160, 1)` //The Hover color of the Indicator - Should be the Hover Background
	}
  };
  
  /*OLD SOON TO BE DELETED*/
  
  const SideBarAccordion = {
	ControllerNavLink: {
		Height: SideBarDefaultNavLink.Height,
		Background: SideBarDefaultNavLink.Background,
		BackgroundHover: SideBarDefaultNavLink.BackgroundHover,
		BackgroundActive: SideBarDefaultNavLink.BackgroundActive,
		TextColor: SideBarDefaultNavLink.TextColor,
		TextColorHover: SideBarDefaultNavLink.TextColorHover,
		TextColorActive: SideBarDefaultNavLink.TextColorActive,
		FontSize: SideBarDefaultNavLink.FontSize,
		HoverTransitionDuration: SideBarDefaultNavLink.HoverTransitionDuration,
		HoverTransitionEasing: SideBarDefaultNavLink.HoverTransitionEasing,
		BorderBottom: SideBarDefaultNavLink.BorderBottom,
		BorderTop: SideBarDefaultNavLink.BorderTop,
		paddingTop: SideBarDefaultNavLink.paddingTop,
		paddingRight: SideBarDefault.CommonLinkElement.paddingRightForAllElement,
		paddingBottom: SideBarDefaultNavLink.paddingBottom,
		paddingLeft: SideBarDefaultNavLink.paddingLeft
	},
	LinkIndicator: {
		ActiveBorder: SideBarDefaultNavLink.LinkIndicator.ActiveBorder,
		InactiveBorder: SideBarDefaultNavLink.LinkIndicator.InactiveBorder,
		IndicatorPadding: SideBarDefaultNavLink.LinkIndicator.IndicatorPadding,
		HoverBorder: SideBarDefaultNavLink.LinkIndicator.HoverBorder
	}
  };
  
  const SideBarAccordion_WithTree = {
	ControllerNavLink: {},
	LinkIndicator: {
		ActiveBorder: SideBarDefaultNavLink.LinkIndicator.ActiveBorder,
		InactiveBorder: SideBarDefaultNavLink.LinkIndicator.InactiveBorder,
		IndicatorPadding: SideBarDefaultNavLink.LinkIndicator.IndicatorPadding,
		HoverBorder: SideBarDefaultNavLink.LinkIndicator.HoverBorder
	}
  };
  
  var SideBarColors = {
	Background: "#124871",
	HoverLinks: "rgba(160, 160, 160, 1)",
	ActiveLinks: "rgba(105, 105, 105, 1)",
	IndicatorInactive: "transparent",
	IndicatorActive: "orangered",
	TextColor: Colors.TextLightGrey
  };
  var SideBarNavLinkElementsStyling = {
	//Standard Link Element with indicator option
	NavSideBarLink: {
		height: "40px", //Height of the SideBarLink Element
		TextColor: SideBarColors.TextColor, //Standard Text Color
		FontSize: "1em", //FontSize
		borderBottom: "0px solid grey", //Separation Border between Elements
		Hover: {
			backgroundHover: SideBarDefaultNavLink.BackgroundHover, //Hover Background Color
			TextHover: "white", //Hover Text Color
			TransitionDuration: "400ms", //Hover Transition Duration
			TransitionEsing: "ease-in-out" //Hover Transition Easing
		},
		backgroundActive: SideBarColors.ActiveLinks, //Active Background Color
		TextActiveColor: "white", //Active Text Color
  
		Indicator: {
			Width: "5px", //width of the Active Indicator
			InactiveColor: SideBarColors.IndicatorInactive, //Inactive Indicator Color
			ActiveColor: SideBarColors.IndicatorActive, //Active Indicator Color
			padding: "0px 0px 0px 0px" //Used to set the padding between the Indicator and the rest of the content
		}
	},
	//Accordion Link Controller Element with indicator option
	NavSideBarLinkAccordion: {
		height: "40px", //Height of the SideBarLink Element
		TextColor: SideBarColors.TextColor, //Standard Text Color
		FontSize: "1em", //FontSize
		borderBottom: "0px solid grey", //Separation Border between Elements
		Hover: {
			backgroundHover: SideBarColors.HoverLinks, //Hover Background Color
			TextHover: "white", //Hover Text Color
			TransitionDuration: "400ms", //Hover Transition Duration
			TransitionEsing: "ease-in-out" //Hover Transition Easing
		},
		backgroundActive: SideBarColors.ActiveLinks, //Active Background Color
		TextActiveColor: "white", //Active Text Color
		Indicator: {
			Width: "5px", //width of the Active Indicator
			InactiveColor: SideBarColors.IndicatorInactive, //Inactive Indicator Color
			ActiveColor: SideBarColors.IndicatorActive, //Active Indicator Color
			padding: "0px 0px 0px 0px" //Used to set the padding between the Indicator and the rest of the content
		},
		OpenCloseTransition: {
			TransitionDuration: "300ms", //Transition Duration for the Opening and Closing of the Accordion
			TransitionTimingFunction: "cubic-bezier(0, 1.13, 0.8, 1.21)" //Transition Easing for the Opening and Closing of the Accordion
		},
		Content: {
			Background: "transparent", //The background Color of the Collapsible Group
			MarginLeft: "25px", //Defines the left margin of the Accordion Content for the Sub-Elements
			BorderWidth: "0px", //Defines the Vertical Border Width When Using Tree View
			BorderColor: "transparent" //Defines the Vertical Border Color When Using Tree View
		}
	},
	//Accordion Link Controller Element  With tree view and with indicator option
	NavSideBarLinkAccordion_WithTree: {
		//Accordion Link Controller Element with indicator option
		height: "40px", //Height of the SideBarLink Element
		TextColor: SideBarColors.TextColor, //Standard Text Color
		FontSize: "1em", //FontSize
		borderBottom: "0px solid grey", //Separation Border between Elements
		Hover: {
			backgroundHover: SideBarColors.HoverLinks, //Hover Background Color
			TextHover: "white", //Hover Text Color
			TransitionDuration: "400ms", //Hover Transition Duration
			TransitionEsing: "ease-in-out" //Hover Transition Easing
		},
		backgroundActive: SideBarColors.ActiveLinks, //Active Background Color
		TextActiveColor: "white", //Active Text Color
		Indicator: {
			Width: "5px", //width of the Active Indicator
			InactiveColor: SideBarColors.IndicatorInactive, //Inactive Indicator Color
			ActiveColor: SideBarColors.IndicatorActive, //Active Indicator Color
			padding: "0px 0px 0px 0px" //Used to set the padding between the Indicator and the rest of the content
		},
		OpenCloseTransition: {
			TransitionDuration: "300ms", //Transition Duration for the Opening and Closing of the Accordion
			TransitionTimingFunction: "cubic-bezier(0, 1.13, 0.8, 1.21)" //Transition Easing for the Opening and Closing of the Accordion
		},
		Content: {
			Background: "transparent", //The background Color of the Collapsible Group
			MarginLeft: "25px", //Defines the left margin of the Accordion Content for the Sub-Elements
			BorderWidth: "2px", //Defines the Vertical Border Width When Using Tree View
			BorderColor: "white" //Defines the Vertical Border Color When Using Tree View
		}
	},
	//Accordion Navlink SubElement
	NavSideBarLinkAccordionSubLink: {
		//Accordion SubLinks Controller Element with indicator option and tree Options
		height: "40px", //Height of the SideBarLink Element
		TextColor: SideBarColors.TextColor, //Standard Text Color
		FontSize: "1em", //FontSize
		borderBottom: "0px solid grey", //Separation Border between Elements
		Hover: {
			backgroundHover: SideBarColors.HoverLinks, //Hover Background Color
			TextHover: "white", //Hover Text Color
			TransitionDuration: "400ms", //Hover Transition Duration
			TransitionEsing: "ease-in-out" //Hover Transition Easing
		},
		backgroundActive: SideBarColors.ActiveLinks, //Active Background Color
		TextActiveColor: "white", //Active Text Color
		Indicator: {
			Width: "5px", //width of the Active Indicator
			InactiveColor: SideBarColors.IndicatorInactive, //Inactive Indicator Color
			ActiveColor: SideBarColors.IndicatorActive, //Active Indicator Color
			padding: "0px 15px 0px 0px" //Used to set the padding between the Indicator and the rest of the content
		}
	},
	//Accordion SubLinks Controller Element with indicator option and tree Options
	NavSideBarLinkAccordionSubLink_WithTree: {
		height: "40px", //Height of the SideBarLink Element
		TextColor: SideBarColors.TextColor, //Standard Text Color
		FontSize: "1em", //FontSize
		borderBottom: "0px solid grey", //Separation Border between Elements
		Hover: {
			backgroundHover: SideBarColors.HoverLinks, //Hover Background Color
			TextHover: "white", //Hover Text Color
			TransitionDuration: "400ms", //Hover Transition Duration
			TransitionEsing: "ease-in-out" //Hover Transition Easing
		},
		backgroundActive: SideBarColors.ActiveLinks, //Active Background Color
		TextActiveColor: "white", //Active Text Color
		Indicator: {
			Width: "5px", //width of the Active Indicator
			InactiveColor: SideBarColors.IndicatorInactive, //Inactive Indicator Color
			ActiveColor: SideBarColors.IndicatorActive, //Active Indicator Color
			padding: "0px 15px 0px 0px" //Used to set the padding between the Indicator and the rest of the content Important for the TreeBar Display
		},
		TreeBar: {
			Border: "2px solid white", //Color and width of the horizontal Tree Bar
			Width: "15px" // Width of the Horizontal Tree Bar
		}
	}
  };
  
  /*OLD SOON TO BE DELETED*/
  /*
  ****************** SideBars ********************
  */
  
  const theme = {
	Colors: {},
	BasicBox: {
		maxWidth: "300px",
		backgroundColor: "grey",
		color: "black",
		fontSize: "20px",
		minHeight: "40px",
		padding: "5px 10px 5px 10px",
		borderRadius: "8px"
	},
	AppWrapper_MainContent: {},
	AppWrapper_FakeFlex: {
		background: "rgb(250, 250, 250)"
	},
	AccordionVertical: {
		ControllerWrapper: {
			cursor: "pointer",
			padding: "0px 0px 0px 0px",
			background: "transparent",
			color: "black",
			transition: [`background 500ms ease-in-out`],
			"&:hover": {
				background: "grey",
				transition: [`background 300ms ease-in-out`]
			}
		},
		ArrowSimple: {
			IconSize: "20",
			SVGStrokeWidth: "10",
			SVGStrokeColor: "white",
			SVGFillColor: "transparent",
			SVGStrokeHoverColor: "white",
			SVGFillHoverColor: "none",
			SVGTransitionDurationHover: "0",
			SVGTransitionDurationRotate: "150"
		},
		AccordionVerticalWrapper: {
			transition: [`height`],
			transitionDuration: "300ms",
			transitionTimingFunction: "cubic-bezier(0, 1.13, 0.8, 1.21)"
		},
		ControllerContent: {
			flex: "1 0 auto",
			display: "flex"
		},
		ControllerIconWrapper: {
			flex: "0 0 auto",
			display: "flex",
			padding: "0px 20px 0px 5px",
			alignItems: "center"
		},
		ControllerIconWrapper_WithPerfectScrollBar: {
			flex: "0 0 auto",
			display: "flex",
			padding: "0px 20px 0px 5px",
			alignItems: "center"
		},
  
		CollapsibleContent: {
			display: "block",
			width: "100%",
			position: "relative",
			boxSizing: "border-box",
			overflow: "hidden"
		}
	},
	NavHorizontal_Top: {
		zIndex: "100",
		background: "grey",
		height: "50px"
	},
  
	NavHorizontal_Bottom: {
		zIndex: "100",
		background: BottomBarDefault.Background,
		height: BottomBarDefault.Height,
		color: BottomBarDefault.TextColor,
		fontSize: BottomBarDefault.fontSize
	},
	NavHorizontal_Inner: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		transition: ["padding"],
		transitionDuration: "300ms",
		transitionTimingFunction: "ease-in-out"
	},
	NavTopBar: {
		display: "flex",
		height: "100%",
		flex: "1 1 auto",
		flexWrap: "nowrap",
		padding: "0px 0px 0px 0px",
		background: TopBarDefault.Background,
		alignItems: "center",
		zIndex: "50",
		width: "100%",
		boxSizing: "border-box"
	},
  
	NavTopBarLink: {
		display: "block",
		flex: "1 1 auto",
		fontSize: TopBarDefault.Links.FontSize,
		fontWeight: "400",
		padding: "0px 0px 0px 0px",
		margin: "0px 0px 0px 0px",
		textDecoration: "none",
		textTransform: "none",
		color: TopBarDefault.Links.TextColor,
		height: "100%",
		position: "relative",
		background: TopBarDefault.Links.Background,
		zIndex: 1,
		whiteSpace: "wrap",
		letterSpacing: "0em",
		wordSpacing: "2px",
		transition: `background ${TopBarDefault.Links.HoverTransitionDuration} ${TopBarDefault.Links.HoverTransitionEasing}`,
		lineHeight: "1.2",
		cursor: "pointer",
		"&:visited": {
			textDecoration: "none"
		},
		"&:hover": {
			background: TopBarDefault.Links.BackgroundHover, //Hover Background Color
			color: TopBarDefault.Links.TextHover //Hover Text Color
		}
	},
	NavTopBarLinkActive: {
		background: TopBarDefault.Links.BackgroundActive, //Hover Background Color
		color: TopBarDefault.Links.TextActive //Hover Text Color
	},
	NavTopBarLink_Indicator_Active: {
		borderTop: TopBarDefault.Indicator.ActiveBorder,
		boxSizing: "border-box",
		"&:hover": {
			borderTop: TopBarDefault.Indicator.ActiveBorder
		}
	},
	NavTopBarLink_Indicator_Inactive: {
		transition: `all ${TopBarDefault.Links.HoverTransitionDuration} ${TopBarDefault.Links.HoverTransitionEasing}`,
		borderTop: TopBarDefault.Indicator.InactiveBorder,
		boxSizing: "border-box",
		"&:hover": {
			borderTop: TopBarDefault.Indicator.HoverBorder
		}
	},
	NavTopBarLinkContent: {
		display: "flex",
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
		flex: "1 0 auto"
	},
	NavTopBarSubMenu: {
		display: "block",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		flex: "1 1 auto",
		fontSize: TopBarDefault.Links.FontSize,
		fontWeight: "400",
		padding: "0px 0px 0px 0px",
		margin: "0px 0px 0px 0px",
		textDecoration: "none",
		textTransform: "none",
		color: TopBarDefault.Links.TextColor,
		height: "100%",
		position: "relative",
		background: TopBarDefault.Links.Background,
		zIndex: "100",
		whiteSpace: "wrap",
		letterSpacing: "0em",
		wordSpacing: "2px",
		transition: `background-color ${TopBarDefault.Links.HoverTransitionDuration} ${TopBarDefault.Links.HoverTransitionEasing}`,
		lineHeight: "1.2",
		cursor: "pointer",
		"&:visited": {
			textDecoration: "none"
		},
		"&:hover $NavTopBarSubMenu_Controller": {
			background: TopBarDefault.Links.BackgroundHover, //Hover Background Color
			color: TopBarDefault.Links.TextHover //Hover Text Color
		},
		"&:hover $NavTopBarLink_Indicator_Inactive": {
			background: TopBarDefault.Links.BackgroundHover, //Hover Background Color
			color: TopBarDefault.Links.TextHover, //Hover Text Color
			borderTop: TopBarDefault.Indicator.HoverBorder
		}
	},
	NavTopBarSubMenuActive: {
		background: TopBarDefault.Links.BackgroundActive, //Hover Background Color
		color: TopBarDefault.Links.TextActive //Hover Text Color
	},
	NavTopBarSubMenu_Controller: {
		display: "flex",
		position: "relative",
		background: TopBarDefault.Links.Background,
		transition: `all ${TopBarDefault.Links.HoverTransitionDuration} ${TopBarDefault.Links.HoverTransitionEasing}`,
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
		flex: "1 0 auto",
		zIndex: "1"
	},
	NavTopBarSubMenu_Controller_Active: {
		background: TopBarDefault.Links.BackgroundActive, //Hover Background Color
		color: TopBarDefault.Links.TextActive //Hover Text Color
	},
	NavTopBarSubMenuContent_Show: {
		display: "block",
		// visibility: "visible",
		flex: "0 1 auto",
		background: TopBarDefault.SubLinks.Background,
		transform: "translateY(0px)",
		opacity: "1",
		padding: "0px 0px 0px 0px",
		margin: "0px 0px 0px 0px",
		textAlign: "center",
		border: "none",
		transition: "all 300ms ease-out",
		zIndex: "-1"
	},
	NavTopBarSubMenuContent_Hide: {
		display: "block",
		visibility: "hidden",
		flex: "0 1 auto",
		background: TopBarDefault.SubLinks.Background,
		transform: "translateY(-200px)",
		opacity: "1",
		textAlign: "center",
		border: "none",
		transition: "all 300ms ease-in",
		zIndex: "-1"
	},
	NavTopBarSubLink: {
		width: "100%",
		display: "flex",
		flex: "0 1 auto",
		alignItems: "center",
		justifyContent: "center",
		padding: "0px 0px 0px 0px",
		margin: "0px 0px 0px 0px",
		height: TopBarDefault.SubLinks.Height,
		color: TopBarDefault.SubLinks.TextColor, //TextColor
		boxSizing: "border-box",
		borderBottom: TopBarDefault.SubLinks.Border,
		transition: `background-color ${TopBarDefault.Links.HoverTransitionDuration} ${TopBarDefault.Links.HoverTransitionEasing}`,
		"&:visited": {
			textDecoration: "none"
		},
		textDecoration: "none",
		"&:hover": {
			background: TopBarDefault.SubLinks.BackgroundHover, //Hover Background Color
			color: TopBarDefault.SubLinks.TextHover //Hover Text Color
		}
	},
	NavTopBarSubLink_Content: {
		flex: "1 1 auto",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		color: "white",
		fontSize: TopBarDefault.SubLinks.FontSize,
		fontWeight: TopBarDefault.SubLinks.FontWeight,
		"&:visited": {
			textDecoration: "none"
		}
	},
  
	/* NavSideBar Components */
	NavSideBar: {
		SideBar: {
			background: SideBarDefault.Background,
			transition: ["width"],
			transitionDuration: SideBarDefault.TransitionMinimizing.EasingDuration,
			transitionTimingFunction: SideBarDefault.TransitionMinimizing.EasingFunction,
			borderRight: SideBarDefault.BorderRight,
			borderTop: SideBarDefault.BorderTop,
			boxSizing: "border-box"
		},
  
		SideBarContent: {
			width: "100%",
			height: "100%",
			position: "relative",
			color: "white"
		},
		SideBarContent_WithPerfectScrollbar: {
			width: "100%",
			height: "100%",
			position: "relative",
			color: "white",
			overflowY: "hidden",
			overflowX: "hidden"
		},
		SideBarContent_WithScrollBarX: {
			width: "100%",
			height: "100%",
			position: "relative",
			overflowY: "hidden",
			overflowX: "scroll",
			color: "white"
		},
		SideBarContent_WithScrollBarY: {
			width: "100%",
			height: "100%",
			position: "relative",
			overflowY: "scroll",
			overflowX: "hidden",
			color: "white"
		},
		SideBarContent_WithScrollBarXY: {
			width: "100%",
			height: "100%",
			position: "relative",
			overflowY: "scroll",
			overflowX: "scroll",
			color: "white"
		}
	},
	NavSideBarPanels: {
		display: "flex",
		height: "100%",
		position: "relative",
		width: "100%",
		flexDirection: "column"
	},
	NavSideBarPanel: {
		flex: "1 0 auto",
		display: "flex",
		flexDirection: "column",
		height: "100%",
		position: "relative",
		width: "100%"
	},
	NavSideBarPanel_FixedContent: {
		flex: "1 0 auto"
		//   display : "flex",
		// height: '100%',
	},
	NavSideBarPanel_PerfectScrollableContent: {
		// display : "flex",
		height: "100%",
		overflow: "hidden"
	},
	NavSideBarPanel_ScrollableContentX: {
		// display : "flex",
		height: "100%",
		overflowY: "hidden",
		overflowX: "scroll"
	},
	NavSideBarPanel_ScrollableContentY: {
		// display : "flex",
		height: "100%",
		overflowY: "auto",
		overflowX: "hidden"
	},
	NavSideBarPanel_ScrollableContentXY: {
		// display : "flex",
		height: "100%",
		overflowY: "scroll",
		overflowX: "scroll"
	},
	NavSideBarController: {
		Controller: {
			display: "flex",
			position: "relative",
			alignItems: "center",
			height: "60px",
			boxSizing: "border-box",
			borderTop: "0px solid grey",
			borderBottom: "1px solid white",
			borderLeft: "0px solid grey",
			borderRight: "0px solid grey"
		},
		ControllerWhitoutContent_NativeScrollBar: {
			display: "flex",
			boxSizing: "border-box",
			padding: "5px 10px 5px 0px"
		},
		ControllerWhitoutContent_NoScrollBar: {
			display: "flex",
			boxSizing: "border-box",
			padding: "5px 0px 5px 0px"
		},
		ControllerWhitoutContent_PerfectScrollBar: {
			display: "flex",
			boxSizing: "border-box",
			padding: "5px 0px 5px 0px"
		},
  
		ControllerIconWrapper: {
			cursor: "pointer",
			flex: "0 0 auto",
			justifyContent: "center",
			alignItems: "center",
			padding: "0px 0px 0px 2px",
			boxSizing: "border-box",
			position: "relative"
		},
		ExpandCollapseController: {
			cursor: "pointer",
			flex: "0 0 auto",
			justifyContent: "center",
			alignItems: "center",
			// padding: "0px 5px 0px 5px",
			boxSizing: "border-box",
			position: "relative"
		},
		CollapseExpandIcon: {
			// cursor: "pointer",
			// flex: "0 0 auto",
			// justifyContent: "center",
			// alignItems: "center",
			// padding: "0px 5px 0px 5px",
			// boxSizing: "border-box",
			// position: "relative"
		},
  
		ControllerIconStyle: {
			IconSize: "20px",
			SVGFillColor: "white",
			SVGStrokeColor: "none",
			SVGStrokeWidth: "none",
			SVGStrokeHoverColor: "none",
			SVGFillHoverColor: "white",
			Frame: "none",
			FrameStrokeWidth: "",
			FrameStrokeColor: "",
			FrameFillColor: "",
			FrameStrokeColorHover: "",
			FrameFillColorHover: "",
			InnerPadding: ""
		},
		CollapseExpandControllerIconStyle: {
			IconSize: "20px",
			SVGFillColor: "white",
			SVGStrokeColor: "none",
			SVGStrokeWidth: "none",
			SVGStrokeHoverColor: "none",
			SVGFillHoverColor: "white",
			Frame: "none",
			FrameStrokeWidth: "",
			FrameStrokeColor: "",
			FrameFillColor: "",
			FrameStrokeColorHover: "",
			FrameFillColorHover: "",
			InnerPadding: ""
		},
  
		ControllerContent: {
			flex: "1 0 0px",
			overflow: "hidden"
		},
		ControllerContent_Minimized: {
			flex: "1 0 60px",
			overflow: "hidden",
			transition: "all 200ms linear 800ms"
		}
	},
  
	NavSideBarLink: {
		display: "flex",
		textDecoration: "none",
		cursor: "pointer",
		background: SideBarDefaultNavLink.Background,
		color: SideBarDefaultNavLink.TextColor, //Standard Text Color
		fontSize: SideBarDefaultNavLink.FontSize, //FontSize
		fontWeight: SideBarDefaultNavLink.FontWeight, //FontWeight
		minHeight: SideBarDefaultNavLink.Height, //Height of the SideBarLink Element
		width: "100%",
		borderBottom: SideBarDefaultNavLink.BorderBottom, //Separation Border between Elements
		borderTop: SideBarDefaultNavLink.BorderTop, //Separation Border between Elements
		transition: "all",
		transitionDuration: SideBarDefaultNavLink.HoverTransitionDuration, //Hover Transition Duration
		transitionTimingFunction: SideBarDefaultNavLink.HoverTransitionEasing, //Hover Transition Easing
		"&:visited": {
			textDecoration: "none"
		},
		"&:hover": {
			background: SideBarDefaultNavLink.BackgroundHover, //Hover Background Color
			color: SideBarDefaultNavLink.TextColorHover //Hover Text Color
		},
		"&:hover $NavSideBarLink_ActiveIndicator_Inactive": {
			flex: "0 0 auto",
			boxSizing: "border-box",
			transition: "all",
			transitionDuration: SideBarDefaultNavLink.HoverTransitionDuration, //Hover Transition Duration
			transitionTimingFunction: SideBarDefaultNavLink.HoverTransitionEasing, //Hover Transition Easing
			borderLeft: SideBarDefaultNavLink.LinkIndicator.HoverBorder //Color and Width of The Indicator
			// padding: SideBarDefaultNavLink.LinkIndicator.HoverBorder //Used to set the padding between the Indicator and the rest of the content
		},
		NavSideBarLink_Active: {
			background: SideBarDefaultNavLink.BackgroundActive, //Hover Background Color,
			color: SideBarDefaultNavLink.TextColorActive //Active Text Color
		},
		ActiveIndicator_Inactive: {
			flex: "0 0 auto",
			boxSizing: "border-box",
			transition: "all",
			transitionDuration: SideBarDefaultNavLink.HoverTransitionDuration, //Hover Transition Duration
			transitionTimingFunction: SideBarDefaultNavLink.HoverTransitionEasing, //Hover Transition Easing
			borderLeft: SideBarDefaultNavLink.LinkIndicator.InactiveBorder, //Color and Width of The Indicator
			padding: SideBarDefaultNavLink.LinkIndicator.IndicatorPadding //Used to set the padding between the Indicator and the rest of the content
		},
		ActiveIndicator_Active: {
			flex: "0 0 auto",
			boxSizing: "border-box",
			borderLeft: SideBarDefaultNavLink.LinkIndicator.ActiveBorder, //Color and Width of The Indicator
			padding: SideBarDefaultNavLink.LinkIndicator.IndicatorPadding //Used to set the padding between the Indicator and the rest of the content
		},
		Content: {
			flex: "1 1 auto",
			display: "flex",
			alignItems: "center",
			position: "relative",
			padding: "0px 0px 0px 10px",
			paddingTop: SideBarDefaultNavLink.paddingTop,
			paddingRight: SideBarDefault.paddingRightForAllElement,
			paddingBottom: SideBarDefaultNavLink.paddingBottom,
			paddingLeft: SideBarDefaultNavLink.paddingLeft
		}
	},
	NavSideBarLink_ActiveIndicator_Inactive: {
		flex: "0 0 auto",
		boxSizing: "border-box",
		borderLeft: SideBarDefaultNavLink.LinkIndicator.InactiveBorder, //Color and Width of The Indicator
		padding: SideBarDefaultNavLink.LinkIndicator.IndicatorPadding, //Used to set the padding between the Indicator and the rest of the content
		transition: "all",
		transitionDuration: SideBarDefaultNavLink.HoverTransitionDuration, //Hover Transition Duration
		transitionTimingFunction: SideBarDefaultNavLink.HoverTransitionEasing //Hover Transition Easing
	},
	NavSideBarLink_ActiveIndicator_Active: {
		flex: "0 0 auto",
		boxSizing: "border-box",
		borderLeft: SideBarDefaultNavLink.LinkIndicator.ActiveBorder, //Color and Width of The Indicator
		padding: SideBarDefaultNavLink.LinkIndicator.IndicatorPadding //Used to set the padding between the Indicator and the rest of the content
	},
	NavSideBarLinkAccordion: {
		NavSideBarLinkAccordion: {},
		NavSideBarLinkAccordion_AccordionVerticalWrapper: {
			transition: [`height`],
			transitionDuration: SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.OpenCloseTransition.TransitionDuration, //Transition Duration for the Opening and Closing of the Accordion
			transitionTimingFunction: SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.OpenCloseTransition.TransitionTimingFunction //Transition Easing for the Opening and Closing of the Accordion
		},
		//This controls the Classic and Hover Properties of the Navink for the Accordion Main element
		NavSideBarLinkAccordion_ControllerWrapper: {
			cursor: "pointer",
			padding: "0px 0px 0px 0px",
			background: SideBarAccordion.ControllerNavLink.Background, //Background Color
			borderBottom: SideBarAccordion.ControllerNavLink.BorderBottom, //Separation Border between Elements
			borderTop: SideBarAccordion.ControllerNavLink.BorderTop, //Separation Border between Elements
			color: SideBarAccordion.ControllerNavLink.TextColor, //Standard Text Color
			fontSize: SideBarAccordion.ControllerNavLink.FontSize, //FontSize
			fontWeight: SideBarAccordion.ControllerNavLink.FontWeight, //FontWeight
			transition: "all",
			transitionDuration: SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.Hover.TransitionDuration, //Hover Transition Duration
			transitionTimingFunction: SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.Hover.TransitionEsing, //Hover Transition Easing
			"&:visited": {
				textDecoration: "none"
			},
			"&:hover": {
				background: SideBarAccordion.ControllerNavLink.BackgroundHover, //Hover Background Color
				color: SideBarAccordion.ControllerNavLink.TextColorHover, //Hover Text Color
				fontSize: SideBarAccordion.ControllerNavLink.FontSizeHover //FontSize
			},
			"&:hover $NavSideBarLinkAccordion_NavSideBarLink_Controller_ActiveIndicator_Inactive": {
				flex: "0 0 auto",
				boxSizing: "border-box",
				borderLeft: SideBarDefaultNavLink.LinkIndicator.HoverBorder, //Color and Width of The Indicator
				padding: SideBarDefaultNavLink.LinkIndicator.HoverBorder //Used to set the padding between the Indicator and the rest of the content
			}
		},
		//This controls the Active Properties of the Navink for the Accordion Main element
		NavSideBarLinkAccordion_ControllerWrapper_Active: {
			cursor: "pointer",
			padding: "0px 0px 0px 0px",
			background: SideBarAccordion.ControllerNavLink.BackgroundActive, //Active Background Color
			borderBottom: SideBarAccordion.ControllerNavLink.BorderBottom, //Separation Border between Elements
			borderTop: SideBarAccordion.ControllerNavLink.BorderTop, //Separation Border between Elements
			color: SideBarAccordion.ControllerNavLink.TextColorActive, //Active Text Color
			fontSize: SideBarAccordion.ControllerNavLink.FontSizeActive, //FontSize
			fontWeight: SideBarAccordion.ControllerNavLink.FontWeight, //FontWeight
			transition: "all",
			transitionDuration: SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.Hover.TransitionDuration, //Hover Transition Duration
			transitionTimingFunction: SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.Hover.TransitionEsing, //Hover Transition Easing
			"&:visited": {
				textDecoration: "none"
			},
			"&:hover": {
				background: SideBarAccordion.ControllerNavLink.BackgroundHover, //Hover Background Color
				color: SideBarAccordion.ControllerNavLink.TextColorHover //Hover Text Color
			}
		},
		NavSideBarLinkAccordion_CollapsibleContent: {
			display: "block",
			position: "relative",
			boxSizing: "border-box",
			overflow: "hidden",
			background: SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.Content.Background,
			margin: `0px 0px 0px ${SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.Content.MarginLeft}`, //Defines the left margin of the Accordion Content for the Sub-Elements
			borderLeft: `${SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.Content.BorderWidth} solid ${SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.Content.BorderColor}` //Defines the Vertical Border Width And Color When Using Tree View
		},
		NavSideBarLinkAccordion_CollapsibleContent_WithTree: {
			display: "block",
			position: "relative",
			boxSizing: "border-box",
			overflow: "hidden",
			background: SideBarNavLinkElementsStyling.NavSideBarLinkAccordion_WithTree.Content.Background,
			margin: `0px 0px 0px ${SideBarNavLinkElementsStyling.NavSideBarLinkAccordion_WithTree.Content.MarginLeft}`, //Defines the left margin of the Accordion Content for the Sub-Elements
			borderLeft: `${SideBarNavLinkElementsStyling.NavSideBarLinkAccordion_WithTree.Content.BorderWidth} solid ${SideBarNavLinkElementsStyling.NavSideBarLinkAccordion_WithTree.Content.BorderColor}` //Defines the Vertical Border Width And Color When Using Tree View
		},
		NavSideBarLinkAccordion_NavSideBarLink_Controller: {
			display: "flex",
			color: "inherit",
			fontSize: "inherit",
			fontWeight: "inherit",
			textDecoration: "none",
			minHeight: "40px",
			width: "100%",
			"&:visited": {
				textDecoration: "none"
			}
		},
		NavSideBarLinkAccordion_NavSideBarLink_Controller_ActiveIndicator_Inactive: {
			flex: "0 0 auto",
			boxSizing: "border-box",
			position: "relative",
			borderLeft: SideBarAccordion.LinkIndicator.InactiveBorder,
			padding: SideBarAccordion.LinkIndicator.IndicatorPadding,
			transition: "all",
			transitionDuration: SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.Hover.TransitionDuration, //Hover Transition Duration
			transitionTimingFunction: SideBarNavLinkElementsStyling.NavSideBarLinkAccordion.Hover.TransitionEsing //Hover Transition Easing
		},
		NavSideBarLinkAccordion_NavSideBarLink_Controller_ActiveIndicator_Active: {
			flex: "0 0 auto",
			boxSizing: "border-box",
			position: "relative",
			borderLeft: SideBarAccordion.LinkIndicator.ActiveBorder,
			padding: SideBarAccordion.LinkIndicator.IndicatorPadding
		},
		NavSideBarLinkAccordion_NavSideBarLink_Controller_WithTree: {
			display: "flex",
			textDecoration: "none",
			minHeight: "40px",
			width: "100%",
			"&:visited": {
				textDecoration: "none"
			},
			"&:before": {
				content: '""',
				position: "absolute",
				bottom: "50%",
				left: "0px",
				borderBottom: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.TreeBar.Border, //Color and Thicknss of the horizontal Tree Bar
				width: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.TreeBar.Width // Width of the Horizontal Tree Bar
			}
		},
		NavSideBarLink_Controller_ActiveIndicator_Inactive_WithTree: {
			flex: "0 0 auto",
			boxSizing: "border-box",
			position: "relative",
			borderLeft: SideBarAccordion_WithTree.LinkIndicator.InactiveBorder,
			padding: SideBarAccordion_WithTree.LinkIndicator.IndicatorPadding
		},
		NavSideBarLink_Controller_ActiveIndicator_Active_WithTree: {
			flex: "0 0 auto",
			boxSizing: "border-box",
			position: "relative",
			borderLeft: SideBarAccordion_WithTree.LinkIndicator.ActiveBorder,
			padding: SideBarAccordion_WithTree.LinkIndicator.IndicatorPadding
		},
  
		NavSideBarLinkAccordionSubLink: {
			display: "flex",
			//   alignItems : "center",
			textDecoration: "none",
			color: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.TextColor, //Standard Text Color
			fontSize: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.FontSize, //FontSize
			minHeight: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.height, //Height of the SideBarLink Element
			position: "relative",
			borderBottom: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.borderBottom, //Separation Border between Elements
			transition: "all",
			transitionDuration: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.Hover.TransitionDuration, //Hover Transition Duration
			transitionTimingFunction: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.Hover.TransitionEsing, //Hover Transition Easing
			"&:visited": {
				textDecoration: "none"
			},
			"&:hover": {
				background: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.Hover.backgroundHover, //Hover Background Color
				color: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.Hover.TextHover //Hover Text Color
			},
			NavSideBarLink_Active: {
				background: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.backgroundActive, //Hover Background Color,
				color: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.TextActiveColor //Active Text Color
			},
			NavSideBarLinkAccordion_NavSideBarLink_Controller_ActiveIndicator_Inactive: {
				flex: "0 0 auto",
				boxSizing: "border-box",
				position: "relative",
				borderLeft: `${SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.Indicator.Width} solid ${SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.Indicator.InactiveColor}`, //Color and Width of The Indicator
				padding: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.Indicator.padding //Used to set the padding between the Indicator and the rest of the content
			},
			NavSideBarLinkAccordion_NavSideBarLink_Controller_ActiveIndicator_Active: {
				flex: "0 0 auto",
				boxSizing: "border-box",
				position: "relative",
				borderLeft: `${SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.Indicator.Width} solid ${SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.Indicator.ActiveColor}`, //Color and Width of The Indicator
				padding: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink.Indicator.padding //Used to set the padding between the Indicator and the rest of the content
			}
		},
		NavSideBarLinkAccordionSubLink_WithTree: {
			display: "flex",
			//   alignItems : "center",
			textDecoration: "none",
			color: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.TextColor, //Standard Text Color
			fontSize: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.FontSize, //FontSize
			minHeight: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.height, //Height of the SideBarLink Element
			position: "relative",
			borderBottom: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.borderBottom, //Separation Border between Elements
			transition: "all",
			transitionDuration: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.Hover.TransitionDuration, //Hover Transition Duration
			transitionTimingFunction: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.Hover.TransitionEsing, //Hover Transition Easing
			"&:visited": {
				textDecoration: "none"
			},
			"&:hover": {
				background: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.Hover.backgroundHover, //Hover Background Color
				color: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.Hover.TextHover //Hover Text Color
			},
			"&:before": {
				content: '""',
				position: "absolute",
				bottom: "50%",
				left: "0px",
				borderBottom: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.TreeBar.Border, //Color and Thicknss of the horizontal Tree Bar
				width: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.TreeBar.Width // Width of the Horizontal Tree Bar
			},
  
			NavSideBarLink_Active: {
				background: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.backgroundActive, //Hover Background Color,
				color: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.TextActiveColor //Active Text Color
			},
			ActiveIndicator_Inactive: {
				flex: "0 0 auto",
				boxSizing: "border-box",
				position: "relative",
				borderLeft: `${SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.Indicator.Width} solid ${SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.Indicator.InactiveColor}`, //Color and Width of The Indicator
				padding: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.Indicator.padding //Used to set the padding between the Indicator and the rest of the content Important for the TreeBar Display
			},
			ActiveIndicator_Active: {
				flex: "0 0 auto",
				boxSizing: "border-box",
				position: "relative",
				borderLeft: `${SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.Indicator.Width} solid ${SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.Indicator.ActiveColor}`, //Color and Width of The Indicator
				padding: SideBarNavLinkElementsStyling.NavSideBarLinkAccordionSubLink_WithTree.Indicator.padding //Used to set the padding between the Indicator and the rest of the content Important for the TreeBar Display
			}
		}
	},
	LocalCircularLoader: {
		LocalLoaderWrapper: {
			zIndex: "200",
			background: "rgba(179, 177, 177, 0)"
		},
		FixedCircleProps: {
			CircleRadius: 100,
			CircleThickness: 4,
			CircleColor: "#eef3fd"
		},
		AnimatedCircleProps: {
			CircleRadius: 100,
			CircleThickness: 4,
			CircleColor: "#6798e5"
		},
		AnimatedCircle: {
			animation: "$rotatethecircle 500ms linear infinite"
		}
	},
	GlobalBarLoader: {
		height: "4px",
		BarBackgroundColor: "rgba(66, 164, 245, 0.4)",
		BarColor: "rgba(66, 164, 245, 1)"
	},
	FormTextInput: {
		FormElement: {
			fontFamily: "roboto"
		},
		FormInput: {
			borderRadius: "4px"
		},
		FieldSet: {
			borderRadius: "4px"
			// border :"2px solid transparent"
		},
		FieldSetBorderHover: {
			borderBottom: "2px solid black"
		},
		FieldSetBorderEmpty: {
			borderBottom: "1px solid grey"
		},
		FieldSetBorderFocus: {
			borderBottom: "2px solid rgb(66,165,245)"
		},
		FieldSetBorderError: {
			borderBottom: "2px solid orange"
		},
		FieldSetBorderFilled: {
			borderBottom: "1px solid black"
		},
		FieldSetBorderDisabled: {
			borderBottom: "1px solid rgba(0, 0, 0, 0.26)"
		},
  
		FieldSetOutlinedBorderEmpty: {
			border: "1px solid grey "
		},
		FieldSetOutlinedBorderFocus: {
			border: "1px solid rgb(66,165,245)"
		},
		FieldSetOutlinedBorderError: {
			border: "1px solid orange "
		},
		FieldSetOutlinedBorderFilled: {
			border: "1px solid grey"
		},
		FieldSetOutlinedBorderDisabled: {
			border: "1px solid rgba(0, 0, 0, 0.26)"
		},
		LabelPlaceHolderEmpty: {
			color: "#666"
		},
		LabelFilled: {
			color: "black"
		},
		LabelEmpty: {
			color: "grey"
		},
		LabelFocus: {
			color: "rgb(66,165,245)"
		},
		LabelErrorEmpty: {
			color: "orange"
		},
		LabelError: {
			color: "orange"
		},
		LabelDisabledEmpty: {
			color: "green"
		},
		LabelDisabled: {
			color: "grey"
		},
  
		LabelSpan: {
			padding: "0px 5px 0px 5px"
		},
		Input: {
			paddingTop: "2px",
			paddingBottom: "2px"
		},
		InputFont: {
			fontSize: "18px",
			fontFamily: "roboto",
			fontWeight: "400"
		},
		InputColor: {
			color: "black"
		},
		InputColorFocus: {
			color: "rgb(66,165,245)"
		},
		InputColorError: {
			color: "orange"
		},
		InputColorDisabled: {
			color: "black"
		},
		BottomMessageContent: {
			marginTop: "2px",
			marginLeft: "0px",
			padding: "0",
			boxSizing: "border-box",
			fontSize: "12px",
			fontFamily: "roboto",
			fontWeight: "400"
		},
		BottomMessageContentError: {
			color: "orange"
		},
		BottomMessageContentHelper: {
			color: "blue"
		},
		BottomMessageContentHidden: {
			visibility: "hidden"
		}
	},
	CheckBox: {
		Label: {
			fontFamily: "Roboto",
			fontWeight: "400",
			fontSize: "1rem",
			color: "black",
			padding: "0px",
			margin: "0px 0px 2px 4px"
		},
		Icon: {
			IconSize: "23px",
			CheckMarkColor: "white",
			CheckFillColor: "rgb(66,165,245)",
			UncheckedBorderColor: "#565656"
		},
		Circle: {
			color: "rgb(66,165,245)",
			padding: "8px"
		}
	},
	Switch: {
		Dimensions: {
			TrackWidth: "30px",
			TrackHeight: "14px",
			KnobDiameter: "20px",
			HalloDiameter: "35px"
		},
		Label: {
			fontFamily: "Roboto",
			fontWeight: "400",
			fontSize: "1rem",
			color: "black",
			padding: "0px",
			margin: "0px 0px 2px 0px"
		},
		Track: {
			Color: "rgb(128, 128, 128)",
			ColorChecked: "rgb(66,165,245)",
			opacity: "0.8"
		},
		Knob: {
			Color: "rgb(128, 128, 128)",
			ColorChecked: "rgb(66,165,245)"
		}
	},
	FormSelect: {
		Menu: {
			marginTop: "12px",
			marginBottom: "12px",
			boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
			background: "white none repeat scroll 0% 0%",
			// backgroundColor: "none",
			fontFamily: "roboto",
			fontSize: "18px",
			fontWeight: "400"
		},
		Options: {
			padding: "5px 10px 5px 10px",
			BackgroundColor: "white",
			BackgroundColorSelected: "rgb(66, 165, 245)",
			BackgroundColorHovered: "rgb(155, 155, 155)",
  
			BackgroundAlternate: "rgb(240, 240, 240)",
			Color: "black",
			ColorSelected: "white",
			ColorFocused: "black",
			FontWeightSelected: "800"
		}
	},
	ProgressLinear: {
		ProgressBar: {
			height: "5px",
			backgroundColor: "rgb(66,165,245,0.2)"
		},
		ProgressIndicator: {
			width: "100%",
			backgroundColor: "rgb(66,165,245)"
		}
	},
	FileUploader: {
		Button: {
			height: "46px"
		},
		UploadIcon: {
			IconSize: "25px",
			SVGFillColor: "white",
			SVGFillHoverColor: "white"
		},
		Select: {
			backgroundColor: "hsl(0,0%,90%)",
			color: "black"
		}
	},
	TextInputAnimated: {
		label: {
			EmptyColor: "grey", //Color when placeholder
			FilledColor: "black", //Color of input
			FocusedColor: "rgb(66, 165, 245)", //Color of Focused Input
			ErrorColor: "orange", //Color When error
			fontSize: "18px", //Font Size of the label : Must be the same as Input
			fontFamily: "roboto", //Font Family for the label
			fontWeight: "400" //Font Weight for the label
		},
		Outlined: {
			BorderRadius: "5px",
			BorderNormal: "1px solid grey",
			BorderHover: "1px solid grey",
			BorderFocused: "1px solid grey",
			BorderError: "2px solid orange"
		},
		LineInput: {
			BorderNormal: "1px solid grey", //Border when inactive
			BorderHover: "1px solid grey",
			BorderAfter: "2px solid rgb(66, 165, 245)",
			BorderError: "1px solid orange"
		},
		Input: {
			fontSize: "18px", //Font Size of the Input : Must be the same as Label
			fontFamily: "roboto",
			fontWeight: "400",
			TextColor: "black",
			HoverColor: "black",
			FocusedColor: "rgb(66, 165, 245)",
			ErrorColor: "orange"
		},
		FormHelperText: {
			defaultColor: "blue",
			color: "orange",
			fontSize: "14px"
		}
	},
	CheckBoxAnimated: {
		label: {
			fontFamily: "roboto", //Label FontFamily
			fontSize: "16px", //Label Font Size
			fontWeight: "400", //Label Font Size
			lineHeight: "16px",
			marginBottom: "1px", //Correcting the alignment
			color: "black", //Label Color
			paddingLeft: "2px" //Padding between label and checkbox
		},
		CheckBox: {
			BorderColorUnchecked: "grey",
			PaddingCircle: "7px",
			SvgIconSize: "1em",
			CheckedColor: "rgb(66, 165, 245)",
			CircleColorUnchecked: "rgba(66, 165, 245, 0.4)",
			CircleColorChecked: "rgba(66, 165, 245, 0.08)"
		}
	},
	SelectSingleAnimated: {
		//The Select is using the same properties as the TexInputAnimated for consistency
		MenuOptions: {
			ZIndex: "10000", //ZIndex of the popup
			marginTop: "5px", // distance between the textfield and the menu
			background: "white", //background Color of the Menu
			boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
			borderRadius: "4px"
		},
		MenuItems: {
			//inside the option menu
			color: "black", //Default color
			padding: "3px 5px 3px 5px", //padding
			fontSize: "1rem",
			lineHeight: "30px",
			outlinedPadding: "10px",
			fontFamily: "Roboto",
			fontWeight: "600",
			colorHover: "black",
			backgroundHover: "rgb(240, 240, 240)",
			colorSelected: "black",
			backgroundSelected: "rgb(200, 200, 200)"
		}
	},
	CardWindow: {
		backgroundColor: "rgba(0, 0, 0, 0.85)",
		zIndex: "500",
		justifyContent: "center",
		alignItems: "center"
	},
	Card: {
		borderRadius: "10px",
		// background: "linear-gradient(0deg, #fca2c2 0%, #b4004e)",
		// backgroundColor: "rgba(255, 255, 255, 0.226)",
		color: "white",
		textAlign: "center",
		background: "linear-gradient(0deg, rgba(15, 14, 14, 0.9) 0%, rgba(49, 49, 49, 0.9) 100%)",
		boxShadow: "rgba(0, 0, 0, .419608) 0 14px 26px -12px, rgba(0, 0, 0, .117647) 0 4px 23px 0, rgba(0, 0, 0, .2) 0 8px 10px -5px",
		backgroundColor: "rgba(250, 250, 250, 0.9)"
	},
  
	Button: {
		display: "flex",
		position: "relative",
		justifyContent: "center",
		alignItems: "center",
		background: "rgb(66, 165, 245)",
		borderRadius: 5,
		border: 0,
		color: "white",
		height: 40,
		width: 180,
		padding: "0 30px",
		margin: "10px auto 10px auto",
		transition: "box-shadow 300ms ease-in-out",
		fontSize: "16px",
		fontFamily: "Roboto",
		fontWeight: "400",
		"&:hover": {
			boxShadow: "rgba(0, 0, 0, 0.419608) 0 14px 26px -12px, rgba(0, 0, 0, 0.117647) 0 4px 23px 0, rgba(0, 0, 0, 0.2) 0 8px 10px -5px"
		},
		overflow: "hidden",
		transform: "translate3d(0, 0, 0)",
		"&:after": {
			content: '""',
			display: "block",
			position: "absolute",
			width: "100%",
			height: "100%",
			top: "0",
			left: "0",
			pointerEvents: "none",
			backgroundImage: "radial-gradient(circle, rgb(255, 255, 255) 10%, transparent 10.01%)",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "50%",
			transform: "scale(10,10)",
			opacity: "0",
			transition: "transform .5s, opacity 1s"
		},
		"&:active": {
			outline: "none!important",
			border: "none!important"
		},
		"&:focus": {
			outline: "none!important",
			border: "none!important"
		},
		"&:active:after": {
			transform: "scale(0,0)",
			opacity: ".4",
			transition: "0s"
		},
		"&:disabled": {
			"&:after": {
				backgroundImage: "none",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "50%",
				transform: "scale(10,10)"
			},
			"&:before": {
				content: '" "',
				position: "absolute",
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				backgroundImage: "linear-gradient(to right, rgba(189,189,189,1) 0%, rgba(199,199,199,1) 100%)",
				opacity: "0.4"
			},
			"&:hover": {
				boxShadow: "none"
			}
		}
	},
	DXTable: {
		DetailedRowControllerWrapper: {
			padding: "0px 0px 0px 5px"
		},
		DetailedRowControllerIcon: {
			IconSize: "20",
			IconColor: "white",
			IconStrokeWidth: "10",
			IconColorHover: "white"
		}
	},
	TabNav: {
		height: "100%",
		width: "100%"
	},
	TabNavMenu: {
		display: "flex",
		height: "50px", //Height of the TabNav Menu
		flex: "0 0 auto", //Important to prevent collapsing when scrolling
		flexWrap: "nowrap",
		padding: "0px 0px 0px 0px",
		background: "none", //Background Color of the tabNavMenu
		alignItems: "flex-start",
		zIndex: "100",
		width: "100%",
		boxSizing: "border-box"
	},
	TabNavMenuSeparationShadow: {
		boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)" //Shadow of the tabNavMenu
	},
	TabNavMenuSeparationLine: {
		borderBottom: "1px solid black" //Borderof the TabMenu
	},
  
	TabNavMenuScrollIcon: {
		IconSize: "30px", //Icon Size
		SVGStrokeColor: "#6E6E6E", //Icon Color
		SVGStrokeWidth: "10px",
		SVGStrokeHoverColor: "#0FB8E6", //Icon Color Hover
		Frame: "none",
		FrameFillColorHover: "grey",
		FrameStrokeWidth: "12px",
		FrameStrokeColor: "none",
		FrameFillColor: "grey",
		InnerPadding: "25px"
	},
	TabNavLink: {
		position: "relative",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		flex: "1 0 auto",
		boxSizing: "border-box"
	},
  
	TabNavLink_Link: {
		display: "flex",
		flex: "1 0 auto",
		padding: "0px 10px 0px 10px",
		fontSize: "1rem",
		fontWeight: "400",
		textDecoration: "none",
		textTransform: "none",
		color: "black", //Text Color
		height: "100%",
		position: "relative",
		background: "none",
		zIndex: 1,
		whiteSpace: "wrap",
		letterSpacing: "0em",
		wordSpacing: "2px",
		transition: `background 600ms`,
		lineHeight: "1.2",
		"&:visited": {
			textDecoration: "none"
		},
		"&:hover": {
			background: "#E0E0E0",
			color: "black" //Text Color when hover
		}
	},
	TabNavLink_Link_TabStyle: {
		borderTopLeftRadius: "8px",
		borderTopRightRadius: "8px"
	},
	TabNavLink_Link_Active: {
		background: "#B4B4B4", //Background Color When Active
		color: "black", //Text Color When Active
		"&:hover": {
			background: "#B4B4B4", //Background Color When Active & Hover
			color: "black" //Text Color When Active and Hover
		}
	},
	TabNavLink_Link_Indicator_Active_Bottom: {
		borderBottom: "2px solid rgb(255, 68, 0)", //Indicator Border
		boxSizing: "border-box",
		"&:hover": {
			borderBottom: "2px solid rgb(255, 68, 0)" //Indicator Border Hover
		}
	},
	TabNavLink_Link_Indicator_Inactive_Bottom: {
		borderBottom: "2px solid transparent",
		boxSizing: "border-box",
		"&:hover": {
			borderBottom: "2px solid transparent"
		}
	},
	TabNavLink_Link_Indicator_Active_Top: {
		borderTop: "2px solid rgb(255, 68, 0)", //Indicator Border
		boxSizing: "border-box",
		zIndex: "300",
		"&:hover": {
			borderTop: "2px solid rgb(255, 68, 0)" //Indicator Border Hover
		}
	},
	TabNavLink_Link_Indicator_Inactive_Top: {
		borderTop: "2px solid transparent", //Indicator Border Inactive
		boxSizing: "border-box",
		"&:hover": {
			borderTop: "2px solid transparent" //Indicator Border Inactive Hover
		}
	},
	TabNavLink_Content: {
		display: "flex",
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
		flex: "1 0 auto"
	},
	ArtiWebIcons: {
		IconSize: "30",
		InnerPadding: "10",
		FrameStrokeWidth: "4",
		Frame: "none",
		Artiweb_Icon_Svg: {
			transition: "transform",
			transitionDuration: "300ms",
			transitionTimingFunction: "ease-in-out",
			hoverMain: {
				fill: "#0FB8E6",
				stroke: "#299CD0",
				transitionDuration: "300ms",
				transitionTimingFunction: "linear"
			},
			hoverFrame: {
				fill: "#B1AEAE",
				stroke: "#353535",
				transitionDuration: "300ms",
				transitionTimingFunction: "ease-in-out"
			}
		},
		Artiweb_Icon_Main: {
			fill: "#2E2E2E",
			stroke: "black",
			strokeWidth: "6",
			transitionDuration: "300ms",
			transitionTimingFunction: "ease-in-out"
		},
		Artiweb_Icon_Frame: {
			fill: "#EDEAEA",
			stroke: "#6E6E6E",
			strokeWidth: 4,
			transitionDuration: "300ms",
			transitionTimingFunction: "ease-in-out"
		}
	},
	Icon_ArrowFilledLeft: {
		fill: "#2E2E2E",
		stroke: "black",
		strokeWidth: "6"
	},
	Icon_ArrowSimpleLeft: {
		fill: "#2E2E2E",
		stroke: "black",
		strokeWidth: "6",
		InnerPadding: "5"
	},
	Icon_Search: {
		fill: "#2E2E2E",
		stroke: "black",
		strokeWidth: "6",
		InnerPadding: "10"
	},
	ExpandableBox: {
		ExpandableBox: {
			borderRadius: "4px",
			boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
			fontFamily: "Roboto",
			color: "white",
			fontSize: "1rem",
			fontWeight: "400"
		},
		TopContentWrapper: {
			flex: "0 0 40px",
			backgroundColor: "rgb(44, 44, 44)",
			borderTopLeftRadius: "4px",
			borderTopRightRadius: "4px"
		},
		Content: {
			borderBottomLeftRadius: "4px",
			borderBottomRightRadius: "4px"
		},
		ContentOpenedWrapper: {
			backgroundColor: "rgb(80, 80, 80);"
			// borderBottomLeftRadius: "4px",
			// borderBottoRightRadius: "4px"
		},
		ContentOpened: {
			padding: "10px"
		},
		ContentClosedWrapper: {
			backgroundColor: "rgb(80, 80, 80);"
			// borderBottomLeftRadius: "4px",
			// borderBottoRightRadius: "4px"
		},
		ContentClosed: {
			padding: "10px"
		},
		Buttonripple: {
			position: "relative",
			overflow: "hidden",
			transform: "translate3d(0, 0, 0)",
			width: "30px",
			height: "30px",
			transition: "background-color .5s",
			"&:hover": {
				backgroundColor: "grey",
				transition: "background-color .5s"
			},
			"&:after": {
				content: '""',
				display: "block",
				position: "absolute",
				width: "100%",
				height: "100%",
				top: "0",
				left: "0",
				pointerEvents: "none",
				backgroundImage: "radial-gradient(circle, #000 10%, transparent 10.01%)",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "50%",
				transform: "scale(10,10)",
				opacity: "0",
				transition: "transform .5s, opacity 1s"
			},
			"&:active:after": {
				transform: "scale(0,0)",
				opacity: ".2",
				transition: "0s"
			}
		},
		IconControllerStyle: {
			SVGStrokeColor: "white",
			SVGStrokeHoverColor: "white",
			IconSize: "20px"
		}
	},
	ExpandableList: {
		ExpandableList: {
			padding: "0px",
			fontFamily: "Roboto",
			color: "white",
			fontSize: "1em",
			fontWeight: "400",
			backgroundColor: "rgb(44, 44, 44)",
			borderRadius: "5px",
			boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
		},
		ControllerIconStyle: {
			SVGStrokeColor: "white",
			SVGStrokeHoverColor: "white"
		},
		Summary: {
			backgroundColor: "rgb(80, 80, 80)"
		},
		SummaryContent: {
			padding: "5px 10px"
		},
		DetailWrapper: {
			backgroundColor: "rgb(100, 100, 100)"
		},
		Detail: {
			padding: "5px 10px"
		},
		ListItemBasic: {
			backgroundColor: "grey",
			boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
			transition: "margin 0.3s cubic-bezier(.36,.59,.29,.95)"
		},
		ListItemMargin: "10px", //The gap that separates the elements when opened
  
		BorderSeparationColor: "rgba(255, 255, 255, 0.3)" //The Line that separates the list item when closed
	}
  };
  export default theme;
  