/*eslint-disable*/
import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Link} from "react-router-dom";
import {Facebook, Linkedin} from "@artibulles-cis/react-icons";

const styles = createUseStyles((theme) => ({
    BottomBar: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        background: "rgb(44,44,44)",
        color: "white",
    },
    BottomBar_AppInfo: {
        flex: "0 1 400px",
    },
    BottomBar_AppConsole: {
        flex: "1 1 auto",
    },
    Footer_SocialIconsWrapper: {
        display: "flex",
    },
    IconBoxSocial: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "5px 5px",
    },
    CisLogo: {
        height: "30px",
        width: "auto",
    },
}));

const BottomNavBar = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    return (
        <div className={classes.BottomBar}>
            <div className="FooterWrapper">
                <div className="FooterPart_Single_Cols_Mobile">
                    <h3>Site Maps</h3>
                    <ul>
                        <li>
                            <Link to="/es" className="FooterNavLink">
                                Engineering Solutions
                            </Link>
                        </li>

                        <li>
                            <Link to="/iso" className="FooterNavLink">
                                Innovation, Strategy & Operational Support
                            </Link>
                        </li>
                        <li>
                            <Link to="/dc" className="FooterNavLink">
                                Digital Communication
                            </Link>
                        </li>
                        <li>
                            <Link to="/cis" className="FooterNavLink">
                                Custom IT Solutions
                            </Link>
                        </li>
                        <li>
                            <Link to="/pm" className="FooterNavLink">
                                Project Management
                            </Link>
                        </li>
                        <li>
                            <p className="FooterNavLink">Artibulles Company</p>
                            <ul>
                                <li>
                                    <Link to="/contact" className="FooterNavLink">
                                        Contact US
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
				<div className="Footer_Section_Separator" />
                <div className="FooterPart_Single_Cols_Mobile">
                    <h3>Follow US</h3>

                    <div className={classes.Footer_SocialIconsWrapper}>
                        <div className={classes.IconBoxSocial}>
                            <a href="https://www.linkedin.com/company/artibulles" target="_blank">
                                <Linkedin IconSize="40px" />
                            </a>
                        </div>

                        <div className={classes.IconBoxSocial}>
                            <a href="https://www.facebook.com/ArTiBulles-1832078817006718" target="_blank">
                                <Facebook IconSize="40px" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="Footer_Section_Separator" />
                <div className="FooterPart_Single_Cols_Mobile Legal" style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <div>
                        <Link to="/legal" className="FooterNavLink">
                            Legal
                        </Link>
                    </div>
                    <span>&nbsp;</span>
                    <span>-</span>
                    <span>&nbsp;</span>
                    <div>
                        <Link to="/termsandconditions" className="FooterNavLink">
                            Terms and Conditions
                        </Link>
                    </div>
                    <span>&nbsp;</span>
                    <span>-</span>
                    <span>&nbsp;</span>
                    <div>
                        <Link to="/privacy" className="FooterNavLink">
                            Privacy Policy
                        </Link>
                    </div>
                    <span>&nbsp;</span>
                    <span>-</span>
                    <span>&nbsp;</span>
                    <div>
                        <Link to="/cookies" className="FooterNavLink">
                            Cookies
                        </Link>
                    </div>
                </div>
                <div className="Footer_Section_Separator" />
                <div className="FooterPart_Single_Cols_Mobile">
                    <div className="FooterCompanyWithLogo">
                        <img src="/images/general/ArtibullesLogo.svg" alt="CompanyLogo" />
                        <h3>Artibulles</h3>
                    </div>
                    <p>180, Avenue de Tervueren</p>
                    <p>1150 Woluwe-Saint-Pierre</p>
                    <p>Belgium</p>
                </div>

                <div className="Footer_Section_Separator" />
                <div id="WebDesign" className="FooterPart_Single_Cols_Mobile">
                    <a href="https://www.artibulles-cis.artibulles.com" target="_blank" className="Footer_WebInfoWrapper">
                        <p style={{marginRight: "10px"}}>WebSite Design : </p>
                        <img className={classes.CisLogo} src="/images/icons/Artibulles_logo_CIS_black_fontexp.svg" alt="Artibulles-cis-Logo" />
                        <p style={{marginLeft: "10px", color: "rgb(66, 165, 245)"}}>ArTiBulles CIS</p>
                    </a>
                </div>

                <div className="Footer_Section_Separator" />
                <div className="FooterPart_Single_Cols_Mobile">
                    <h3>Copyright © 2020 ArTiBulles</h3>
                </div>
            </div>
        </div>
    );
};

export default BottomNavBar;
// Artibulles_Icon_WithText_CIS.svg
// <img className={classes.CisLogo} src="/images/icons/Artibulles_logo_CIS_black_fontexp.svg" alt="Artibulles-cis-Logo"/>
