import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import ScrollToTop from "./app/common/util/ScrollToTop";

// import {Provider} from "react-redux";
// import {configureStore} from "./app/reduxStore/configureStore";
import "./scss/App.scss";
import App from "./app/layout/App";
import * as serviceWorker from "./serviceWorker";
// const store = configureStore();
// Getting our root element to start rendering React
const rootEl = document.getElementById("root");

let render = () => {
    ReactDOM.render(
        <BrowserRouter>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </BrowserRouter>,
        rootEl
    );
};
render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
