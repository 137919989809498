import React from "react";
import {MatomoProvider, createInstance} from "@datapunt/matomo-tracker-react";
import ArtiwebThemeProvider from "@artibulles-cis/react/ArtiwebThemeProvider";
import WebsiteAppInit from "@artibulles-cis/react/WebsiteAppInit";

import MainTheme from "./ArtibullesCIS-react-Themes/mainTheme";
// import SiteCookies from "../Cookies/SiteCookies";

import AppDesktop from "./AppDesktop";
import AppMobile from "./AppMobile";

const MatomoInstance = createInstance({
    urlBase: "https://analytics.artibulles.com",
    siteId: 1, // optional, default value: `1`
    // userId: "UID76903202", // optional, default value: `undefined`.
    // trackerUrl: "https://LINK.TO.DOMAIN/tracking.php", // optional, default value: `${urlBase}matomo.php`
    // srcUrl: "https://LINK.TO.DOMAIN/tracking.js", // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
        // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10, // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: {
        // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: "POST",
    },
});

const App = (props) => {
    const AppConfiguration = {
        appMaxWidth: "2500px",
        mobileWidth: "1000px",
        GeoLocation: true,
        withCookies: false,
        animationIntro: false,
        animationTime: 2500,
    };

    const SiteCookiesList = [
        {
            type: "Mandatory",
            content: [
                {name: "privacy", value: {shown: true, accepted: true}, expirationDays: 365, type: "privacy"},
                {name: "visited", value: true, expirationDays: 0, type: "visited"},
                {name: "geo", value: {country: null, browserLang: null}, expirationDays: 0},
                {name: "lang", value: {perferedLang: null}, expirationDays: 365},
            ],
        },
        {type: "Optional", content: [{name: "arnaud_cook", value: "arnaud est beau", expirationDays: 0}]},
    ];

    return (
		<MatomoProvider value={MatomoInstance}>
        <ArtiwebThemeProvider Theme={MainTheme}>
            <WebsiteAppInit
                {...AppConfiguration}
                MobileComponent={AppMobile}
                DesktopComponent={AppDesktop}
                SiteCookiesList={SiteCookiesList}
                // CookiesComponent={SiteCookies}
                // HomePageAnimationComponent={HomePageAnimation}
            />
		</ArtiwebThemeProvider>
		</MatomoProvider>
    );
};

export default App;

