import React from "react";

import {Route, Switch} from "react-router-dom";
import {createUseStyles, useTheme} from "react-jss";

import {ToastContainer, toast, Slide} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppWrapper from "@artibulles-cis/react/AppWrapper";
import AppMainContainer from "@artibulles-cis/react/AppMainContainer";
import NavHorizontalBar from "@artibulles-cis/react/NavHorizontalBar";

import TopNavBar from "./TopNavBar/TopNavBar";

import HomePage from "../../Pages/Desktop/HomePage";
import ISOPage from "../../Pages/Desktop/ISO_Page";
import DCPage from "../../Pages/Desktop/DC_Page";
import CISPage from "../../Pages/Desktop/CIS_Page";
import PMPage from "../../Pages/Desktop/PM_Page";
import ESPage from "../../Pages/Desktop/ES_Page";
import Cookies from "../../Pages/Desktop/Legal/Cookies";
import TermsAndConditions from "../../Pages/Desktop/Legal/TermsAndConditions";
import PrivacyPolicy from "../../Pages/Desktop/Legal/PrivacyPolicy";
import Legal from "../../Pages/Desktop/Legal/Legal";
import Contact from "../../Pages/Desktop/Contact";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));
toast.configure();
const AppDesktop = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    const AppConfig = {
        maxWidth: "2500px",
    };
    

    return (
        <React.Fragment>
            <AppWrapper AppMaxWidth={AppConfig.maxWidth} style={{height: "100%"}}>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    // style={{minWidth : "500px"}}
                    transition={Slide}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnVisibilityChange={false}
                    draggable={false}
                    pauseOnHover={false}
                />
                <NavHorizontalBar Show={true} Height="60px" id="TopFixedNavBar" MaxWidth={AppConfig.maxWidth}>
                    <TopNavBar />
                </NavHorizontalBar>

                <AppMainContainer
                    WithPerfectScrollbar={false}
                    WithScrollBar={false}
                    // classExtendMainContainer={classes.classExtendMainContainer}
                    DisableBodyScroll={true}
                    TopRefComponentID="TopFixedNavBar"
                    // BottomRefComponentID="BottomFixedNavBar"
                >
                    <Switch>
                        <Route exact path="/">
                            <HomePage />
                        </Route>
                        <Route path="/es">
                            <ESPage />
                        </Route>
                        <Route path="/iso">
                            <ISOPage />
                        </Route>
                        <Route path="/dc">
                            <DCPage />
                        </Route>
                        <Route path="/cis">
                            <CISPage />
                        </Route>
                        <Route path="/pm">
                            <PMPage />
                        </Route>
                        <Route path="/legal">
                            <Legal />
                        </Route>
                        <Route path="/termsandconditions">
                            <TermsAndConditions />
                        </Route>
                        <Route path="/cookies">
                            <Cookies />
                        </Route>
                        <Route path="/privacy">
                            <PrivacyPolicy />
                        </Route>
                        <Route path="/contact">
                            <Contact />
                        </Route>
                    </Switch>
                </AppMainContainer>
            </AppWrapper>
        </React.Fragment>
    );
};

export default AppDesktop;
// {AppRouting()}
