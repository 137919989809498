import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import Page from "@artibulles-cis/react/Page";
import PagesBoxDescription from "./PagesBoxDescription/PagesBoxDescription";
import BottomNavBar from "../../app/layout/BottomNavBar/BottomNavBarMobile";
import {TopSection} from "../TextContent/ISOPageText";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Summary: {
        position: "relative",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflowX: "hidden",
	},
	Summary_Top_DivWrapper :{
		display: "flex",
		margin: "0 auto",
		flexDirection: "row",
		justifyContent: "center",
		position: "relative",
		padding: "0px 8px 0px 8px",
	}
}));

const EngineeringSolution = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});const {trackPageView} = useMatomo();
    const Language = "English";
    var FinalLanguageTag;
    if (Language === "English") {
        FinalLanguageTag = "English";
    }
//Matomo Tracking
React.useEffect(() => {
	trackPageView({
		documentTitle: "ISOPage_Mobile",
	});
}, [trackPageView]);
    var Description = TopSection[FinalLanguageTag].Description;
    var CombinedBoxContent = TopSection[FinalLanguageTag].CombinedBoxContent;
    return (
        <Page WithScrollbar={{X: "auto", Y: "auto"}}>
            <section className={classes.Summary}>
                <section className="SummaryBackground_ISO SummaryBackground_ISO_Mobile">
                    <div className="Summary_Top_DivWrapper">
                        <PagesBoxDescription
                            TitleImgPath="/images/icons/Artibulles_logo_ISO_black_fontexp.svg"
                            Title="Innovation, Strategy, Operational Support"
                            // TitleRightContent={<div>Hello There</div>}
                            RefBusiness="ISO"
                            SectionLeftImagesPath={{
                                top: "/images/general/Artibulles_ISO_icon1.png",
                                right: "/images/general/Artibulles_ISO_icon2.png",
                                bottom: "/images/general/Artibulles_ISO_icon3.png",
                                left: "/images/general/Artibulles_ISO_icon4.png",
                            }}
                            DescriptionContent={Description}
                            CombinedBoxContent={CombinedBoxContent}
                        />
                    </div>
                </section>
            </section>
            <BottomNavBar />
        </Page>
    );
};

export default EngineeringSolution;
