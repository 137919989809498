import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {CloseIcon} from "@artibulles-cis/react-icons";
import Button from "@artibulles-cis/react/Button";

import {useHistory} from "react-router-dom";
//Defining the parameters for automated styling
const SummaryBoxMaxWidth = 1300;
const SummaryContentHeight = 550;

const SVGBoxPadding = 20;
const SVGRatio = (SummaryContentHeight - 2 * SVGBoxPadding) / (SummaryBoxMaxWidth - 2 * SVGBoxPadding);
const SVGHeight = 1000 * SVGRatio;
const SummaryBoxRadius = "15px";
// const SummaryBoxBackgroundColor = "hsla(0,0%,100%,.6)";
const SummaryBoxBackgroundColor = "rgba(44, 44, 44,0.9)";

// const SummaryBoxTitleBackgroundColor = "hsla(0,0%,100%,.45)";
const SummaryBoxTitleBackgroundColor = "black";

const styles = createUseStyles((theme) => ({
    Summary_Wrapper: {
        display: "flex",
        maxWidth: SummaryBoxMaxWidth + "px",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: SummaryBoxRadius,
        backgroundColor: SummaryBoxBackgroundColor,
    },
    Summary_TitleWrapper: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        borderTopLeftRadius: SummaryBoxRadius,
        borderTopRightRadius: SummaryBoxRadius,
        padding: "10px 15px 10px 15px",
        backgroundColor: SummaryBoxTitleBackgroundColor,
        alignItems: "center",
        boxSizing: "border-box",
        color: "white",
    },
    Summary_TitleIcon_Wrapper: {
        flex: "0 0 60px",
        display: "flex",
        alignItems: "center",
    },
    Summary_TitleIcon: {
        height: "60px",
        width: "60px",
    },
    Summary_Title_Wrapper: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
    },
    Summary_Title: {
        color: "white",
        margin: "0px 0px 0px 15px",
        fontSize: "2rem",
        fontWeight: "700",
    },
    Summary_TitleRightContent: {
        flex: "0 0 350px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },

    Summary_Content_Wrapper: {
        display: "flex",
        position: "relative",
        margin: "0",
        padding: "0",
        justifyContent: "space-around",
        height: SummaryContentHeight + "px",
        alignItems: "center",
        boxSizing: "border-box",
    },
    Svg_Wrapper: {
        position: "absolute",
        display: "flex",
        // border : "1px solid white",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: SVGBoxPadding + "px",

        boxSizing: "border-box",
        height: "100%",
    },
    Svg_Box: {
        width: "100%",
        // maxWidth : "60px",
        height: "100%",
    },
    Junction1: {
        display: "none",
    },

    Junction2: {
        display: "none",
    },
    Junction3: {
        display: "none",
    },
    Junction4: {
        display: "none",
    },

    Summary_Content_Description_Wrapper: {
        position: "absolute",
        top: SVGBoxPadding + "px",
        height: SummaryContentHeight - 2 * SVGBoxPadding + "px",
        bottom: "0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        opacity: 0,
        transition: "opacity .2s",
    },

    Summary_Content_Description_List_Wrapper: {},
    Summary_Content_Description_List: {
        margin: "0px",
        listStyle: "none",
        "& li": {
            display: "flex",
            alignContent: "flex-start",
            alignItems: "flex-start",
            padding: "5px 0px",
            margin: "0px",
        },
        "& p": {
            margin: "0px",
            lineHeight: "25px",
        },
    },
    BulletLogo: {
        height: "20px",
        width: "auto",
        margin: "3px 10px 0px 0px",
        fill: "white",
        stroke: "white",
    },
    CombinedActivities_Wrapper: {
        display: "flex",
        width: "90%",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        background: "black",
        color: "white",
        borderRadius: "10px",
    },
    CombinedActivities_Wrapper_Block: {
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        background: "black",
        borderRadius: "10px",
    },

    CombinedActivities_Top: {
        flex: "0 0 85px",
        width: "100%",
        display: "flex",
        position: "relative",
        // alignItems: "center",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        borderBottom: "1px solid white",
    },

    CombinedActivities_Top_Title_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "10px 10px 10px 10px",
        fontFamily: "Roboto",
        fontWeight: "700",
        fontSize: "1rem",
    },

    CombinedActivities_Top_Title_Top: {
        textAlign: "left",
        lineHeight: "1rem",
    },
    CombinedActivities_Top_Title_Middle: {
        textAlign: "center",
        lineHeight: "1rem",
        margin: "4px 0px 4px 0px",
    },
    CombinedActivities_Top_Title_Bottom: {
        textAlign: "right",
        lineHeight: "1rem",
    },
    CombinedActivities_Top_Close: {
        flex: "0 0 40px",
        display: "flex",
        position: "absolute",
        right: "0px",
        top: "0px",
        alignItems: "flex-start",
        justifyContent: "center",
        boxSizing: "border-box",
        padding: "5px 5px 0px 0px",
    },
    CombinedActivities_Content: {
        flex: "1 1 auto",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        // justifyContent : "center",
        boxSizing: "border-box",
        padding: "10px 20px 10px 20px",
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "0.9rem",
    },
    CombinedActivities_Content_Title: {
        margin: "0px",
    },
    CombinedActivities_Content_Description: {
        margin: "5px 0px",
        "& li": {
            margin: "0px",
        },
    },

    CombinedActivities_Footer: {
		flex: "0 0 50px",
		width : "100%",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
		padding: "10px 20px 10px 20px",
		justifyContent : "center"
    },
    ClassExtendButton_LearnMore: {
        textTransform: "uppercase",
    },

    TopSection_CirclePictures_Group: {
        transition: `all 0.5s`,
    },
    TopSection_CirclePictures_Group_Top_Hover: {
        transform: "scale(1.5)",
        transition: `all 0.5s`,
    },
    TopSection_CirclePictures_Group_Left_Hover: {
        transform: "scale(1.5)",
        transition: `all 0.5s`,
    },
    TopSection_CirclePictures_Group_Bottom_Hover: {
        transform: "scale(1.5)",
        transition: `all 0.5s`,
    },
    TopSection_CirclePictures_Group_Right_Hover: {
        transform: "scale(1.5)",
        transition: `all 0.5s`,
    },
}));

const PagesBoxDescription = (props) => {
    const {
        RefBusiness,
        TitleImgPath,
        Title,
        TitleRightContent,
        SectionLeftImagesPath,
        DescriptionContent,
        CombinedBoxContent,
        //Dont fold
    } = props;
    const theme = useTheme();
	const classes = styles({...props, theme});
	const {trackEvent} = useMatomo();
    const History = useHistory();
    const RefMiddleSectionSVG = useRef(null);
    const RefSummaryContentWrapper = useRef(null);
    const RefCombinedBoxWrapper = useRef(null);
    

    const [MiddleSectionDim, setMiddleSectionDim] = useState({left: 0, width: 0});
    const [CombinationBoxVisibility, setCombinationBoxVisibility] = useState({
        target1: false,
        target2: false,
        target3: false,
        target4: false,
    });

    const CalcMiddleSection = useCallback(() => {
        if (RefMiddleSectionSVG && RefMiddleSectionSVG.current && RefSummaryContentWrapper && RefSummaryContentWrapper.current) {
            let MiddleSectionLeft = RefMiddleSectionSVG.current.getBoundingClientRect().left;
            let MiddleSectionWidth = RefMiddleSectionSVG.current.getBoundingClientRect().width;
            let SummaryContentWrapperLeft = RefSummaryContentWrapper.current.getBoundingClientRect().left;
            setMiddleSectionDim({left: MiddleSectionLeft - SummaryContentWrapperLeft, width: MiddleSectionWidth});
            // console.log(MiddleSectionLeft - SummaryContentWrapperLeft)
            // console.log(MiddleSectionWidth)
        }
    }, [RefMiddleSectionSVG, RefSummaryContentWrapper]);

    const [TopSectionPicturesClassesHover, setTopSectionPicturesClassesHover] = useState({
        top: classes.TopSection_CirclePictures_Group,
        left: classes.TopSection_CirclePictures_Group,
        bottom: classes.TopSection_CirclePictures_Group,
        right: classes.TopSection_CirclePictures_Group,
    });

    //Recalculating the position of the middle section on resize
    useEffect(() => {
        CalcMiddleSection();
        window.addEventListener("resize", CalcMiddleSection);
        return () => {
            window.removeEventListener("resize", CalcMiddleSection);
        };
    }, [CalcMiddleSection]);

    //Handle Click outside
    const HandleClickOutside = (e) => {
        if (RefCombinedBoxWrapper.current.contains(e.target)) {
        } else {
            if (e.target.id.includes("SectionRight_Circle_Img")) {
            } else {
                let UpdatedState = {
                    target1: false,
                    target2: false,
                    target3: false,
                    target4: false,
                };
                setCombinationBoxVisibility(UpdatedState);
            }
        }
    };
    //Adding Event Listeners for Click Outside
    useEffect(() => {
        if (CombinationBoxVisibility.target1 === true || CombinationBoxVisibility.target2 === true || CombinationBoxVisibility.target3 === true || CombinationBoxVisibility.target4 === true) {
            document.addEventListener("mousedown", HandleClickOutside);
        } else {
            document.removeEventListener("mousedown", HandleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", HandleClickOutside);
        };
    }, [CombinationBoxVisibility]);

    const HandleCloseCombinationBox = () => {
        let UpdatedState = {
            target1: false,
            target2: false,
            target3: false,
            target4: false,
        };
        setCombinationBoxVisibility(UpdatedState);
    };

    const HandleCircleLeftClick = (target) => {
		//Matomo Tracking
        trackEvent({category: `${Title}_Page_Desktop`, action: `CombinationBoxShow : ${target}`});
        let UpdatedState = {
            target1: false,
            target2: false,
            target3: false,
            target4: false,
        };

        setCombinationBoxVisibility({...UpdatedState, ...{[target]: true}});
    };

    const HandleNavigateTo = (target) => {
        History.push(`/${target}`);
    };

    /*********** KEY SVG PARAMETERS ************/
    const RealSVGRatio = 1000 / 1280;
    const SvgSectionWidthLeft = 330;
    const SvgSectionWidthRight = 110;
    //Left Circle Param
    const LeftCircleWrapperStrokeWidth = 6;
    const LeftCircleInnerStokeWidth = 5;
    //Padding between LeftCircle and the images circles
    const LeftCircleWrapperPadding = 7;
    const LeftCircleImgContainerRadius = (130 * RealSVGRatio) / 2;
    const LeftCircleImgPaddingImage = 4; //Internal Image Padding
    const LeftCentralLogoWidth = 50;

    //Right Cirlces Param
    const RightCircleImgRadius = 40; //Padding included
    const RightCircleImgPaddingImage = 23; //Internal Image Padding
    const AnimatedHallo = 2.5;
    //Junction Lines Params
    const JunctionLinesRightPadding = 50;
    const JunctionLinesStrokeWidth = 6;

    //Description Box Padding
    const DescriptionBoxPadding = {
        top: 10,
        right: 30,
        bottom: 10,
        left: 30,
    };

    const BusinessTitleES = "Engineering Solutions";
    const BusinessTitleISO = "Innovation, Strategy, Operational Support";
    const BusinessTitleDC = "Digital Communication";
    const BusinessTitleCIS = "Custom IT Solutions";
    const BusinessTitlePM = "Project Management";
    /*********** KEY SVG PARAMETERS ************/

    /*********** COLORS DEFINIITION ************/
    const ColorOrange = "#FF6103";
    const ColorPink = "#ED2D92";
    const ColorGreen = "#6DBE45";
    const ColorBlue = "#4997D2";
    const ClorYellow = "#FCB415";
    const ColorES = ColorOrange;
    const ColorDC = ColorPink;
    const ColorCIS = ColorBlue;
    const ColorISO = ColorGreen;
    const ColorPM = ClorYellow;

    // const ColorESRight = ColorOrange;
    // const ColorDCRight = ColorPink;
    // const ColorCISRight = ColorBlue;
    // const ColorISORight = ColorGreen;
    // const ColorPMRight = ClorYellow;

    const ColorESRight = "black";
    const ColorDCRight = "black";
    const ColorCISRight = "black";
    const ColorISORight = "black";
    const ColorPMRight = "black";

    // const LeftColorImageWrapper = SummaryBoxBackgroundColor
    const LeftColorImageWrapper = "rgba(30, 30, 30,1)";

    /*********** COLORS DEFINIITION ************/

    /*********** LOGOS SOURCE ************/
    const logoPathES = "/images/icons/Artibulles_logo_ES_black_fontexp.svg";
    const logoPathISO = "/images/icons/Artibulles_logo_ISO_black_fontexp.svg";
    const logoPathDC = "/images/icons/Artibulles_logo_DC_black_fontexp.svg";
    const logoPathCIS = "/images/icons/Artibulles_logo_CIS_black_fontexp.svg";
    const logoPathPM = "/images/icons/Artibulles_logo_PM_black_fontexp.svg";

    /*********** LOGOS SOURCE ************/

    /*********** Defining colors and images sources ************/
    // ES->ISO->DC->CIS->PM

    var JunctionColor1, JunctionColor2, JunctionColor3, JunctionColor4;
    var RigthCircleColor1, RigthCircleColor2, RigthCircleColor3, RigthCircleColor4;
    var RigthImgPath1, RigthImgPath2, RigthImgPath3, RigthImgPath4;
    var LeftCircleExternalColor, LeftCircleInternalColor;
    var LeftCircleImgColor;
    var LeftCentralIconPath;
    var LeftCircleColorWhenCombinedDesc;
    var CombinedBoxTitle;

    if (RefBusiness === "ES") {
        CombinedBoxTitle = {
            top: BusinessTitleES,
            bottom1: BusinessTitleISO,
            bottom2: BusinessTitleDC,
            bottom3: BusinessTitleCIS,
            bottom4: BusinessTitlePM,
            buttonColor1: ColorISO,
            buttonColor2: ColorDC,
            buttonColor3: ColorCIS,
            buttonColor4: ColorPM,
            buttonNavTo1: "iso",
            buttonNavTo2: "dc",
            buttonNavTo3: "cis",
            buttonNavTo4: "pm",
        };
        LeftCentralIconPath = logoPathES;
        LeftCircleImgColor = LeftColorImageWrapper;
        LeftCircleExternalColor = ColorES;
        LeftCircleInternalColor = ColorES;
        LeftCircleColorWhenCombinedDesc = "black";
        RigthImgPath1 = logoPathISO;
        RigthImgPath2 = logoPathDC;
        RigthImgPath3 = logoPathCIS;
        RigthImgPath4 = logoPathPM;
        JunctionColor1 = ColorISO;
        JunctionColor2 = ColorDC;
        JunctionColor3 = ColorCIS;
        JunctionColor4 = ColorPM;
        RigthCircleColor1 = ColorISORight;
        RigthCircleColor2 = ColorDCRight;
        RigthCircleColor3 = ColorCISRight;
        RigthCircleColor4 = ColorPMRight;
    } else if (RefBusiness === "ISO") {
        LeftCentralIconPath = logoPathISO;
        LeftCircleImgColor = LeftColorImageWrapper;
        LeftCircleExternalColor = ColorISO;
        LeftCircleInternalColor = ColorISO;
        LeftCircleColorWhenCombinedDesc = "black";
        RigthImgPath1 = logoPathES;
        RigthImgPath2 = logoPathDC;
        RigthImgPath3 = logoPathCIS;
        RigthImgPath4 = logoPathPM;

        JunctionColor1 = ColorES;
        JunctionColor2 = ColorDC;
        JunctionColor3 = ColorCIS;
        JunctionColor4 = ColorPM;
        RigthCircleColor1 = ColorESRight;
        RigthCircleColor2 = ColorDCRight;
        RigthCircleColor3 = ColorCISRight;
        RigthCircleColor4 = ColorPMRight;
        CombinedBoxTitle = {
            top: BusinessTitleISO,
            bottom1: BusinessTitleES,
            bottom2: BusinessTitleDC,
            bottom3: BusinessTitleCIS,
            bottom4: BusinessTitlePM,
            buttonColor1: ColorES,
            buttonColor2: ColorDC,
            buttonColor3: ColorCIS,
            buttonColor4: ColorPM,
            buttonNavTo1: "es",
            buttonNavTo2: "dc",
            buttonNavTo3: "cis",
            buttonNavTo4: "pm",
        };
    } else if (RefBusiness === "DC") {
        CombinedBoxTitle = {
            top: BusinessTitleDC,
            bottom1: BusinessTitleES,
            bottom2: BusinessTitleISO,
            bottom3: BusinessTitleCIS,
            bottom4: BusinessTitlePM,
            buttonColor1: ColorES,
            buttonColor2: ColorISO,
            buttonColor3: ColorCIS,
            buttonColor4: ColorPM,
            buttonNavTo1: "es",
            buttonNavTo2: "iso",
            buttonNavTo3: "cis",
            buttonNavTo4: "pm",
        };
        LeftCentralIconPath = logoPathDC;
        LeftCircleImgColor = LeftColorImageWrapper;
        LeftCircleExternalColor = ColorPink;
        LeftCircleInternalColor = ColorPink;
        LeftCircleColorWhenCombinedDesc = "black";
        RigthImgPath1 = logoPathES;
        RigthImgPath2 = logoPathISO;
        RigthImgPath3 = logoPathCIS;
        RigthImgPath4 = logoPathPM;
        JunctionColor1 = ColorES;
        JunctionColor2 = ColorISO;
        JunctionColor3 = ColorCIS;
        JunctionColor4 = ColorPM;
        RigthCircleColor1 = ColorESRight;
        RigthCircleColor2 = ColorISORight;
        RigthCircleColor3 = ColorCISRight;
        RigthCircleColor4 = ColorPMRight;
    } else if (RefBusiness === "CIS") {
        CombinedBoxTitle = {
            top: BusinessTitleCIS,
            bottom1: BusinessTitleES,
            bottom2: BusinessTitleISO,
            bottom3: BusinessTitleDC,
            bottom4: BusinessTitlePM,
            buttonColor1: ColorES,
            buttonColor2: ColorISO,
            buttonColor3: ColorDC,
            buttonColor4: ColorPM,
            buttonNavTo1: "es",
            buttonNavTo2: "iso",
            buttonNavTo3: "dc",
            buttonNavTo4: "pm",
        };
        LeftCentralIconPath = logoPathCIS;
        LeftCircleImgColor = LeftColorImageWrapper;
        LeftCircleExternalColor = ColorCIS;
        LeftCircleInternalColor = ColorCIS;
        LeftCircleColorWhenCombinedDesc = "black";
        RigthImgPath1 = logoPathES;
        RigthImgPath2 = logoPathISO;
        RigthImgPath3 = logoPathDC;
        RigthImgPath4 = logoPathPM;
        JunctionColor1 = ColorES;
        JunctionColor2 = ColorISO;
        JunctionColor3 = ColorDC;
        JunctionColor4 = ColorPM;
        RigthCircleColor1 = ColorESRight;
        RigthCircleColor2 = ColorISORight;
        RigthCircleColor3 = ColorDCRight;
        RigthCircleColor4 = ColorPMRight;
    } else if (RefBusiness === "PM") {
        CombinedBoxTitle = {
            top: BusinessTitlePM,
            bottom1: BusinessTitleES,
            bottom2: BusinessTitleISO,
            bottom3: BusinessTitleDC,
            bottom4: BusinessTitleCIS,
            buttonColor1: ColorES,
            buttonColor2: ColorISO,
            buttonColor3: ColorDC,
            buttonColor4: ColorCIS,
            buttonNavTo1: "es",
            buttonNavTo2: "iso",
            buttonNavTo3: "dc",
            buttonNavTo4: "cis",
        };
        LeftCentralIconPath = logoPathPM;
        LeftCircleImgColor = LeftColorImageWrapper;
        LeftCircleExternalColor = ColorPM;
        LeftCircleInternalColor = ColorPM;
        LeftCircleColorWhenCombinedDesc = "black";
        RigthImgPath1 = logoPathES;
        RigthImgPath2 = logoPathISO;
        RigthImgPath3 = logoPathDC;
        RigthImgPath4 = logoPathCIS;
        JunctionColor1 = ColorES;
        JunctionColor2 = ColorISO;
        JunctionColor3 = ColorDC;
        JunctionColor4 = ColorCIS;
        RigthCircleColor1 = ColorESRight;
        RigthCircleColor2 = ColorISORight;
        RigthCircleColor3 = ColorDCRight;
        RigthCircleColor4 = ColorCISRight;
    }

    //

    var SvgSectionWidthMiddle = 1000 - SvgSectionWidthLeft - SvgSectionWidthRight; //590

    const SvgSectionCenterLeft = {
        x: SvgSectionWidthLeft / 2 + AnimatedHallo * 4,
        y: SVGHeight / 2,
    };
    const SvgSectionCenterMiddle = {
        x: SvgSectionWidthLeft + SvgSectionWidthMiddle / 2,
        y: SVGHeight / 2,
    };
    const SvgSectionCenterRight = {
        x: SvgSectionWidthLeft + SvgSectionWidthMiddle + SvgSectionWidthRight / 2,
        y: SVGHeight / 2,
    };

    //Left Circle
    const LeftCircleWrapperRadius = (SvgSectionWidthLeft - LeftCircleWrapperStrokeWidth) / 2;
    //Calculating the middle circle based on the size of each individual circles
    const LeftCircleInnerRadius = LeftCircleWrapperRadius - LeftCircleWrapperStrokeWidth - LeftCircleWrapperPadding - LeftCircleImgContainerRadius;

    const LeftCentralLogoDim = {
        x: SvgSectionCenterLeft.x - LeftCentralLogoWidth / 2,
        y: SvgSectionCenterLeft.y - LeftCentralLogoWidth / 2,
        dim: LeftCentralLogoWidth,
    };

    var Left_Circle_Img_Dim_Top = {
        cx: SvgSectionCenterLeft.x,
        cy: SvgSectionCenterLeft.y - LeftCircleInnerRadius,
        r: LeftCircleImgContainerRadius,
    };
    const Left_Circle_Img_Dim_Bottom = {
        cx: SvgSectionCenterLeft.x,
        cy: SvgSectionCenterLeft.y + LeftCircleInnerRadius,
        r: LeftCircleImgContainerRadius,
    };
    const Left_Circle_Img_Dim_Left = {
        cx: SvgSectionCenterLeft.x - LeftCircleInnerRadius,
        cy: SvgSectionCenterLeft.y,
        r: LeftCircleImgContainerRadius,
    };
    const Left_Circle_Img_Dim_Right = {
        cx: SvgSectionCenterLeft.x + LeftCircleInnerRadius,
        cy: SvgSectionCenterLeft.y,
        r: LeftCircleImgContainerRadius,
    };

    let LeftImgDim = (LeftCircleImgContainerRadius - LeftCircleImgPaddingImage) * 2;

    var Left_Circle_Img_ImageDim_Top = {
        x: Left_Circle_Img_Dim_Top.cx - LeftImgDim / 2,
        y: Left_Circle_Img_Dim_Top.cy - LeftImgDim / 2,
        dim: LeftImgDim,
    };

    const Left_Circle_Img_ImageDim_Bottom = {
        x: Left_Circle_Img_Dim_Bottom.cx - LeftImgDim / 2,
        y: Left_Circle_Img_Dim_Bottom.cy - LeftImgDim / 2,
        dim: LeftImgDim,
    };
    const Left_Circle_Img_ImageDim_Left = {
        x: Left_Circle_Img_Dim_Left.cx - LeftImgDim / 2,
        y: Left_Circle_Img_Dim_Left.cy - LeftImgDim / 2,
        dim: LeftImgDim,
    };
    const Left_Circle_Img_ImageDim_Right = {
        x: Left_Circle_Img_Dim_Right.cx - LeftImgDim / 2,
        y: Left_Circle_Img_Dim_Right.cy - LeftImgDim / 2,
        dim: LeftImgDim,
    };

    //Right circles
    const RightCirclesPadding = (SVGHeight - 4 * RightCircleImgRadius * 2) / 4;

    const Right_Circle_Img_Dim_1 = {
        cx: SvgSectionCenterRight.x + SvgSectionWidthRight / 2 - RightCircleImgRadius - AnimatedHallo * 4,
        cy: RightCirclesPadding / 2 + RightCircleImgRadius,
        r: RightCircleImgRadius,
    };
    const Right_Circle_Img_Dim_2 = {
        cx: SvgSectionCenterRight.x + SvgSectionWidthRight / 2 - RightCircleImgRadius - AnimatedHallo * 4,
        cy: RightCirclesPadding / 2 + RightCircleImgRadius + (2 * RightCircleImgRadius + RightCirclesPadding),
        r: RightCircleImgRadius,
    };
    const Right_Circle_Img_Dim_3 = {
        cx: SvgSectionCenterRight.x + SvgSectionWidthRight / 2 - RightCircleImgRadius - AnimatedHallo * 4,
        cy: RightCirclesPadding / 2 + RightCircleImgRadius + 2 * (2 * RightCircleImgRadius + RightCirclesPadding),
        r: RightCircleImgRadius,
    };
    const Right_Circle_Img_Dim_4 = {
        cx: SvgSectionCenterRight.x + SvgSectionWidthRight / 2 - RightCircleImgRadius - AnimatedHallo * 4,
        cy: RightCirclesPadding / 2 + RightCircleImgRadius + 3 * (2 * RightCircleImgRadius + RightCirclesPadding),
        r: RightCircleImgRadius,
    };

    let RightImgDim = (RightCircleImgRadius - RightCircleImgPaddingImage) * 2;
    const Right_Circle_Img_ImageDim_1 = {
        x: Right_Circle_Img_Dim_1.cx - RightImgDim / 2,
        y: Right_Circle_Img_Dim_1.cy - RightImgDim / 2,
        dim: RightImgDim,
    };
    const Right_Circle_Img_ImageDim_2 = {
        x: Right_Circle_Img_Dim_2.cx - RightImgDim / 2,
        y: Right_Circle_Img_Dim_2.cy - RightImgDim / 2,
        dim: RightImgDim,
    };
    const Right_Circle_Img_ImageDim_3 = {
        x: Right_Circle_Img_Dim_3.cx - RightImgDim / 2,
        y: Right_Circle_Img_Dim_3.cy - RightImgDim / 2,
        dim: RightImgDim,
    };
    const Right_Circle_Img_ImageDim_4 = {
        x: Right_Circle_Img_Dim_4.cx - RightImgDim / 2,
        y: Right_Circle_Img_Dim_4.cy - RightImgDim / 2,
        dim: RightImgDim,
    };

    //Junctions
    const JunctionPoint2 = {
        x: SvgSectionCenterMiddle.x + SvgSectionWidthMiddle / 2 - JunctionLinesRightPadding,
        y: SvgSectionCenterMiddle.y,
    };

    //Description Box Positionning and dimensions
    // DescriptionBoxPadding
    var visibilityDescOrCombination_Description;
    var LeftCircleDynamicColor;
    if (CombinationBoxVisibility.target1 === true || CombinationBoxVisibility.target2 === true || CombinationBoxVisibility.target3 === true || CombinationBoxVisibility.target4 === true) {
        visibilityDescOrCombination_Description = true;
        LeftCircleDynamicColor = LeftCircleColorWhenCombinedDesc;
    } else {
        visibilityDescOrCombination_Description = false;
        LeftCircleDynamicColor = "none";
    }

    const DescriptionBoxDim = {
        left: MiddleSectionDim.left + DescriptionBoxPadding.left + "px",
        width: MiddleSectionDim.width - DescriptionBoxPadding.left - DescriptionBoxPadding.right - JunctionLinesRightPadding + "px",
        opacity: MiddleSectionDim.left === null || MiddleSectionDim.width === 0 ? "none" : 1,
    };

    const FinalContentDescription = () => {
        if (Array.isArray(DescriptionContent)) {
            var i = 0;
            return DescriptionContent.map((elem) => {
                i = i + 1;
                return (
                    <li key={i}>
                        <img src="images/general/ArtibullesBulletWhite.svg" alt="companyLogo" className={classes.BulletLogo} />
                        <p>{elem}</p>
                    </li>
                );
            });
        } else {
            return null;
        }
    };

    const FinalCombinedBoxComponent = () => {
        var BoxItemList = [];
        for (var i = 1; i < 5; i++) {
            let target = "target" + i;
            let ButtColor = "buttonColor" + i;
            let bottomTitle = "bottom" + i;
            let NavTo = "buttonNavTo" + i;
            var ContentTitle;
            var ContentList = [];
            
            if (CombinedBoxContent) {
                if (Array.isArray(CombinedBoxContent) && CombinedBoxContent.length > 0) {
                    ContentTitle = CombinedBoxContent[i - 1].title;
                    if (Array.isArray(CombinedBoxContent[i - 1].list)) {
                        for (var j = 0; j < CombinedBoxContent[i - 1].list.length; j++) {
                            let liElem = <li key={`CombinedBoxListElem${i}_${j}`}>{CombinedBoxContent[i - 1].list[j]}</li>;
                            ContentList.push(liElem);
                        }
                    }
                }
            }

            let Component = (
                <div
                    key={`CombinedBoxElem_${i}`}
                    className={classes.CombinedActivities_Wrapper_Block}
                    style={{display: CombinationBoxVisibility[target] === false ? "none" : null, border: `2px solid ${CombinedBoxTitle[ButtColor]}`}}
                >
                    <div className={classes.CombinedActivities_Top}>
                        <div className={classes.CombinedActivities_Top_Title_Wrapper}>
                            <div className={classes.CombinedActivities_Top_Title_Top}>{CombinedBoxTitle.top}</div>
                            <div className={classes.CombinedActivities_Top_Title_Middle}>&</div>
                            <div className={classes.CombinedActivities_Top_Title_Bottom}>{CombinedBoxTitle[bottomTitle]}</div>
                        </div>
                        <div className={classes.CombinedActivities_Top_Close} onClick={HandleCloseCombinationBox}>
                            <CloseIcon
                                IconSize="25px"
                                SVGFillColor=""
                                SVGStrokeColor="black"
                                SVGStrokeHoverColor="black"
                                SVGStrokeWidth="25"
                                Frame="circle"
                                InnerPadding="45px"
                                FrameFillColor="white"
                                FrameStrokeColor="white"
                                FrameFillColorHover="white"
                                FrameStrokeColorHover="white"
                                //
                            />
                        </div>
                    </div>

                    <div className={classes.CombinedActivities_Content}>
                        <p className={classes.CombinedActivities_Content_Title}>{ContentTitle}</p>
                        <ul className={classes.CombinedActivities_Content_Description}>{ContentList}</ul>
                    </div>
                    <div className={classes.CombinedActivities_Footer}>
                        <Button
                            Width="450px"
                            Height="30px"
                            Background={CombinedBoxTitle[ButtColor]}
                            style={{margin: "0px"}}
                            ClassExtendButton={classes.ClassExtendButton_LearnMore}
                            onClick={() => HandleNavigateTo(CombinedBoxTitle[NavTo])}
                        >
                            {`Learn more about ${CombinedBoxTitle[NavTo]}`}
                        </Button>
                    </div>
                </div>
            );
            BoxItemList.push(Component);
        }
        return BoxItemList;
    };

    const HandlePictureMouseEnter = (position) => {
        if (position === "Top") {
            setTopSectionPicturesClassesHover({
                ...TopSectionPicturesClassesHover,
                ...{
                    top: classes.TopSection_CirclePictures_Group_Top_Hover,
                    left: classes.TopSection_CirclePictures_Group,
                    bottom: classes.TopSection_CirclePictures_Group,
                    right: classes.TopSection_CirclePictures_Group,
                },
            });
        } else if (position === "Bottom") {
            setTopSectionPicturesClassesHover({
                ...TopSectionPicturesClassesHover,
                ...{
                    top: classes.TopSection_CirclePictures_Group,
                    left: classes.TopSection_CirclePictures_Group,
                    bottom: classes.TopSection_CirclePictures_Group_Bottom_Hover,
                    right: classes.TopSection_CirclePictures_Group,
                },
            });
        } else if (position === "Left") {
            setTopSectionPicturesClassesHover({
                ...TopSectionPicturesClassesHover,
                ...{
                    top: classes.TopSection_CirclePictures_Group,
                    left: classes.TopSection_CirclePictures_Group_Left_Hover,
                    bottom: classes.TopSection_CirclePictures_Group,
                    right: classes.TopSection_CirclePictures_Group,
                },
            });
        } else if (position === "Right") {
            setTopSectionPicturesClassesHover({
                ...TopSectionPicturesClassesHover,
                ...{
                    top: classes.TopSection_CirclePictures_Group,
                    left: classes.TopSection_CirclePictures_Group,
                    bottom: classes.TopSection_CirclePictures_Group,
                    right: classes.TopSection_CirclePictures_Group_Right_Hover,
                },
            });
        }
    };
    const HandlePictureMouseLeave = (position) => {
        if (position === "Top") {
            setTopSectionPicturesClassesHover({
                ...TopSectionPicturesClassesHover,
                ...{
                    top: classes.TopSection_CirclePictures_Group,
                },
            });
        } else if (position === "Bottom") {
            setTopSectionPicturesClassesHover({...TopSectionPicturesClassesHover, ...{bottom: classes.TopSection_CirclePictures_Group}});
        } else if (position === "Left") {
            setTopSectionPicturesClassesHover({...TopSectionPicturesClassesHover, ...{left: classes.TopSection_CirclePictures_Group}});
        } else if (position === "Right") {
            setTopSectionPicturesClassesHover({...TopSectionPicturesClassesHover, ...{right: classes.TopSection_CirclePictures_Group}});
        }
    };

    return (
        <div className={classes.Summary_Wrapper}>
            <div className={classes.Summary_TitleWrapper}>
                <div className={classes.Summary_TitleIcon_Wrapper}>
                    <img className={classes.Summary_TitleIcon} src={TitleImgPath} alt="SummaryBoxTitleIcon" />
                </div>
                <div className={classes.Summary_Title_Wrapper}>
                    <h1 className={classes.Summary_Title}>{Title}</h1>
                </div>
                <div className={classes.Summary_TitleRightContent}>{TitleRightContent}</div>
            </div>
            <div className={classes.Summary_Content_Wrapper} ref={RefSummaryContentWrapper}>
                <div className={classes.Svg_Wrapper}>
                    <svg className={classes.Svg_Box} viewBox={`0 0 1000 ${SVGHeight}`}>
                        <defs>
                            <circle id="path-1" cx={Right_Circle_Img_Dim_1.cx} cy={Right_Circle_Img_Dim_1.cy} r={Right_Circle_Img_Dim_1.r} />
                            <circle id="path-2" cx={Right_Circle_Img_Dim_2.cx} cy={Right_Circle_Img_Dim_2.cy} r={Right_Circle_Img_Dim_2.r} />
                            <circle id="path-3" cx={Right_Circle_Img_Dim_3.cx} cy={Right_Circle_Img_Dim_3.cy} r={Right_Circle_Img_Dim_3.r} />
                            <circle id="path-4" cx={Right_Circle_Img_Dim_4.cx} cy={Right_Circle_Img_Dim_4.cy} r={Right_Circle_Img_Dim_4.r} />
                            <filter x="-22.1%" y="-22.1%" width="144.2%" height="144.2%" filterUnits="objectBoundingBox" id="hallo-filter">
                                <feMorphology radius={AnimatedHallo} operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
                                <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                                <feGaussianBlur stdDeviation="4.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                                <feColorMatrix values="0 0 0 0 0.305225317   0 0 0 0 0.305225317   0 0 0 0 0.305225317  0 0 0 1 0" type="matrix" in="shadowBlurOuter1" />
                            </filter>
                        </defs>
                        <g id="MiddleSection">
                            <g ref={RefMiddleSectionSVG}>
                                <rect x={SvgSectionWidthLeft} y="1" width={SvgSectionWidthMiddle - 2} height={SVGHeight - 2} stroke="none" strokeWidth="2" fill="none"></rect>
                            </g>
                            <polyline
                                id="Junction1"
                                style={{display: CombinationBoxVisibility.target1 === false ? "none" : null}}
                                points={`${SvgSectionWidthLeft - 2} ${SvgSectionCenterLeft.y} ${JunctionPoint2.x} ${JunctionPoint2.y} ${Right_Circle_Img_Dim_1.cx} ${Right_Circle_Img_Dim_1.cy}`}
                                stroke={JunctionColor1}
                                //
                                fill="none"
                                strokeWidth={JunctionLinesStrokeWidth}
                            ></polyline>
                            <polyline
                                id="Junction2"
                                style={{display: CombinationBoxVisibility.target2 === false ? "none" : null}}
                                points={`${SvgSectionWidthLeft - 2} ${SvgSectionCenterLeft.y} ${JunctionPoint2.x} ${JunctionPoint2.y} ${Right_Circle_Img_Dim_2.cx} ${Right_Circle_Img_Dim_2.cy}`}
                                stroke={JunctionColor2}
                                //
                                fill="none"
                                strokeWidth={JunctionLinesStrokeWidth}
                            ></polyline>
                            <polyline
                                id="Junction3"
                                style={{display: CombinationBoxVisibility.target3 === false ? "none" : null}}
                                points={`${SvgSectionWidthLeft - 2} ${SvgSectionCenterLeft.y} ${JunctionPoint2.x} ${JunctionPoint2.y} ${Right_Circle_Img_Dim_3.cx} ${Right_Circle_Img_Dim_3.cy}`}
                                stroke={JunctionColor3}
                                //
                                fill="none"
                                strokeWidth={JunctionLinesStrokeWidth}
                            ></polyline>
                            <polyline
                                id="Junction4"
                                style={{display: CombinationBoxVisibility.target4 === false ? "none" : null}}
                                points={`${SvgSectionWidthLeft - 2} ${SvgSectionCenterLeft.y} ${JunctionPoint2.x} ${JunctionPoint2.y} ${Right_Circle_Img_Dim_4.cx} ${Right_Circle_Img_Dim_4.cy}`}
                                stroke={JunctionColor4}
                                //
                                fill="none"
                                strokeWidth={JunctionLinesStrokeWidth}
                            ></polyline>
                        </g>
                        <g id="LeftSection">
                            <rect x="1" y="1" width={SvgSectionWidthLeft - 2} height={SVGHeight - 2} stroke="none" fill="none" strokeWidth="2"></rect>
                            <circle
                                id="SectionLeft_CircleWrapper"
                                cx={SvgSectionCenterLeft.x}
                                cy={SvgSectionCenterLeft.y}
                                r={LeftCircleWrapperRadius}
                                stroke={LeftCircleExternalColor}
                                strokeWidth={LeftCircleWrapperStrokeWidth}
                                //
                                fill={LeftCircleDynamicColor}
                            ></circle>
                            <circle
                                id="SectionLeft_Circle_Inner"
                                cx={SvgSectionCenterLeft.x}
                                cy={SvgSectionCenterLeft.y}
                                r={LeftCircleInnerRadius}
                                stroke={LeftCircleInternalColor}
                                strokeWidth={LeftCircleInnerStokeWidth}
                                //
                                fill="none"
                            ></circle>
                            <image
                                id="SectionLeft_Central_Logo"
                                x={LeftCentralLogoDim.x}
                                y={LeftCentralLogoDim.y}
                                width={LeftCentralLogoDim.dim}
                                height={LeftCentralLogoDim.dim}
                                xlinkHref={LeftCentralIconPath}
                            />
                            <g
                                className={TopSectionPicturesClassesHover.top}
                                style={{transformOrigin: `${Left_Circle_Img_Dim_Top.cx}px ${Left_Circle_Img_Dim_Top.cy}px 0px`}}
                                onMouseEnter={() => HandlePictureMouseEnter("Top")}
                                onMouseLeave={() => HandlePictureMouseLeave("Top")}
                            >
                                <circle
                                    id="SectionLeft_Circle_Img_Top"
                                    cx={Left_Circle_Img_Dim_Top.cx}
                                    cy={Left_Circle_Img_Dim_Top.cy}
                                    r={Left_Circle_Img_Dim_Top.r}
                                    stroke="none"
                                    //
                                    fill={LeftCircleImgColor}
                                ></circle>
                                <image
                                    id="SectionLeft_Circle_Img_ImageTop"
                                    x={Left_Circle_Img_ImageDim_Top.x}
                                    y={Left_Circle_Img_ImageDim_Top.y}
                                    width={Left_Circle_Img_ImageDim_Top.dim}
                                    height={Left_Circle_Img_ImageDim_Top.dim}
                                    xlinkHref={SectionLeftImagesPath.top}
                                    //
                                />
                            </g>
                            <g
                                className={TopSectionPicturesClassesHover.bottom}
                                style={{transformOrigin: `${Left_Circle_Img_Dim_Bottom.cx}px ${Left_Circle_Img_Dim_Bottom.cy}px 0px`}}
                                onMouseEnter={() => HandlePictureMouseEnter("Bottom")}
                                onMouseLeave={() => HandlePictureMouseLeave("Bottom")}
                            >
                                <circle
                                    id="SectionLeft_Circle_Img_Bottom"
                                    cx={Left_Circle_Img_Dim_Bottom.cx}
                                    cy={Left_Circle_Img_Dim_Bottom.cy}
                                    r={Left_Circle_Img_Dim_Bottom.r}
                                    stroke="none"
                                    //
                                    fill={LeftCircleImgColor}
                                ></circle>

                                <image
                                    id="SectionLeft_Circle_Img_ImageBottom"
                                    x={Left_Circle_Img_ImageDim_Bottom.x}
                                    y={Left_Circle_Img_ImageDim_Bottom.y}
                                    width={Left_Circle_Img_ImageDim_Bottom.dim}
                                    height={Left_Circle_Img_ImageDim_Bottom.dim}
                                    xlinkHref={SectionLeftImagesPath.bottom}
                                    //
                                />
                            </g>
                            <g
                                className={TopSectionPicturesClassesHover.left}
                                style={{transformOrigin: `${Left_Circle_Img_Dim_Left.cx}px ${Left_Circle_Img_Dim_Left.cy}px 0px`}}
                                onMouseEnter={() => HandlePictureMouseEnter("Left")}
                                onMouseLeave={() => HandlePictureMouseLeave("Left")}
                            >
                                <circle
                                    id="SectionLeft_Circle_Img_Left"
                                    cx={Left_Circle_Img_Dim_Left.cx}
                                    cy={Left_Circle_Img_Dim_Left.cy}
                                    r={Left_Circle_Img_Dim_Left.r}
                                    stroke="none"
                                    //
                                    fill={LeftCircleImgColor}
                                ></circle>
                                <image
                                    id="SectionLeft_Circle_Img_ImageLeft"
                                    x={Left_Circle_Img_ImageDim_Left.x}
                                    y={Left_Circle_Img_ImageDim_Left.y}
                                    width={Left_Circle_Img_ImageDim_Left.dim}
                                    height={Left_Circle_Img_ImageDim_Left.dim}
                                    xlinkHref={SectionLeftImagesPath.left}
                                    //
                                />
                            </g>
                            <g
                                className={TopSectionPicturesClassesHover.right}
                                style={{transformOrigin: `${Left_Circle_Img_Dim_Right.cx}px ${Left_Circle_Img_Dim_Right.cy}px 0px`}}
                                onMouseEnter={() => HandlePictureMouseEnter("Right")}
                                onMouseLeave={() => HandlePictureMouseLeave("Right")}
                            >
                                <circle
                                    id="SectionLeft_Circle_Img_Right"
                                    cx={Left_Circle_Img_Dim_Right.cx}
                                    cy={Left_Circle_Img_Dim_Right.cy}
                                    r={Left_Circle_Img_Dim_Right.r}
                                    stroke="none"
                                    //
                                    fill={LeftCircleImgColor}
                                ></circle>
                                <image
                                    id="SectionLeft_Circle_Img_ImageRight"
                                    x={Left_Circle_Img_ImageDim_Right.x}
                                    y={Left_Circle_Img_ImageDim_Right.y}
                                    width={Left_Circle_Img_ImageDim_Right.dim}
                                    height={Left_Circle_Img_ImageDim_Right.dim}
                                    xlinkHref={SectionLeftImagesPath.right}
                                    //
                                />
                            </g>
                        </g>

                        <g id="RightSection">
                            <rect x={SvgSectionWidthLeft + SvgSectionWidthMiddle} y="1" width={SvgSectionWidthRight - 2} height={SVGHeight - 2} stroke="none" fill="none" strokeWidth="2"></rect>

                            <g id="groupRight1" onClick={() => HandleCircleLeftClick("target1")}>
                                <circle
                                    id="SectionRight_Circle_Img_1"
                                    cx={Right_Circle_Img_Dim_1.cx}
                                    cy={Right_Circle_Img_Dim_1.cy}
                                    r={Right_Circle_Img_Dim_1.r}
                                    stroke="none"
                                    fill={RigthCircleColor1}

                                    //
                                />
                                <g id="RightCircle1Shadow">
                                    <use id="SectionRight_Circle_Img_Use11" fill="black" fillOpacity="1" filter="url(#hallo-filter)" xlinkHref="#path-1" />
                                    <use id="SectionRight_Circle_Img_Use12" fill="black" fillRule="evenodd" xlinkHref="#path-1" />
                                </g>

                                <image
                                    id="SectionRight_Circle_Img_Image1"
                                    x={Right_Circle_Img_ImageDim_1.x}
                                    y={Right_Circle_Img_ImageDim_1.y}
                                    width={Right_Circle_Img_ImageDim_1.dim}
                                    height={Right_Circle_Img_ImageDim_1.dim}
                                    xlinkHref={RigthImgPath1}
                                    //
                                />
                            </g>
                            <g id="groupRight2" onClick={() => HandleCircleLeftClick("target2")}>
                                <circle
                                    id="SectionRight_Circle_Img_2"
                                    cx={Right_Circle_Img_Dim_2.cx}
                                    cy={Right_Circle_Img_Dim_2.cy}
                                    r={Right_Circle_Img_Dim_2.r}
                                    //
                                    stroke="none"
                                    fill={RigthCircleColor2}
                                />
                                <g id="RightCircle2Shadow">
                                    <use id="SectionRight_Circle_Img_Use21" fill="black" fillOpacity="1" filter="url(#hallo-filter)" xlinkHref="#path-2" />
                                    <use id="SectionRight_Circle_Img_Use22" fill="black" fillRule="evenodd" xlinkHref="#path-2" />
                                </g>
                                <image
                                    id="SectionRight_Circle_Img_Image2"
                                    x={Right_Circle_Img_ImageDim_2.x}
                                    y={Right_Circle_Img_ImageDim_2.y}
                                    width={Right_Circle_Img_ImageDim_2.dim}
                                    height={Right_Circle_Img_ImageDim_2.dim}
                                    xlinkHref={RigthImgPath2}
                                    //
                                />
                            </g>
                            <g id="groupRight3" onClick={() => HandleCircleLeftClick("target3")}>
                                <circle
                                    id="SectionRight_Circle_Img_3"
                                    cx={Right_Circle_Img_Dim_3.cx}
                                    cy={Right_Circle_Img_Dim_3.cy}
                                    r={Right_Circle_Img_Dim_3.r}
                                    stroke="none"
                                    //
                                    fill={RigthCircleColor3}
                                />
                                <g id="RightCircle3Shadow">
                                    <use id="SectionRight_Circle_Img_Use31" fill="black" fillOpacity="1" filter="url(#hallo-filter)" xlinkHref="#path-3" />
                                    <use id="SectionRight_Circle_Img_Use32" fill="black" fillRule="evenodd" xlinkHref="#path-3" />
                                </g>
                                <image
                                    id="SectionRight_Circle_Img_Image3"
                                    x={Right_Circle_Img_ImageDim_3.x}
                                    y={Right_Circle_Img_ImageDim_3.y}
                                    width={Right_Circle_Img_ImageDim_3.dim}
                                    height={Right_Circle_Img_ImageDim_3.dim}
                                    xlinkHref={RigthImgPath3}
                                    //
                                />
                            </g>
                            <g id="groupRight4" onClick={() => HandleCircleLeftClick("target4")}>
                                <circle
                                    id="SectionRight_Circle_Img_4"
                                    cx={Right_Circle_Img_Dim_4.cx}
                                    cy={Right_Circle_Img_Dim_4.cy}
                                    r={Right_Circle_Img_Dim_4.r}
                                    stroke="none"
                                    fill={RigthCircleColor4}

                                    //
                                />
                                <g id="RightCircle4Shadow">
                                    <use id="SectionRight_Circle_Img_Use41" fill="black" fillOpacity="1" filter="url(#hallo-filter)" xlinkHref="#path-4" />
                                    <use id="SectionRight_Circle_Img_Use42" fill="black" fillRule="evenodd" xlinkHref="#path-4" />
                                </g>
                                <image
                                    id="SectionRight_Circle_Img_Image4"
                                    x={Right_Circle_Img_ImageDim_4.x}
                                    y={Right_Circle_Img_ImageDim_4.y}
                                    width={Right_Circle_Img_ImageDim_4.dim}
                                    height={Right_Circle_Img_ImageDim_4.dim}
                                    xlinkHref={RigthImgPath4}
                                    //
                                />
                            </g>
                        </g>
                    </svg>
                </div>

                <div className={classes.Summary_Content_Description_Wrapper} style={{opacity: DescriptionBoxDim.opacity, left: DescriptionBoxDim.left, width: DescriptionBoxDim.width}}>
                    <div className={classes.Summary_Content_Description_List_Wrapper} style={{display: visibilityDescOrCombination_Description === true ? "none" : null}}>
                        <ul className={classes.Summary_Content_Description_List}>{FinalContentDescription()}</ul>
                    </div>
                    <div ref={RefCombinedBoxWrapper} className={classes.CombinedActivities_Wrapper} style={{display: visibilityDescOrCombination_Description === false ? "none" : null}}>
                        {FinalCombinedBoxComponent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PagesBoxDescription;
