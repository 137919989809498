import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useMatomo} from "@datapunt/matomo-tracker-react";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Terms: {
        margin: "0 auto",
        maxWidth: "800px",
	},
	PageTitle : {
		margin : "0 auto",
		textAlign : "center",
		

	}
}));

const TermsAndConditions = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
	const {trackPageView} = useMatomo();
    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
 //Matomo Tracking
 React.useEffect(() => {
	trackPageView({
		documentTitle: "TermsAndConditions_Desktop",
	});
}, [trackPageView]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
		<div className={classes.Terms}>
		<h1 className={classes.PageTitle}>Terms and conditions</h1>
            
            <p>Not applicable</p>
        </div>
    );

    /***************** RENDER ******************/
};

export default TermsAndConditions;
