exports.TopSection = {
    English: {
		VisitSiteButton: "Visit Artibulles-DC dedicated Website",
		VisitSiteButtonMobile : "Visit Artibulles-DC Website",
        Description: ["Communication strategy", "Branding & Visual Identity", "Graphic Design", "Presentations"],
        CombinedBoxContent: [
            {
                combineWith: "ES",
                title: "A scientific and Engineering knowledge provides support to:",
                list: ["Create complex and unique realistic 3d renders and animations"],
            },
            {
                combineWith: "ISO",
                title: "Innovation and operational processes are an important part of the Digital Communication development process:",
                list: [
                    "Generate unique ideas, concepts, graphics",
                    "Establish robust processes to capture the customer needs, identity, messages",
                    "Create unique marketing positioning to increase differentiation against competition",
                ],
            },
            {
                combineWith: "CIS",
                title: "Custom IT Solutions helps to create: ",
                list: ["Efficient digital marketing campaign", "Capture Customer requirements and needs through standardized electronic forms"],
            },
            {
                combineWith: "PM",
                title: "Project Management expertise helps to keep things on track:",
                list: ["In a very creative environment, it is easy to lose track of time, cost and commitments, PM helps to keep customers happy"],
            },
        ],
    },
    French: {},
};
