import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {CloseIcon} from "@artibulles-cis/react-icons";
import Button from "@artibulles-cis/react/Button";
import useWebsiteAppInitContext from "@artibulles-cis/react/utils/context/websiteAppInitContext/useWebsiteAppInitContext";
import {useHistory} from "react-router-dom";
//Defining the parameters for automated styling
const SummaryBoxMaxWidth = 1300;

const SVGBoxPadding = 0;

const SummaryBoxRadius = "10px";
// const SummaryBoxBackgroundColor = "hsla(0,0%,100%,.6)";
const SummaryBoxBackgroundColor = "rgba(44, 44, 44,0.9)";

// const SummaryBoxTitleBackgroundColor = "hsla(0,0%,100%,.45)";
const SummaryBoxTitleBackgroundColor = "black";

const styles = createUseStyles((theme) => ({
    Summary_Wrapper: {
        display: "flex",
        maxWidth: SummaryBoxMaxWidth + "px",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: SummaryBoxRadius,
        backgroundColor: SummaryBoxBackgroundColor,
    },
    Summary_TitleWrapper: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        borderTopLeftRadius: SummaryBoxRadius,
        borderTopRightRadius: SummaryBoxRadius,
        padding: "8px 8px 8px 8px",
        backgroundColor: SummaryBoxTitleBackgroundColor,
        alignItems: "center",
        boxSizing: "border-box",
        color: "white",
    },
    Summary_TitleIcon_Wrapper: {
        flex: "0 0 50px",
        display: "flex",
        alignItems: "center",
    },
    Summary_TitleIcon: {
        height: "50px",
        width: "50px",
    },
    Summary_Title_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        // overflowWrap: "break-word",
        // minWidth : "100%"
        padding: "0px 8px 0px 8px",
        boxSizing: "border-box",
    },
    Summary_Title: {
        color: "white",
        margin: "0px",
        fontSize: "1.1rem",
        fontWeight: "700",
        // whiteSpace :"pre-wrap",
        // wordWrap : "break-word",
        // wordBreak : "break-word"
    },
    Summary_TitleRightContent: {
        flex: "0 0 350px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },

    Summary_Content_Wrapper: {
        display: "flex",
        position: "relative",
        margin: "0",
        padding: "0",
        justifyContent: "space-around",
        alignItems: "center",
        boxSizing: "border-box",
        flexDirection: "column",
    },
    Svg_Wrapper: {
        // position: "absolute",
        display: "flex",
        // border : "1px solid white",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: SVGBoxPadding + "px",
        boxSizing: "border-box",
        // height: "400px",
        flex: `1 1 auto`,
    },
    Svg_Box: {
        width: "100%",
        // maxWidth : "60px",
        height: "100%",
    },
    Junction1: {
        display: "none",
    },

    Junction2: {
        display: "none",
    },
    Junction3: {
        display: "none",
    },
    Junction4: {
        display: "none",
    },

    Summary_Content_Description_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",

        transition: "opacity .2s",
        width: "100%",
    },

    Summary_Content_Description_List_Wrapper: {
        width: "100%",
        padding: "0px 10px 0px 10px",
        boxSizing: "border-box",
    },
    Summary_Content_Description_List: {
        margin: "0px",
        padding: "0px",
        width: "100%",
        listStyle: "none",
        fontSize: "1em",
        "& li": {
            display: "flex",
            alignContent: "flex-start",
            alignItems: "flex-start",
            padding: "5px 0px",
            margin: "0px",
        },
        "& p": {
            margin: "0px",
            lineHeight: "25px",
        },
    },
    BulletLogo: {
        height: "20px",
        width: "auto",
        margin: "3px 10px 0px 0px",
        fill: "white",
        stroke: "white",
    },
    CombinedActivities_Wrapper: {
        display: "flex",
        width: "90%",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        background: "black",
        color: "white",
        borderRadius: "10px",
    },
    CombinedActivities_Wrapper_Block: {
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        background: "black",
        borderRadius: "10px",
    },

    CombinedActivities_Top: {
        flex: "0 0 85px",
        width: "100%",
        display: "flex",
        position: "relative",
        // alignItems: "center",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        borderBottom: "1px solid white",
    },

    CombinedActivities_Top_Title_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "10px 10px 10px 10px",
        fontFamily: "Roboto",
        fontWeight: "700",
        fontSize: "1rem",
    },

    CombinedActivities_Top_Title_Top: {
        textAlign: "left",
        lineHeight: "1rem",
    },
    CombinedActivities_Top_Title_Middle: {
        textAlign: "center",
        lineHeight: "1rem",
        margin: "4px 0px 4px 0px",
    },
    CombinedActivities_Top_Title_Bottom: {
        textAlign: "right",
        lineHeight: "1rem",
    },
    CombinedActivities_Top_Close: {
        flex: "0 0 40px",
        display: "flex",
        position: "absolute",
        right: "0px",
        top: "0px",
        alignItems: "flex-start",
        justifyContent: "center",
        boxSizing: "border-box",
        padding: "5px 5px 0px 0px",
    },
    CombinedActivities_Content: {
        flex: "1 1 auto",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        // justifyContent : "center",
        boxSizing: "border-box",
        padding: "10px 20px 10px 20px",
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "0.9rem",
    },
    CombinedActivities_Content_Title: {
        margin: "0px",
    },
    CombinedActivities_Content_Description: {
        margin: "5px 0px",
        "& li": {
            margin: "0px",
        },
    },

    CombinedActivities_Footer: {
        flex: "0 0 50px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "10px 20px 10px 20px",
        justifyContent: "center",
    },
    ClassExtendButton_LearnMore: {
        textTransform: "uppercase",
    },

    TopSection_CirclePictures_Group: {
        transition: `all 0.5s`,
    },
    TopSection_CirclePictures_Group_Top_Hover: {
        transform: "scale(1.5)",
        transition: `all 0.5s`,
    },
    TopSection_CirclePictures_Group_Left_Hover: {
        transform: "scale(1.5)",
        transition: `all 0.5s`,
    },
    TopSection_CirclePictures_Group_Bottom_Hover: {
        transform: "scale(1.5)",
        transition: `all 0.5s`,
    },
    TopSection_CirclePictures_Group_Right_Hover: {
        transform: "scale(1.5)",
        transition: `all 0.5s`,
    },
}));

const PagesBoxDescription = (props) => {
    const {
        RefBusiness,
        TitleImgPath,
        Title,
        BottomButtonContent,
        SectionLeftImagesPath,
        DescriptionContent,
        CombinedBoxContent,
        //Dont fold
    } = props;
    const {websiteAppInitContextState} = useWebsiteAppInitContext();
    
    const windowWidth = websiteAppInitContextState.clientWindowDimensions.windowWidth;

    const theme = useTheme();
	const classes = styles({...props, theme});
	const {trackEvent} = useMatomo();
    const History = useHistory();
    
    const RefSummaryContentWrapper = useRef(null);
    const RefCombinedBoxWrapper = useRef(null);

    
    const [CombinationBoxVisibility, setCombinationBoxVisibility] = useState({
        target1: false,
        target2: false,
        target3: false,
        target4: false,
    });

   

    

    //Recalculating the position of the middle section on resize
    

    //Handle Click outside
    const HandleClickOutside = (e) => {
        if (RefCombinedBoxWrapper.current.contains(e.target)) {
        } else {
            if (e.target.id.includes("SectionRight_Circle_Img")) {
            } else {
                let UpdatedState = {
                    target1: false,
                    target2: false,
                    target3: false,
                    target4: false,
                };
                setCombinationBoxVisibility(UpdatedState);
            }
        }
    };
    //Adding Event Listeners for Click Outside
    useEffect(() => {
        if (CombinationBoxVisibility.target1 === true || CombinationBoxVisibility.target2 === true || CombinationBoxVisibility.target3 === true || CombinationBoxVisibility.target4 === true) {
            document.addEventListener("mousedown", HandleClickOutside);
        } else {
            document.removeEventListener("mousedown", HandleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", HandleClickOutside);
        };
    }, [CombinationBoxVisibility]);

    const HandleCloseCombinationBox = () => {
        let UpdatedState = {
            target1: false,
            target2: false,
            target3: false,
            target4: false,
        };
        setCombinationBoxVisibility(UpdatedState);
    };
    const HandleCircleLeftClick = (target) => {
		trackEvent({category: `${Title}_Page_Mobile`, action: `CombinationBoxShow : ${target}`});
        let UpdatedState = {
            target1: false,
            target2: false,
            target3: false,
            target4: false,
        };

        setCombinationBoxVisibility({...UpdatedState, ...{[target]: true}});
    };

    const HandleNavigateTo = (target) => {
        History.push(`/${target}`);
    };

    /*********** KEY SVG PARAMETERS ************/

    //Top Section
    const SVGTopPaddingH = 10;
    const SVGTopPaddingV = 5;
    const SVGTopViewBoxWidth = windowWidth - 16; //Padding if the box;
    const TopCircleRadius = (SVGTopViewBoxWidth - 5 * SVGTopPaddingH) / 8;
    const SVGTopViewBoxHeight = (TopCircleRadius + SVGTopPaddingV) * 2;
    const TopCircleImgPaddingImage = 4; //Internal Image Padding

    const TopCircle1 = {
        cx: SVGTopPaddingH + TopCircleRadius,
        cy: SVGTopViewBoxHeight / 2,
        r: TopCircleRadius,
    };
    const TopCircle2 = {
        cx: SVGTopPaddingH + TopCircleRadius + 2 * TopCircleRadius + SVGTopPaddingH,
        cy: SVGTopViewBoxHeight / 2,
        r: TopCircleRadius,
    };
    const TopCircle3 = {
        cx: SVGTopPaddingH + TopCircleRadius + 2 * (2 * TopCircleRadius + SVGTopPaddingH),
        cy: SVGTopViewBoxHeight / 2,
        r: TopCircleRadius,
    };
    const TopCircle4 = {
        cx: SVGTopPaddingH + TopCircleRadius + 3 * (2 * TopCircleRadius + SVGTopPaddingH),
        cy: SVGTopViewBoxHeight / 2,
        r: TopCircleRadius,
    };

    let TopImgDim = (TopCircleRadius - TopCircleImgPaddingImage) * 2;

    const TopImg1 = {
        x: TopCircle1.cx - TopImgDim / 2,
        y: TopCircle1.cy - TopImgDim / 2,
        dim: TopImgDim,
    };

    const TopImg2 = {
        x: TopCircle2.cx - TopImgDim / 2,
        y: TopCircle2.cy - TopImgDim / 2,
        dim: TopImgDim,
    };
    const TopImg3 = {
        x: TopCircle3.cx - TopImgDim / 2,
        y: TopCircle3.cy - TopImgDim / 2,
        dim: TopImgDim,
    };
    const TopImg4 = {
        x: TopCircle4.cx - TopImgDim / 2,
        y: TopCircle4.cy - TopImgDim / 2,
        dim: TopImgDim,
    };

    //Bottom SVG
    const AnimatedHallo = 2.5;
    const SVGBottomPaddingH = 5;
    const SVGBottomPaddingV = 5;
    const SVGBottomPaddingHReal = SVGBottomPaddingH + 4 * AnimatedHallo;
    const SVGBottomPaddingVReal = SVGBottomPaddingV + 4 * AnimatedHallo;

    const SVGBottomViewBoxWidth = windowWidth - 16; //Padding if the box;
    const BottomCircleRadius = (SVGBottomViewBoxWidth - 5 * SVGBottomPaddingHReal) / 8;

    const SVGBottomViewBoxHeight = (BottomCircleRadius + SVGBottomPaddingVReal) * 2;
    const BottomCircleImgPaddingImage = 5; //Internal Image Padding

    const BottomCircle1 = {
        cx: SVGBottomPaddingHReal + BottomCircleRadius,
        cy: SVGBottomViewBoxHeight / 2,
        r: BottomCircleRadius,
    };
    const BottomCircle2 = {
        cx: SVGBottomPaddingHReal + BottomCircleRadius + 2 * BottomCircleRadius + SVGBottomPaddingHReal,
        cy: SVGBottomViewBoxHeight / 2,
        r: BottomCircleRadius,
    };
    const BottomCircle3 = {
        cx: SVGBottomPaddingHReal + BottomCircleRadius + 2 * (2 * BottomCircleRadius + SVGBottomPaddingHReal),
        cy: SVGBottomViewBoxHeight / 2,
        r: BottomCircleRadius,
    };
    const BottomCircle4 = {
        cx: SVGBottomPaddingHReal + BottomCircleRadius + 3 * (2 * BottomCircleRadius + SVGBottomPaddingHReal),
        cy: SVGBottomViewBoxHeight / 2,
        r: BottomCircleRadius,
    };

    let BottomImgDim = (BottomCircleRadius * Math.cos(Math.PI / 4) - BottomCircleImgPaddingImage) * 2;

    const BottomImg1 = {
        x: BottomCircle1.cx - BottomImgDim / 2,
        y: BottomCircle1.cy - BottomImgDim / 2,
        dim: BottomImgDim,
    };
    const BottomImg2 = {
        x: BottomCircle2.cx - BottomImgDim / 2,
        y: BottomCircle2.cy - BottomImgDim / 2,
        dim: BottomImgDim,
    };
    const BottomImg3 = {
        x: BottomCircle3.cx - BottomImgDim / 2,
        y: BottomCircle3.cy - BottomImgDim / 2,
        dim: BottomImgDim,
    };
    const BottomImg4 = {
        x: BottomCircle4.cx - BottomImgDim / 2,
        y: BottomCircle4.cy - BottomImgDim / 2,
        dim: BottomImgDim,
    };

    const BusinessTitleES = "Engineering Solutions";
    const BusinessTitleISO = "Innovation, Strategy, Operational Support";
    const BusinessTitleDC = "Digital Communication";
    const BusinessTitleCIS = "Custom IT Solutions";
    const BusinessTitlePM = "Project Management";
    /*********** KEY SVG PARAMETERS ************/

    /*********** COLORS DEFINIITION ************/
    const ColorOrange = "#FF6103";
    const ColorPink = "#ED2D92";
    const ColorGreen = "#6DBE45";
    const ColorBlue = "#4997D2";
    const ClorYellow = "#FCB415";
    const ColorES = ColorOrange;
    const ColorDC = ColorPink;
    const ColorCIS = ColorBlue;
    const ColorISO = ColorGreen;
    const ColorPM = ClorYellow;

    // const ColorESRight = ColorOrange;
    // const ColorDCRight = ColorPink;
    // const ColorCISRight = ColorBlue;
    // const ColorISORight = ColorGreen;
    // const ColorPMRight = ClorYellow;

    const ColorESRight = "black";
    const ColorDCRight = "black";
    const ColorCISRight = "black";
    const ColorISORight = "black";
    const ColorPMRight = "black";

    // const LeftColorImageWrapper = SummaryBoxBackgroundColor
    const LeftColorImageWrapper = "rgba(30, 30, 30,1)";

    /*********** COLORS DEFINIITION ************/

    /*********** LOGOS SOURCE ************/
    const logoPathES = "/images/icons/Artibulles_logo_ES_black_fontexp.svg";
    const logoPathISO = "/images/icons/Artibulles_logo_ISO_black_fontexp.svg";
    const logoPathDC = "/images/icons/Artibulles_logo_DC_black_fontexp.svg";
    const logoPathCIS = "/images/icons/Artibulles_logo_CIS_black_fontexp.svg";
    const logoPathPM = "/images/icons/Artibulles_logo_PM_black_fontexp.svg";

    /*********** LOGOS SOURCE ************/

    /*********** Defining colors and images sources ************/
    // ES->ISO->DC->CIS->PM

    
    var LeftCircleImgColor;
    
    
    var CombinedBoxTitle;
    var RigthCircleColor1, RigthCircleColor2, RigthCircleColor3, RigthCircleColor4;
    var RigthImgPath1, RigthImgPath2, RigthImgPath3, RigthImgPath4;
    if (RefBusiness === "ES") {
        CombinedBoxTitle = {
            top: BusinessTitleES,
            bottom1: BusinessTitleISO,
            bottom2: BusinessTitleDC,
            bottom3: BusinessTitleCIS,
            bottom4: BusinessTitlePM,
            buttonColor1: ColorISO,
            buttonColor2: ColorDC,
            buttonColor3: ColorCIS,
            buttonColor4: ColorPM,
            buttonNavTo1: "iso",
            buttonNavTo2: "dc",
            buttonNavTo3: "cis",
            buttonNavTo4: "pm",
        };
        
        LeftCircleImgColor = LeftColorImageWrapper;
        
        
        
        RigthImgPath1 = logoPathISO;
        RigthImgPath2 = logoPathDC;
        RigthImgPath3 = logoPathCIS;
        RigthImgPath4 = logoPathPM;
        RigthCircleColor1 = ColorISORight;
        RigthCircleColor2 = ColorDCRight;
        RigthCircleColor3 = ColorCISRight;
        RigthCircleColor4 = ColorPMRight;
    } else if (RefBusiness === "ISO") {
        
        LeftCircleImgColor = LeftColorImageWrapper;
        
        
        
        RigthCircleColor1 = ColorESRight;
        RigthCircleColor2 = ColorDCRight;
        RigthCircleColor3 = ColorCISRight;
        RigthCircleColor4 = ColorPMRight;
        RigthImgPath1 = logoPathES;
        RigthImgPath2 = logoPathDC;
        RigthImgPath3 = logoPathCIS;
        RigthImgPath4 = logoPathPM;
        CombinedBoxTitle = {
            top: BusinessTitleISO,
            bottom1: BusinessTitleES,
            bottom2: BusinessTitleDC,
            bottom3: BusinessTitleCIS,
            bottom4: BusinessTitlePM,
            buttonColor1: ColorES,
            buttonColor2: ColorDC,
            buttonColor3: ColorCIS,
            buttonColor4: ColorPM,
            buttonNavTo1: "es",
            buttonNavTo2: "dc",
            buttonNavTo3: "cis",
            buttonNavTo4: "pm",
        };
    } else if (RefBusiness === "DC") {
        CombinedBoxTitle = {
            top: BusinessTitleDC,
            bottom1: BusinessTitleES,
            bottom2: BusinessTitleISO,
            bottom3: BusinessTitleCIS,
            bottom4: BusinessTitlePM,
            buttonColor1: ColorES,
            buttonColor2: ColorISO,
            buttonColor3: ColorCIS,
            buttonColor4: ColorPM,
            buttonNavTo1: "es",
            buttonNavTo2: "iso",
            buttonNavTo3: "cis",
            buttonNavTo4: "pm",
        };
        
        LeftCircleImgColor = LeftColorImageWrapper;
        
        
        
        RigthImgPath1 = logoPathES;
        RigthImgPath2 = logoPathISO;
        RigthImgPath3 = logoPathCIS;
        RigthImgPath4 = logoPathPM;
        RigthCircleColor1 = ColorESRight;
        RigthCircleColor2 = ColorISORight;
        RigthCircleColor3 = ColorCISRight;
        RigthCircleColor4 = ColorPMRight;
    } else if (RefBusiness === "CIS") {
        CombinedBoxTitle = {
            top: BusinessTitleCIS,
            bottom1: BusinessTitleES,
            bottom2: BusinessTitleISO,
            bottom3: BusinessTitleDC,
            bottom4: BusinessTitlePM,
            buttonColor1: ColorES,
            buttonColor2: ColorISO,
            buttonColor3: ColorDC,
            buttonColor4: ColorPM,
            buttonNavTo1: "es",
            buttonNavTo2: "iso",
            buttonNavTo3: "dc",
            buttonNavTo4: "pm",
        };
        
        LeftCircleImgColor = LeftColorImageWrapper;
        
        
        
        RigthImgPath1 = logoPathES;
        RigthImgPath2 = logoPathISO;
        RigthImgPath3 = logoPathDC;
        RigthImgPath4 = logoPathPM;
        RigthCircleColor1 = ColorESRight;
        RigthCircleColor2 = ColorISORight;
        RigthCircleColor3 = ColorDCRight;
        RigthCircleColor4 = ColorPMRight;
    } else if (RefBusiness === "PM") {
        CombinedBoxTitle = {
            top: BusinessTitlePM,
            bottom1: BusinessTitleES,
            bottom2: BusinessTitleISO,
            bottom3: BusinessTitleDC,
            bottom4: BusinessTitleCIS,
            buttonColor1: ColorES,
            buttonColor2: ColorISO,
            buttonColor3: ColorDC,
            buttonColor4: ColorCIS,
            buttonNavTo1: "es",
            buttonNavTo2: "iso",
            buttonNavTo3: "dc",
            buttonNavTo4: "cis",
        };
        
        LeftCircleImgColor = LeftColorImageWrapper;
        
        
        
        RigthImgPath1 = logoPathES;
        RigthImgPath2 = logoPathISO;
        RigthImgPath3 = logoPathDC;
        RigthImgPath4 = logoPathCIS;
        RigthCircleColor1 = ColorESRight;
        RigthCircleColor2 = ColorISORight;
        RigthCircleColor3 = ColorDCRight;
        RigthCircleColor4 = ColorCISRight;
    }

    //

    //Description Box Positionning and dimensions
    // DescriptionBoxPadding
    var visibilityDescOrCombination_Description;

    if (CombinationBoxVisibility.target1 === true || CombinationBoxVisibility.target2 === true || CombinationBoxVisibility.target3 === true || CombinationBoxVisibility.target4 === true) {
        visibilityDescOrCombination_Description = true;
    } else {
        visibilityDescOrCombination_Description = false;
    }

    const DescriptionBoxDim = {
        left: 1,
        width: 1,
        opacity: 1,
        // left: MiddleSectionDim.left + DescriptionBoxPadding.left + "px",
        // width: MiddleSectionDim.width - DescriptionBoxPadding.left - DescriptionBoxPadding.right - JunctionLinesRightPadding + "px",
        // opacity: MiddleSectionDim.left === null || MiddleSectionDim.width === 0 ? "none" : 1,
    };

    const FinalContentDescription = () => {
        if (Array.isArray(DescriptionContent)) {
            var i = 0;
            return DescriptionContent.map((elem) => {
                i = i + 1;
                return (
                    <li key={i}>
                        <img src="images/general/ArtibullesBulletWhite.svg" alt="companyLogo" className={classes.BulletLogo} />
                        <p>{elem}</p>
                    </li>
                );
            });
        } else {
            return null;
        }
    };

    const FinalCombinedBoxComponent = () => {
        var BoxItemList = [];
        for (var i = 1; i < 5; i++) {
            let target = "target" + i;
            let ButtColor = "buttonColor" + i;
            let bottomTitle = "bottom" + i;
            let NavTo = "buttonNavTo" + i;
            var ContentTitle;
            var ContentList = [];

            if (CombinedBoxContent) {
                if (Array.isArray(CombinedBoxContent) && CombinedBoxContent.length > 0) {
                    ContentTitle = CombinedBoxContent[i - 1].title;
                    if (Array.isArray(CombinedBoxContent[i - 1].list)) {
                        for (var j = 0; j < CombinedBoxContent[i - 1].list.length; j++) {
                            let liElem = <li key={`CombinedBoxListElem${i}_${j}`}>{CombinedBoxContent[i - 1].list[j]}</li>;
                            ContentList.push(liElem);
                        }
                    }
                }
            }

            let Component = (
                <div
                    key={`CombinedBoxElem_${i}`}
                    className={classes.CombinedActivities_Wrapper_Block}
                    style={{display: CombinationBoxVisibility[target] === false ? "none" : null, border: `2px solid ${CombinedBoxTitle[ButtColor]}`}}
                >
                    <div className={classes.CombinedActivities_Top}>
                        <div className={classes.CombinedActivities_Top_Title_Wrapper}>
                            <div className={classes.CombinedActivities_Top_Title_Top}>{CombinedBoxTitle.top}</div>
                            <div className={classes.CombinedActivities_Top_Title_Middle}>&</div>
                            <div className={classes.CombinedActivities_Top_Title_Bottom}>{CombinedBoxTitle[bottomTitle]}</div>
                        </div>
                        <div className={classes.CombinedActivities_Top_Close} onClick={HandleCloseCombinationBox}>
                            <CloseIcon
                                IconSize="25px"
                                SVGFillColor=""
                                SVGStrokeColor="black"
                                SVGStrokeHoverColor="black"
                                SVGStrokeWidth="25"
                                Frame="circle"
                                InnerPadding="45px"
                                FrameFillColor="white"
                                FrameStrokeColor="white"
                                FrameFillColorHover="white"
                                FrameStrokeColorHover="white"
                                //
                            />
                        </div>
                    </div>

                    <div className={classes.CombinedActivities_Content}>
                        <p className={classes.CombinedActivities_Content_Title}>{ContentTitle}</p>
                        <ul className={classes.CombinedActivities_Content_Description}>{ContentList}</ul>
                    </div>
                    <div className={classes.CombinedActivities_Footer}>
                        <Button
                            Width="250px"
                            Height="30px"
                            Background={CombinedBoxTitle[ButtColor]}
                            style={{margin: "0px"}}
                            ClassExtendButton={classes.ClassExtendButton_LearnMore}
                            onClick={() => HandleNavigateTo(CombinedBoxTitle[NavTo])}
                        >
                            {`Learn more about ${CombinedBoxTitle[NavTo]}`}
                        </Button>
                    </div>
                </div>
            );
            BoxItemList.push(Component);
        }
        return BoxItemList;
    };

    return (
        <div className={classes.Summary_Wrapper}>
            <div className={classes.Summary_TitleWrapper}>
                <div className={classes.Summary_TitleIcon_Wrapper}>
                    <img className={classes.Summary_TitleIcon} src={TitleImgPath} alt="SummaryBoxTitleIcon" />
                </div>
                <div className={classes.Summary_Title_Wrapper}>
                    <h1 className={classes.Summary_Title}>{Title}</h1>
                </div>
            </div>
            <div className={classes.Summary_Content_Wrapper} ref={RefSummaryContentWrapper}>
                <div className={classes.Svg_Wrapper}>
                    <svg className={classes.Svg_Box} viewBox={`0 0 ${SVGTopViewBoxWidth} ${SVGTopViewBoxHeight}`}>
                        <g id="TopSection">
                            <g >
                                <circle
                                    id="SectionLeft_Circle_Img_Top"
                                    cx={TopCircle1.cx}
                                    cy={TopCircle1.cy}
                                    r={TopCircle1.r}
                                    stroke="none"
                                    //
                                    fill={LeftCircleImgColor}
                                ></circle>
                                <image
                                    id="SectionLeft_Circle_Img_ImageTop"
                                    x={TopImg1.x}
                                    y={TopImg1.y}
                                    width={TopImg1.dim}
                                    height={TopImg1.dim}
                                    xlinkHref={SectionLeftImagesPath.top}
                                    //
                                />
                            </g>
                            <g >
                                <circle
                                    id="SectionLeft_Circle_Img_Bottom"
                                    cx={TopCircle2.cx}
                                    cy={TopCircle2.cy}
                                    r={TopCircle2.r}
                                    stroke="none"
                                    //
                                    fill={LeftCircleImgColor}
                                ></circle>

                                <image
                                    id="SectionLeft_Circle_Img_ImageBottom"
                                    x={TopImg2.x}
                                    y={TopImg2.y}
                                    width={TopImg2.dim}
                                    height={TopImg2.dim}
                                    xlinkHref={SectionLeftImagesPath.bottom}
                                    //
                                />
                            </g>
                            <g >
                                <circle
                                    id="SectionLeft_Circle_Img_Left"
                                    cx={TopCircle3.cx}
                                    cy={TopCircle3.cy}
                                    r={TopCircle3.r}
                                    stroke="none"
                                    //
                                    fill={LeftCircleImgColor}
                                ></circle>
                                <image
                                    id="SectionLeft_Circle_Img_ImageLeft"
                                    x={TopImg3.x}
                                    y={TopImg3.y}
                                    width={TopImg3.dim}
                                    height={TopImg3.dim}
                                    xlinkHref={SectionLeftImagesPath.left}
                                    //
                                />
                            </g>
                            <g >
                                <circle
                                    id="SectionLeft_Circle_Img_Right"
                                    cx={TopCircle4.cx}
                                    cy={TopCircle4.cy}
                                    r={TopCircle4.r}
                                    stroke="none"
                                    //
                                    fill={LeftCircleImgColor}
                                ></circle>
                                <image
                                    id="SectionLeft_Circle_Img_ImageRight"
                                    x={TopImg4.x}
                                    y={TopImg4.y}
                                    width={TopImg4.dim}
                                    height={TopImg4.dim}
                                    xlinkHref={SectionLeftImagesPath.right}
                                    //
                                />
                            </g>
                        </g>
                    </svg>
                </div>

                <div className={classes.Summary_Content_Description_Wrapper} style={{opacity: DescriptionBoxDim.opacity}}>
                    <div className={classes.Summary_Content_Description_List_Wrapper} style={{display: visibilityDescOrCombination_Description === true ? "none" : null}}>
                        <ul className={classes.Summary_Content_Description_List}>{FinalContentDescription()}</ul>
                    </div>
                    <div ref={RefCombinedBoxWrapper} className={classes.CombinedActivities_Wrapper} style={{display: visibilityDescOrCombination_Description === false ? "none" : null}}>
                        {FinalCombinedBoxComponent()}
                    </div>
                </div>
                <div className={classes.Svg_Wrapper}>
                    <svg className={classes.Svg_Box} viewBox={`0 0 ${SVGBottomViewBoxWidth} ${SVGBottomViewBoxHeight}`}>
                        <defs>
                            <circle id="path-1" cx={BottomCircle1.cx} cy={BottomCircle1.cy} r={BottomCircle1.r} />
                            <circle id="path-2" cx={BottomCircle2.cx} cy={BottomCircle2.cy} r={BottomCircle2.r} />
                            <circle id="path-3" cx={BottomCircle3.cx} cy={BottomCircle3.cy} r={BottomCircle3.r} />
                            <circle id="path-4" cx={BottomCircle4.cx} cy={BottomCircle4.cy} r={BottomCircle4.r} />
                            <filter x="-22.1%" y="-22.1%" width="144.2%" height="144.2%" filterUnits="objectBoundingBox" id="hallo-filter">
                                <feMorphology radius={AnimatedHallo} operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
                                <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                                <feGaussianBlur stdDeviation="4.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                                <feColorMatrix values="0 0 0 0 0.305225317   0 0 0 0 0.305225317   0 0 0 0 0.305225317  0 0 0 1 0" type="matrix" in="shadowBlurOuter1" />
                            </filter>
                        </defs>
                        <g id="BottomSection">
                            <g id="groupRight1" onClick={() => HandleCircleLeftClick("target1")}>
                                <circle
                                    id="SectionRight_Circle_Img_1"
                                    cx={BottomCircle1.cx}
                                    cy={BottomCircle1.cy}
                                    r={BottomCircle1.r}
                                    stroke="none"
                                    fill={RigthCircleColor1}

                                    //
                                />
                                <g id="RightCircle1Shadow">
                                    <use id="SectionRight_Circle_Img_Use11" fill="black" fillOpacity="1" filter="url(#hallo-filter)" xlinkHref="#path-1" />
                                    <use id="SectionRight_Circle_Img_Use12" fill="black" fillRule="evenodd" xlinkHref="#path-1" />
                                </g>

                                <image
                                    id="SectionRight_Circle_Img_Image1"
                                    x={BottomImg1.x}
                                    y={BottomImg1.y}
                                    width={BottomImg1.dim}
                                    height={BottomImg1.dim}
                                    xlinkHref={RigthImgPath1}
                                    //
                                />
                            </g>
                            <g id="groupRight2" onClick={() => HandleCircleLeftClick("target2")}>
                                <circle
                                    id="SectionRight_Circle_Img_2"
                                    cx={BottomCircle2.cx}
                                    cy={BottomCircle2.cy}
                                    r={BottomCircle2.r}
                                    //
                                    stroke="none"
                                    fill={RigthCircleColor2}
                                />
                                <g id="RightCircle2Shadow">
                                    <use id="SectionRight_Circle_Img_Use21" fill="black" fillOpacity="1" filter="url(#hallo-filter)" xlinkHref="#path-2" />
                                    <use id="SectionRight_Circle_Img_Use22" fill="black" fillRule="evenodd" xlinkHref="#path-2" />
                                </g>
                                <image
                                    id="SectionRight_Circle_Img_Image2"
                                    x={BottomImg2.x}
                                    y={BottomImg2.y}
                                    width={BottomImg2.dim}
                                    height={BottomImg2.dim}
                                    xlinkHref={RigthImgPath2}
                                    //
                                />
                            </g>
                            <g id="groupRight3" onClick={() => HandleCircleLeftClick("target3")}>
                                <circle
                                    id="SectionRight_Circle_Img_3"
                                    cx={BottomCircle3.cx}
                                    cy={BottomCircle3.cy}
                                    r={BottomCircle3.r}
                                    stroke="none"
                                    //
                                    fill={RigthCircleColor3}
                                />
                                <g id="RightCircle3Shadow">
                                    <use id="SectionRight_Circle_Img_Use31" fill="black" fillOpacity="1" filter="url(#hallo-filter)" xlinkHref="#path-3" />
                                    <use id="SectionRight_Circle_Img_Use32" fill="black" fillRule="evenodd" xlinkHref="#path-3" />
                                </g>
                                <image
                                    id="SectionRight_Circle_Img_Image3"
                                    x={BottomImg3.x}
                                    y={BottomImg3.y}
                                    width={BottomImg3.dim}
                                    height={BottomImg3.dim}
                                    xlinkHref={RigthImgPath3}
                                    //
                                />
                            </g>
                            <g id="groupRight4" onClick={() => HandleCircleLeftClick("target4")}>
                                <circle
                                    id="SectionRight_Circle_Img_4"
                                    cx={BottomCircle4.cx}
                                    cy={BottomCircle4.cy}
                                    r={BottomCircle4.r}
                                    stroke="none"
                                    fill={RigthCircleColor4}

                                    //
                                />
                                <g id="RightCircle4Shadow">
                                    <use id="SectionRight_Circle_Img_Use41" fill="black" fillOpacity="1" filter="url(#hallo-filter)" xlinkHref="#path-4" />
                                    <use id="SectionRight_Circle_Img_Use42" fill="black" fillRule="evenodd" xlinkHref="#path-4" />
                                </g>
                                <image
                                    id="SectionRight_Circle_Img_Image4"
                                    x={BottomImg4.x}
                                    y={BottomImg4.y}
                                    width={BottomImg4.dim}
                                    height={BottomImg4.dim}
                                    xlinkHref={RigthImgPath4}
                                    //
                                />
                            </g>
                        </g>
                    </svg>
                </div>
                {BottomButtonContent}
            </div>
        </div>
    );
};

export default PagesBoxDescription;
